import axios from "axios";

const FILE_UPLOADED = "FILE_UPLOADED";
const FILE_IS_LOADING = "FILE_IS_LOADING";

export const actionCreators = {
    upload: (file, projectId, transactionType, folderName) => async (dispatch, getState) => {
        try {
            if (getState().files.isLoading) {
                return;
            }
            const url = "/api/file/uploadFiles";
            let formData = new FormData();
            formData.append("file", file);
            formData.append("projectId", projectId);
            formData.append("transactionType", transactionType);
            formData.append("folderName", folderName);
            dispatch({type: FILE_IS_LOADING, isLoading: true});
            let response = await axios.post(url, formData, {
                headers: {"X-Requested-With": "XMLHttpRequest"}
            });
            dispatch({type: FILE_UPLOADED, file: response.data});
            dispatch({type: FILE_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: FILE_IS_LOADING, isLoading: false});
        }
    },
    download: (blobName, createdById, projectId, transactionType) => async (dispatch, getState) =>{
        axios({
            url: '/api/file/downloadFiles',
            method: 'GET',
            responseType: 'blob', // important
            params:{
                blobName,
                createdById,
                projectId,
                transactionType
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            console.clear();
            console.log(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', blobName && blobName.split("/").length > 1 ? blobName.split("/")[blobName.split("/").length - 1] : blobName);
            document.body.appendChild(link);
            link.click();
        });
    },
    docPreview: (blobName, createdById, projectId, transactionType) => async (dispatch, getState) => {
        axios({
            url: '/api/file/downloadFiles',
            method: 'GET',
            responseType: 'blob', // important
            params: {
                blobName,
                createdById,
                projectId,
                transactionType
            }
        }).then((response) => {            
            //Creating a Blob from the PDF Stream or text stream
            //const file = new Blob([response.data], { type: 'application/pdf' || 'text/plain'});
            const file = new Blob([response.data], { type: response.data.type });
            //make a URL from the file
            const fileURL = (window.URL || window.webkitURL || {}).createObjectURL(file);
            //Open the URL on new Window testing purpose
            //window.open(fileURL);          
            //get docViewer
            var docViewerId = document.getElementById('docViewer');            
            //fill doc viewer modal
            if (docViewerId) {
                docViewerId.setAttribute('data', fileURL);
                docViewerId.setAttribute('type', response.data.type ); 
            }
            //another approach for same solution
            //if (docViewerId.outerHTML) { objectEl.outerHTML = objectEl.outerHTML.replace(/data="(.+?)"/, 'data="' + fileURL + '"'); }
            
        }
            )
            .catch(error => {
                console.clear();
                console.log('catch error in docView Api'+error);
            });
            
    },

};

const initialState = {
    lastSavedFile: {},
    isLoading: false
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case FILE_UPLOADED:
            return {...state, lastSavedFile: action.file};
        case FILE_IS_LOADING:
            return {...state, isLoading: false};
        default:
            return state;
    }
};