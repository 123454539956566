import axios from "axios"
const COMMON_API_ISLOADING = "COMMON_API_ISLOADING";
const COMMON_FETCHED_ALL_LIST = "COMMON_FETCHED_ALL_LIST";
const ALL_LIST_BY_PROJECTID = "ALL_LIST_BY_PROJECTID";
const ALL_LIST_BY_POID = "ALL_LIST_BY_POID";
const COMMON_FETCH_ALL_PROJECTS = "COMMON_FETCH_ALL_PROJECTS";
const COMMON_FETCH_ALL_POS = "COMMON_FETCH_ALL_POS";
const COMMON_FETCH_ALL_SHIPMENTS = "COMMON_FETCH_ALL_SHIPMENTS";
const COMMON_FETCH_ALL_INVOICES = "COMMON_FETCH_ALL_INVOICES";
const COMMON_FETCH_RAS = "COMMON_FETCH_RAS";
const COMMON_FETCH_PO_BY_SHIPMENT = "COMMON_FETCH_PO_BY_SHIPMENT";
export const actionCreators = {
    fetchAllData: () => async (dispatch, getState) => {
        if (getState().commonApis.isLoading) {
            return;
        }
        dispatch({ type: COMMON_API_ISLOADING, isLoading: true });
        //THIS api will fetch all projects,Shipments,Purchase Orders
        const url = "/api/common/getAll";
        let response = await axios.get(url);
        dispatch({ type: COMMON_FETCHED_ALL_LIST, data: response.data });
        dispatch({ type: COMMON_API_ISLOADING, isLoading: false });

    },
    fetchAllByProjectId: (ProjectId,requiredData) => async (dispatch, getState) => {
        if (getState().commonApis.isLoading) {
            return;
        }
        dispatch({ type: COMMON_API_ISLOADING, isLoading: true });
        //THIS api will fetch all projects,Shipments,Purchase Orders by project id
        const url = "/api/common/getAllByProjectId?ProjectId=" + ProjectId;
        let response = await axios.get(url);
        if (requiredData === 'pos') {
            dispatch({ type: COMMON_FETCH_ALL_POS, pos: response.data.posByProjectId });
        }
        else if (requiredData === 'shipments') { dispatch({ type: COMMON_FETCH_ALL_SHIPMENTS, allShipments: response.data.shipmentsByProjectId }); }
        else if (requiredData === 'invoices') { dispatch({ type: COMMON_FETCH_ALL_INVOICES, allInvoices: response.data.invoiceByProjectId }); }
        else if (requiredData === 'all') { dispatch({ type: ALL_LIST_BY_PROJECTID, dataByProjectId: response.data }); }
        dispatch({ type: COMMON_API_ISLOADING, isLoading: false });

    },
    fetchAllByPoId: (PurchaseOrderId, requiredData) => async (dispatch, getState) => {
        if (getState().commonApis.isLoading) {
            return;
        }
        dispatch({ type: COMMON_API_ISLOADING, isLoading: true });
        //THIS api will fetch all projects,Shipments,Purchase Orders by project id
        const url = "/api/common/getAllByPoId?PurchaseOrderId=" + PurchaseOrderId;
        let response = await axios.get(url);
        if (requiredData === 'all') {
            dispatch({ type: ALL_LIST_BY_POID, dataByPoId: response.data });
        }
        else if (requiredData === 'shipments') { dispatch({ type: COMMON_FETCH_ALL_SHIPMENTS, allShipments: response.data.shipmentsByPOId }); }
        
        dispatch({ type: COMMON_API_ISLOADING, isLoading: false });

    },
    getAllProjects: () => async (dispatch, getState) => {
        if (getState().commonApis.isLoading) {
            return;
        }
        //debugger;
        dispatch({ type: COMMON_API_ISLOADING, isLoading: true });
        //THIS api will fetch all projects,Shipments,Purchase Orders
        const url = "/api/common/allProjects";
        let response = await axios.get(url);
        dispatch({ type: COMMON_FETCH_ALL_PROJECTS, allProjects: response.data });
        dispatch({ type: COMMON_API_ISLOADING, isLoading: false });

    },
    //authorization policy not working ofor OR condition so this has to be done
    getAllProject: (moduleName) => async (dispatch, getState) => {
        if (getState().commonApis.isLoading) {
            return;
        }
        //debugger;
        dispatch({ type: COMMON_API_ISLOADING, isLoading: true });
        let response = null;
        if (moduleName === "Project") {
            const url = "/api/common/allProject";
             response = await axios.get(url);
        }
        else if (moduleName === "PO") {
            const url = "/api/common/poAllProject";
            response = await axios.get(url);
        }
        else if (moduleName === "PickTkt") {
            const url = "/api/common/pickTktAllProject";
             response = await axios.get(url);
        }
        else if (moduleName === "RA") {
            const url = "/api/common/raAllProject";
             response = await axios.get(url);
        }
        else if (moduleName === "Shipment") {
            const url = "/api/common/shipmentAllProject";
            response = await axios.get(url);
        }
        else if (moduleName === "Invoice") {
            const url = "/api/common/invoiceAllProject";
            response = await axios.get(url);
        }        
        else {
            const url = "/api/common/allProjects";
             response = await axios.get(url);
        }
        dispatch({ type: COMMON_FETCH_ALL_PROJECTS, allProjects: response.data });
        dispatch({ type: COMMON_API_ISLOADING, isLoading: false });

    },
    getAllPos: () => async (dispatch, getState) => {
        if (getState().commonApis.isLoading) {           
           return;
        }
        dispatch({ type: COMMON_API_ISLOADING, isLoading: true });     
        const url = "/api/common/allPos";
        let response = await axios.get(url);
        dispatch({ type: COMMON_FETCH_ALL_POS, pos: response.data });
        dispatch({ type: COMMON_API_ISLOADING, isLoading: false });

    },
    getAllShipments: () => async (dispatch, getState) => {
        if (getState().commonApis.isLoading) {
            return;
        }
        dispatch({ type: COMMON_API_ISLOADING, isLoading: true });
        const url = "/api/common/allShipments";
        let response = await axios.get(url);
        dispatch({ type: COMMON_FETCH_ALL_SHIPMENTS, allShipments: response.data });
        dispatch({ type: COMMON_API_ISLOADING, isLoading: false });

    },
    //to do all receiving alerts controller done only pending here
    getAllRas: (projectId) => async (dispatch, getState) => {
        if (getState().commonApis.isLoading) {
            return;
        }
        dispatch({ type: COMMON_API_ISLOADING, isLoading: true });        
        const url = "/api/common/pickTktRas?projectId=" + projectId;
        let response = await axios.get(url);
        dispatch({ type: COMMON_FETCH_RAS, allRas: response.data });
        dispatch({ type: COMMON_API_ISLOADING, isLoading: false });

    },
    getPoByShipmentId: (shipmentId) => async (dispatch, getState) => {
        if (getState().commonApis.isLoading) {
            return;
        }
        dispatch({ type: COMMON_API_ISLOADING, isLoading: true });
        const url = "/api/common/getPoByShipmentId?shipmentId=" + shipmentId;
        let response = await axios.get(url);
        dispatch({ type: COMMON_FETCH_PO_BY_SHIPMENT, poId: response.data });
        dispatch({ type: COMMON_API_ISLOADING, isLoading: false });

    },
}
const initialState = {
    isLoading: false,
    data: {},
    dataByProjectId: {},
    dataByPoId: {},
    allProjects: {},
    allPos: {},
    allShipments: {},
    allRas: {},
    allInvoices: {},
    poids: [],
};
export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case COMMON_API_ISLOADING:
            return { ...state, isLoading: action.isLoading };
        case COMMON_FETCHED_ALL_LIST:
            return { ...state, data: action.data };
        case ALL_LIST_BY_PROJECTID:
            return { ...state, dataByProjectId: action.dataByProjectId };
        case ALL_LIST_BY_POID:
            return { ...state, dataByPoId: action.dataByPoId };
        case COMMON_FETCH_ALL_PROJECTS:
            return { ...state, allProjects: action.allProjects };
        case COMMON_FETCH_ALL_POS:
            return { ...state, allPos: action.pos };
        case COMMON_FETCH_ALL_SHIPMENTS:
            return { ...state, allShipments: action.allShipments };
        case COMMON_FETCH_RAS:
            return { ...state, allRas: action.allRas };
        case COMMON_FETCH_PO_BY_SHIPMENT:
            return { ...state, poids: action.poId };
        case COMMON_FETCH_ALL_INVOICES:
            return { ...state, allInvoices: action.allInvoices };
        default: return state;
    }
}