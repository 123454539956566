import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import axios from "axios"
import toastr from "toastr";
import { actionCreators as clientActions } from "../../store/Clients";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ClientSelectOption from "../Client/ClientSelectOption";
import AxisLazyLoad from "../common/AxisLazyLoad";
const ClientCreateModal = AxisLazyLoad({ loader: () => import('../Client/ClientCreateModal') });
const errorOption= Promise.resolve({
    options: [
        { value: '0', label: 'Error.. Please pass api url and search text..', disabled: true, }
    ],
});
 class ClientSelect extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            selectedValue: "",            
            typing: false,
            typingTimeout: 0,
            input: "",
            clientCreateModalVisible: false,
            isClientCreateRendered: false,
        };       
        this.timer = null;
     }
     async  componentWillReceiveProps(nextProps, nextContext) {        
        if (this.props.clientId !== nextProps.clientId) {            
            if (nextProps.clientId) {
                await this.props.clientActions.getSingle(nextProps.clientId);
            }
            else
                await this.setState({ selectedValue: "" });

            if (this.props.selectedClient && nextProps.clientId) {
               // toastr.warning('setting client');
                let fetchedCliet = this.props.selectedClient;
                fetchedCliet.value = this.props.selectedClient.id;
                fetchedCliet.label = this.props.selectedClient.name;                
                await this.setState({ selectedValue: fetchedCliet });
            }
        }
        
    }   
    
    getOptions = inputValue =>       
            new Promise(resolve => {
                setTimeout(() => {
                    resolve({ options: this.filterOptions(inputValue) });
                }, 1000);
            }); 
     onChange = async (selected) => {
        await this.setState({ selectedValue: selected });
        await this.props.selectClient(selected);
    }
     //can i wait up to when user stop typing
    changeName = (event) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }
        self.setState({
            input: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.sendToParent(self.state.name);
            }, 5000)
        });
    }
    activateClientModal = async () => {
        await this.setState({ clientCreateModalVisible: true, isClientCreateRendered: true });
        //if (propertyName) {
        //    await   this.setState({ clientPropertyName: propertyName });
        //}
    };
    dismissClientModal = async () => {
        await this.setState({ clientCreateModalVisible: false });       
    };
    setOptions = (response) => {
        return response.map(item => ({
            value: item.id,
            label: item.name,
            addresses: item.addresses.filter(item => item.isPrimary === true),
            contacts: item.contacts,
            isFirst: item.isFirst,
            link: item.link
        }));
        // return response;
    }

    getOption = (searchTerm) => {        
        //searchTerm = searchTerm.replace(/\W/g, '');        
            if (searchTerm) {                       
                let clientId = [1];//by default search in clients              
                let requiredData = this.props.requiredData ? this.props.requiredData : 20;
                const url = '/api/client/getCompanyDetails';
                let clientTypeIds = this.props.clientTypeIds ? this.props.clientTypeIds : clientId;
                if ( requiredData && clientTypeIds) {
                     return axios.post(url, { searchTerm, requiredData, clientTypeIds })
                        .then(res => { return { options: this.setOptions(res.data), complete: true } })
                        .catch(function (error) {
                            if (error.response) {
                                toastr.error(error.response.status);
                            } else if (error.request) {
                                toastr.error("Request error" + error.request);
                            } else {
                                toastr.error('Error', error.message);
                            }
                            return errorOption;
                        });
                }
                else {
                    return errorOption;

                }
            }            
            else {
                let clientList = [];
                clientList.unshift({
                    isFirst: true,
                    link: <h5><a onClick={this.activateClientModal}>+Add Company</a></h5>,
                    addresses: []
                });                
                return Promise.resolve({
                    
                    options: clientList && clientList
                        .map(item => ({
                            value: item.id,
                            label: item.name,
                            addresses: item.addresses.filter(item => item.isPrimary === true),
                            contacts: item.contacts,
                            isFirst: item.isFirst,
                            link: item.link
                        }))                 
                });


            }
        
    }
     render() {
        return (          
            <div>               
                <AsyncSelect
                    cacheOptions
                    isClearable
                    className="basic-single"
                    classNamePrefix="select"
                    name="clientSearch"
                    labelKey="label"
                    valueKey="value"
                    value={this.state.selectedValue}
                    matchPos="any"
                    loadOptions={this.getOption.bind(this)}
                    onChange={this.onChange}
                    optionComponent={ClientSelectOption}
                    disabled={this.props.disabled}
                />
                {this.state.isClientCreateRendered &&
                    <ClientCreateModal
                    isVisible={this.state.clientCreateModalVisible}
                    buttonRightClick={this.dismissClientModal}
                    clientSaved={this.props.clientSaved}
                    selectClient={this.props.selectClient}
                    />
                }
            </div>
        );
    }
}
ClientSelect.propTypes = {};
ClientSelect.defaultProps = {};
const mapStateToProps = state => ({   
    isLoading: state.clients.isLoading,
    selectedClient: state.clients.selected,
});
const mapDispatchToProps = dispatch => ({
    clientActions: bindActionCreators(clientActions, dispatch),   
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientSelect);