import React from 'react';
import classnames from "classnames";
function UserModuleSelect(props) {
    return (
        <div className="table-responsive">
            <table className="table table-hover">
                <thead>
                <tr>
                    <th/>
                    <th className={classnames("text-center")}><span>View</span>
                        <br/>
                        <input type="checkbox"
                                onChange={(e) => props.allSelected(e, { type: "All", value: "View" })}
                                disabled={
                                    // checkAllSelected(props.selectedClaims, {
                                    //     type: "All",
                                    //     value: "View"
                                    // }, props.data)
                                    //||
                                    checkAllSelected(props.selectedClaims, {
                                        type: "All",
                                        value: "Update"
                                    }, props.data) ||
                                    checkAllSelected(props.selectedClaims, {
                                        type: "All",
                                        value: "Delete"
                                    }, props.data) ||
                                    checkAllSelected(props.selectedClaims, {
                                        type: "All",
                                        value: "Create"
                                    }, props.data)
                                }
                               checked={checkAllSelected(props.selectedClaims, {
                                       type: "All",
                                       value: "View"
                                   }, props.data)
                                   ||   
                                   checkAllSelected(props.selectedClaims, {
                                       type: "All",
                                       value: "Create"
                                   }, props.data)
                                   ||
                                   checkAllSelected(props.selectedClaims, {
                                    type: "All",
                                   value: "Update"
                                   }, props.data)
                                   ||
                                    checkAllSelected(props.selectedClaims, {
                                       type: "All",
                                   value: "Delete"
                                   }, props.data)
                                   
                               }
                              
                            />
                    </th>
                    <th className="text-center" style={{"visibility": props.disableAdmin === true ? "hidden" : ""}}>
                        <span>Create</span>
                        <br/>
                        <input type="checkbox"
                               onChange={(e) => props.allSelected(e, {type: "All", value: "Create"})}
                               checked={checkAllSelected(props.selectedClaims, {
                                   type: "All",
                                   value: "Create"
                               }, props.data)}
                            />
                    </th>
                    <th className="text-center" style={{"visibility": props.disableAdmin === true ? "hidden" : ""}}>
                        <span>Update</span>
                        <br/>
                        <input type="checkbox"
                               onChange={(e) => props.allSelected(e, {type: "All", value: "Update"})}
                               checked={checkAllSelected(props.selectedClaims, {
                                   type: "All",
                                   value: "Update"
                               }, props.data)}/>
                    </th>
                    <th className="text-center" style={{"visibility": props.disableAdmin === true ? "hidden" : ""}}>
                        <span>Delete</span>
                        <br/>
                        <input type="checkbox"
                               onChange={(e) => props.allSelected(e, {type: "All", value: "Delete"})}
                               checked={checkAllSelected(props.selectedClaims, {
                                   type: "All",
                                   value: "Delete"
                               }, props.data)}/>
                    </th>
                </tr>
                </thead>
                <tbody>
                {props.data && props.data.map(row => {
                    return (
                        <tr key={row.type}>
                            <td>{row.typeDesc}</td>
                            <td className={classnames("text-center")}>
                                <input type="checkbox"
                                    onChange={(e) => props.moduleSelected(e, {
                                        type: row.type,
                                        value: "View"
                                    })}
                                    checked={checkSelected(props.selectedClaims, {
                                        type: row.type,
                                        value: "View"
                                    })
                                        ||
                                        checkSelected(props.selectedClaims, {
                                            type: row.type,
                                            value: "Create"
                                        }) ||
                                        checkSelected(props.selectedClaims, {
                                            type: row.type,
                                            value: "Update"
                                        }) ||
                                        checkSelected(props.selectedClaims, {
                                            type: row.type,
                                            value: "Delete"
                                        }) 
                                    }
                                    disabled={
                                        checkSelected(props.selectedClaims, {
                                        type: row.type,
                                        value: "Create"
                                        }) ||
                                        checkSelected(props.selectedClaims, {
                                            type: row.type,
                                            value: "Update"
                                        }) ||
                                        checkSelected(props.selectedClaims, {
                                            type: row.type,
                                            value: "Delete"
                                        }) 
                                    }
                                   
                                />
                            </td>
                            <td className={classnames("text-center")}
                                style={{"visibility": props.disableAdmin === true ? "hidden" : ""}}>
                                <input type="checkbox"
                                       onChange={(e) => props.moduleSelected(e, {
                                           type: row.type,
                                           value: "Create"
                                       })}
                                       checked={checkSelected(props.selectedClaims, {
                                           type: row.type,
                                           value: "Create"
                                       })}/>
                            </td>
                            <td className={classnames("text-center")}
                                style={{"visibility": props.disableAdmin === true ? "hidden" : ""}}>
                                <input type="checkbox"
                                       onChange={(e) => props.moduleSelected(e, {
                                           type: row.type,
                                           value: "Update"
                                       })}
                                       checked={checkSelected(props.selectedClaims, {
                                           type: row.type,
                                           value: "Update"
                                       })}/>
                            </td>
                            <td className={classnames("text-center")}
                                style={{"visibility": props.disableAdmin === true ? "hidden" : ""}}>
                                <input type="checkbox"
                                       onChange={(e) => props.moduleSelected(e, {
                                           type: row.type,
                                           value: "Delete"
                                       })}
                                       checked={checkSelected(props.selectedClaims, {
                                           type: row.type,
                                           value: "Delete"
                                       })}/>
                            </td>


                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="col-md-1">
                <label className="control-label">
                    {props.type}
                </label>
            </div>
        </div>
    );
}

function checkSelected(claims, claim) {
   
    if (claims && claims.length) {
        let index = claims.findIndex(item => {
            return item.type === claim.type && item.value === claim.value;
        });
        return index > -1;
    }
    return false;
}

function checkAllSelected(claims, claim, data) {
    //debugger;
    if (claims && claims.length) {
        let exists = claims.filter(item => {
            return item.value === claim.value;
        });
        return exists.length === data.length;
    }
    else { return false; }
}

UserModuleSelect.propTypes = {};
UserModuleSelect.defaultProps = {};

export default UserModuleSelect;
