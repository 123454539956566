import axios from "axios";

const PROJECT_STATUS_FETCHED = "PROJECT_STATUS_FETCHED";
const PROJECT_STATUS_IS_LOADING = "PROJECT_STATUS_IS_LOADING";

export const actionCreators = {
    fetchProjectStatuses: () => async (dispatch, getState) => {
        try {
            if (getState().proposalStatuses.isLoading) {
                return;
            }
            const url = "/api/projectStatus";
            dispatch({type: PROJECT_STATUS_IS_LOADING, isLoading: true});
            let response = await axios.get(url);
            dispatch({type: PROJECT_STATUS_FETCHED, projectStatuses: response.data});
            dispatch({type: PROJECT_STATUS_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: PROJECT_STATUS_IS_LOADING, isLoading: false});
        }
    }
};

const initialState = {
    isLoading: false,
    list: []
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case PROJECT_STATUS_FETCHED:
            return {...state, list: action.projectStatuses};
        case PROJECT_STATUS_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        default:
            return state;
    }
};