import axios from "axios";

const PROPOSAL_STATUS_FETCHED = "PROPOSAL_STATUS_FETCHED";
const PROPOSAL_STATUS_IS_LOADING = "PROPOSAL_STATUS_IS_LOADING";

export const actionCreators = {
    fetchProposalStatuses: state => async (dispatch, getState) => {
        try {
            if (getState().proposalStatuses.isLoading) {
                return;
            }
            const url = "/api/proposalStatus";
            dispatch({type: PROPOSAL_STATUS_IS_LOADING, isLoading: true});
            let response = await axios.get(url);
            dispatch({type: PROPOSAL_STATUS_FETCHED, proposalStatuses: response.data});
            dispatch({type: PROPOSAL_STATUS_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: PROPOSAL_STATUS_IS_LOADING, isLoading: false});
        }
    }
};

const initialState = {
    isLoading: false,
    list: []
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case PROPOSAL_STATUS_FETCHED:
            return {...state, list: action.proposalStatuses};
        case PROPOSAL_STATUS_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        default:
            return state;
    }
};