import axios from "axios";
const ITEM_SAVED = "ITEM_SAVED";
const ITEM_IS_LOADING = "ITEM_IS_LOADING";
export const actionCreators = {
    createMasterItem: (data) => async (dispatch, getState) => {
        if (getState().items.isLoading) {
            return;
        }
        try {
            dispatch({ type: ITEM_IS_LOADING, isLoading: true });
            const url = "/api/item";
            let response = await axios.post(url, data);
            dispatch({ type: ITEM_SAVED, lastSaved: response.data });
            dispatch({ type: ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: ITEM_IS_LOADING, isLoading: false });
        }
    },
    updateItem: (data) => async (dispatch, getState) => {
        try {
            if (getState().items.isLoading) {
                return;
            }
            dispatch({ type: ITEM_IS_LOADING, isLoading: true });
            const url = "api/item";
            await axios.put(url, data);
            dispatch({ type: ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
};
const initialState = {
    isLoading: false,   
    lastSaved: null,   
};
export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case ITEM_IS_LOADING:
            return { ...state, isLoading: action.isLoading };       
        case ITEM_SAVED:
            return { ...state, lastSaved: action.lastSaved };
        default:
            return state;
    }
};
