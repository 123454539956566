import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { validateInput } from '../../validators/userEdit';
import { actionCreators as claimsActions } from "../../store/Claims";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/Users";
import { actionCreators as userTypeActions } from "../../store/UserTypes";
import { actionCreators as projectActions } from "../../store/Projects";
import { actionCreators as clientActions } from "../../store/Clients";
import { actionCreators as modalActions } from "../../store/Modal";
import "toastr/build/toastr.min.css";
import toastr from "toastr";
import { withRouter } from "react-router-dom";
import UserForm from "./UserForm";
import Modal from "../common/WarningModal";
import _ from "lodash";
class UserEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            email: "",
            userName: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            clientId: "",
            lastName: "",
            title: "",
            userTypeId: "",
            claims: [],
            status: "true",
            isAdmin: "false",
            projects: [],
            projectList: [],
            claimsList: [],
            clientList: [],
            disableAdmin: false,
            clientTypesIds: [1]
        };
        this.timer = null;
        toastr.options = {
            "closeButton": true,
            "progressBar": true,
            "timeOut": "2500",
        }
    }
    clientSaved = async (id, clientTypes) => {

        let containClientType = false;
        let clientTypesIds = this.state.clientTypesIds;
        if (clientTypes.length > 0) {
            for (var i = 0; i < clientTypesIds.length; i++) {
                if (clientTypes.indexOf(clientTypesIds[i]) >= 0) {
                    containClientType = true;
                    break;
                }
            }
        }
        if (containClientType === true)
            await this.setState({ clientId: id });

    };
    populateState = async () => {
        if (!this.props.match.params.id) {
            // this.setState({
            //     errors: {},
            //     email: "",
            //     userName: "",
            //     password: "",
            //     confirmPassword: "",
            //     firstName: "",
            //     clientId: "",
            //     lastName: "",
            //     title: "",
            //     userTypeId: "",
            //     claims: [],
            //     status: "true",
            //     isAdmin: "false",
            //     projects: [],
            //     projectList: [],
            //     claimsList: [],
            //     clientList: [],
            //     disableAdmin: false
            // })
            return;
        }
        if (this.props.match.params.id) {
            await this.props.actions.getSingle(this.props.match.params.id);
            await this.setState({ ...this.props.singleUser });
            let id = this.state.clientId;
            if (this.state.userTypeId === "2") {
                await this.props.projectActions.fetchByClientId(id);
                this.setState({ disableAdmin: true });
            }
            else {
                await this.props.projectActions.fetchProjects();
            }
            await this.props.claimActions.fetchClaims(this.props.singleUser.userTypeId === "2");
            if (this.props.singleUser.userTypeId === "2") {
                this.setState({
                    claimsList: this.props.claims.filter(item => item.type !== "Reports"
                        && item.type !== "Proposal"
                        && item.type !== "AddressBook"
                    )
                });
            }
            else {
                this.setState({ claimsList: this.props.claims });
            }
            this.setState({ projectList: this.props.projects });
        }
        await this.props.userTypeActions.fetchUserTypes();
        await this.props.clientActions.fetchClients("all", 0);
        this.setState({ clientList: this.props.clients });
        this.setState({ userTypeList: this.props.userTypes });
    };

    async componentWillMount() {
        await this.populateState();
    }

    isValid = () => {
        if (this.props.match.params.id) {
            const { errors, isValid } = validateInput(this.state);

            if (!isValid) {
                this.setState({ errors });
            }
            return isValid;
        }
    };

    allSelected = (e, claim) => {
        //debugger;
        if (e.target.checked) {
            let claimList = this.state.claimsList;
            let claims = this.state.claims;
            claimList.forEach((item, index) => {
                item.values.forEach((val, index) => {
                    if (val === claim.value && claim.value !== "View") {
                        let index = claims.findIndex(exist => {
                            return exist.type === item.type && exist.value === val;
                        });
                        if (index < 0)
                            claims.push({ type: item.type, value: val });
                        claims.push({ type: item.type, value: "View" });
                    }
                    else if (val === claim.value && claim.value === "View") {
                        let index = claims.findIndex(exist => {
                            return exist.type === item.type && exist.value === val;
                        });
                        if (index < 0)
                            claims.push({ type: item.type, value: val });
                    }
                });
            });
            claims = _.uniqWith(claims, _.isEqual);
            this.setState({ claims: claims });
        }
        else {
            let selectClaims = [];
            let claims = this.state.claims;
            claims.forEach((item, index) => {
                if (item.value !== claim.value) {
                    selectClaims.push(item);
                }
            });
            this.setState({ claims: selectClaims });
        }
    };

    onChange = async (e) => {
        let type = e.target.type;
        let name = e.target.name;
        let val = e.target.value;
        let checked = e.target.checked;
        if (type === "checkbox") {
            this.setState({ [name]: checked });
            return;
        }
        if (name === "userTypeId") {
            if (val === "2") {
                this.setState({
                    disableAdmin: true,
                    isAdmin: false
                });
                if (this.state.clientId !== "") {
                    await this.props.projectActions.fetchByClientId(this.state.clientId);
                    this.setState({
                        projectList: this.props.projects,
                    });
                } else {
                    this.setState({ projectList: [] });
                }
                await this.props.claimActions.fetchClaims(true);
                this.setState({
                    claimsList: this.props.claims
                        .filter(item => item.type !== "Reports"
                            && item.type !== "Proposal"
                            && item.type !== "AddressBook"
                        )
                });
            }
            else {
                await this.props.projectActions.fetchProjects();
                this.setState({
                    disableAdmin: false,
                    isAdmin: false,
                    projectList: this.props.projects,
                });
                await this.props.claimActions.fetchClaims();
                this.setState({ claimsList: this.props.claims, clientId: 1 });
            }
        }
        this.setState({ [name]: val })
    };


    onSubmit = async (e) => {
        e.preventDefault();
        if (this.isValid()) {
            try {
                if (this.props.match.params.id) {
                    await this.props.actions.updateUser(this.state);
                    toastr.success("User " + this.state.userName + " updated.");
                    await this.populateState();
                }
            }
            catch (e) {
                toastr.error(e.response.data);
            }
        }
    };

    getCompanyname = (projectClients, clientTypeId) => {
        let ClientDetail = [];
        if (projectClients) {
            if (clientTypeId === 1) {
                ClientDetail = projectClients.filter(function (item) {
                    return item.tabId === 1 && item.clientTypeId === 1;
                });
            }
            if (clientTypeId === 3) {
                ClientDetail = projectClients.filter(function (item) {
                    return item.tabId === 3 && item.clientTypeId === 3;
                });
            }
            if (ClientDetail.length > 0)
                return ClientDetail[0].client.name;
        }
        return "";
    };
    getPropertyname = projectClients => {
        let ClientDetail = [];
        if (projectClients) {
            ClientDetail = projectClients.filter(function (item) {
                return item.clientTypeId === 3;
            });

            if (ClientDetail.length > 0)
                return ClientDetail[0].client.name;
        }
        return "";
    };
    selectCompany = async (selected) => {
        if (selected === null) {
            this.setState({ clientId: "" });
            return;
        }
        if (this.state.userTypeId === "1" || this.state.userTypeId === "3" || this.state.userTypeId === "4" || this.state.userTypeId === "5") {
            this.setState({ clientId: selected.value });
        }
        else {
            await this.props.projectActions.fetchByClientId(selected.value);
            this.setState({
                projectList: this.props.projects,
                clientId: selected.value
            });
        }
    };

    projectSelected = (e, id) => {
        if (e.target.checked) {
            let projects = this.state.projects;
            let index = projects.findIndex(item => {
                return item === id;
            });
            if (index === -1) {
                projects.push(id);
                this.setState({ projects });
            }
        }
        else {
            let projects = this.state.projects;
            let index = projects.findIndex(item => {
                return item === id;
            });
            if (index > -1) {
                projects.splice(index, 1);
                this.setState({ projects });
            }
        }
    };

    projectSelectAll = (e) => {
        if (e.target.checked) {
            let projects = [];
            let projectList = this.state.projectList;
            projectList.forEach((item, index) => {
                projects.push(item.id);
            });
            this.setState({ projects: projects });
        }
        else {
            this.setState({ projects: [] });
        }
    };

    projectChecked = (id) => {
        let projects = this.state.projects;
        let index = projects.findIndex(projectId => {
            return projectId === id;
        });
        return index > -1;
    };

    projectAllSelected = () => {
        let projects = this.state.projects;
        let projectList = this.state.projectList;
        return projects.length > 0 && projects.length === projectList.length;
    };

    moduleSelected = (e, claim) => {
        //debugger;
        if (e.target.checked) {
            let claims = this.state.claims;
            let index = claims.findIndex(item => {
                return item.type === claim.type && item.value === claim.value;
            });
            if (index === -1) {
                claims.push(claim);
                this.setState({ claims });
            }
            //force to view if have create,Update,Delete
            this.autoSetViewPermission(claim);
        }
        else {
            let claims = this.state.claims;
            let index = claims.findIndex(item => {
                return item.type === claim.type && item.value === claim.value;
            });
            if (index > -1) {
                claims.splice(index, 1);
                this.setState({ claims });
            }
        }

    };
    autoSetViewPermission = (selectedClaim) => {
        switch (selectedClaim.value) {
            case "Create":
                //alert('User must have View Permission!');
                this.setViewClaim(selectedClaim);
                break;
            case "Update":
                this.setViewClaim(selectedClaim);
                break;
            case "Delete":
                this.setViewClaim(selectedClaim);
                break;
            default:
                console.log('View Permission set');
                break;
        }
    }
    setViewClaim = (claim) => {
        let claims = this.state.claims;
        let index = claims.findIndex(item => {
            return item.type === claim.type && item.value === "View";
        });
        //if not exists add new View
        if (index === -1) {
            let Viewclaim = {
                type: claim.type,
                value: "View"
            }
            claims.push(Viewclaim);
            this.setState({ claims });
        }
        //if already have remove view
        //if (index > -1) {
        //    claims.splice(index, 1);
        //    this.setState({ claims });
        //}

    }
    cancelClicked = () => {
        this.props.history.push("/users");
    };

    deleteUser = async () => {
        try {
            await this.props.actions.deleteUser(this.props.match.params.id);
            toastr.success("User " + this.state.userName + " deleted.");
            this.dismissWarning();
            this.props.history.push("/users");
        }
        catch (e) {
            toastr.error("Error occured while deleting User!" + e);
            this.dismissWarning();
        }

    };

    dismissWarning = () => {
        this.setState({ modalVisible: false });
        this.props.modalActions.changeVisibilty(false);
    };

    displayWarning = () => {
        this.setState({ modalVisible: true });
        this.props.modalActions.changeVisibilty(true);
    };
    performSearch = async () => {

        await this.props.projectActions.searchByClientId(this.state.clientId, this.state.searchTerm);
        this.setState({ projectList: this.props.projects.projects });
    };
    search = async e => {
        clearTimeout(this.timer);
        await this.setState({ searchTerm: e.target.value });
        this.timer = setTimeout(this.performSearch, 500);
    };

    hasCreatePermission = () => {
        let index = this.props.user.scope.indexOf("User:Create");
        let indexAdmin = this.props.user.scope.indexOf("Admin");
        return index !== -1 || indexAdmin !== -1;
    };
    hasEditPermission = () => {
        let index = this.props.user.scope.indexOf("User:Update");
        let indexAdmin = this.props.user.scope.indexOf("Admin");
        return index !== -1 || indexAdmin !== -1;
    };
    hasDeletePermission = () => {
        let index = this.props.user.scope.indexOf("User:Delete");
        let indexAdmin = this.props.user.scope.indexOf("Admin");
        return index !== -1 || indexAdmin !== -1;
    };


    render() {
        return (
            <div>
                {this.state.modalVisible &&
                    <Modal
                        closeButton={this.dismissWarning}
                        buttonLeftClick={this.dismissWarning}
                        buttonLeftText="Cancel"
                        buttonRightClick={this.deleteUser}
                        buttonRightText="Delete"
                        modalText="Please confirm you would like to delete the selected User account. This action cannot be reversed and all associated records will also be deleted!."
                        modalHeading="Delete User"
                        isVisible={this.state.modalVisible} />
                }
                <UserForm
                    onChange={this.onChange}
                    projectSelected={this.projectSelected}
                    moduleSelected={this.moduleSelected}
                    onSubmit={this.onSubmit}
                    isEditMode={true}
                    cancelClicked={this.cancelClicked}
                    allSelected={this.allSelected}
                    projectSelectAll={this.projectSelectAll}
                    projectChecked={this.projectChecked}
                    projectAllSelected={this.projectAllSelected}
                    selectCompany={this.selectCompany}
                    displayWarning={this.displayWarning}
                    search={this.search}
                    hasEditPermission={this.hasEditPermission}
                    hasDeletePermission={this.hasDeletePermission}
                    hasCreatePermission={this.hasCreatePermission}
                    isLoading={this.props.isLoading}
                    displayCompanyName={this.getCompanyname}
                    displayPropertyName={this.getPropertyname}
                    clientSaved={this.clientSaved}
                    {...this.state}
                />
            </div>

        );
    }
}

UserEdit.propTypes = {
    userTypes: PropTypes.array.isRequired,
    claims: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    clients: PropTypes.array.isRequired,
    singleUser: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
};
UserEdit.defaultProps = {};
const mapStateToProps = state => {
    return ({
        claims: state.claims.list,
        userTypes: state.userTypes.list,
        isLoading: state.users.isLoading || state.userTypes.isLoading || state.projects.isLoading || state.clients.isLoading || state.claims.isLoading,
        singleUser: state.users.selected,
        projects: state.projects.list,
        clients: state.clients.list,
        user: state.userAuthentication.user,
    });
};
const mapDispatchToProps = dispatch => ({
    claimActions: bindActionCreators(claimsActions, dispatch),
    actions: bindActionCreators(actionCreators, dispatch),
    userTypeActions: bindActionCreators(userTypeActions, dispatch),
    projectActions: bindActionCreators(projectActions, dispatch),
    clientActions: bindActionCreators(clientActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch)
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserEdit));
