import axios from "axios";

const SHIPMENT_STATUS_FETCHED = "SHIPMENT_STATUS_FETCHED";
const SHIPMENT_STATUS_LOADING = "SHIPMENT_STATUS_LOADING";

export const actionCreators = {
    fetchShipmentStatuses: () => async (dispatch, getState) => {
        if (getState().shipmentStatuses.isLoading) {
            return;
        }
        try {
            let url = "/api/shipmentStatus";
            dispatch({type: SHIPMENT_STATUS_LOADING, isLoading: true});
            let response = await axios.get(url);
            dispatch({type: SHIPMENT_STATUS_LOADING, isLoading: false});
            dispatch({type: SHIPMENT_STATUS_FETCHED, shipmentStatuses: response.data});
        } catch (e) {
            dispatch({type: SHIPMENT_STATUS_LOADING, isLoading: false});
        }
    }
};

const initialState = {
    list: [],
    isLoading: false
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case SHIPMENT_STATUS_LOADING:
            return {...state, isLoading: action.isLoading};
        case SHIPMENT_STATUS_FETCHED:
            return {...state, list: action.shipmentStatuses};
        default:
            return state;
    }
};