import React from 'react';


function WarningModal(props) {
    return (
       
        <div id="warning_modal" className={"modal-stackedBackdrop modal fade in"}
            style={{ "display": props.isVisible === true ? "block" : "none" }}>
            {
                //  className={ props.isDismissBackDrop ? "modal-stackedBackdrop" : '' + "modal fade in" }
            }
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={props.closeButton}>×</button>
                        <h4 className="modal-title">{props.modalHeading} ?</h4>
                    </div>
                    <div className="modal-body">
                        <div className={props.modalType === "success" ? "" : "alert alert-danger m-b-0"}>
                            {
                                //props.modalType === "success" ? ""
                                //                        : <h4><i className={props.modalType === "success" ? "" : "fa fa-info-circle"} /> {props.modalHeading}</h4>
                            }
                            <p>{props.modalText}</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="javascript:;" onClick={props.buttonLeftClick}
                           className="btn btn-sm btn-white">{props.buttonLeftText}</a>
                        <a href="javascript:;" onClick={props.buttonRightClick}
                            className={props.modalType === "success" ?"btn btn-sm btn-success": "btn btn-sm btn-danger"}>{props.buttonRightText}</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

WarningModal.propTypes = {};
WarningModal.defaultProps = {};

export default WarningModal;
