import React, {Component} from 'react';
import {connect} from "react-redux";
import {actionCreators} from '../../store/UserAuthentication';
import {bindActionCreators} from "redux";
import NotificationsDropdown from "./NotificationsDropdown";
import classnames from "classnames";
import { faBars } from  '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userOptionsMenuOpen: false
        }
    }

    changeUserOptionMenuVisibility = () => {
        this.setState(prevState => ({userOptionsMenuOpen: !prevState.userOptionsMenuOpen}));
    };

    render() {
        return <div id="header" className="header navbar navbar-default navbar-fixed-top">
            <div className="container-fluid">
                <div className="navbar-header">
                    <a className="navbar-toggle collapsed navbar-toggle-left m-l-0"
                       
                        >
                        <FontAwesomeIcon
                            icon={faBars}
                            size="3x"
                            onClick={this.props.minifySideBar}
                            style={{ color: 'gray', marginTop: '12px', marginBottom: '-10px', marginLeft:'0px !important' }}
                        />                        
                    </a>
                    <a  className="navbar-toggle" data-click="sidebar-toggled">
                        <FontAwesomeIcon
                            icon={faBars}
                            size="3x"
                            //onClick={this.props.minifySideBar}
                            style={{ color: 'gray' }}
                        />                    
                    </a>
                    
                    <a href="/" className="navbar-brand">
                        Axis Worldwide
                    </a>
                </div>

                <ul className="nav navbar-nav navbar-right">
                    <NotificationsDropdown/>
                    <li className={classnames("dropdown navbar-user", {"open": this.state.userOptionsMenuOpen})}>
                        <a className="dropdown-toggle" onClick={this.props.actions.logoutUser}>
                            <span className="hidden-xs m-r-10" style={{verticalAlign:"top"}}>Hi, {this.props.user.unique_name}</span>
                            {//<i className="fa fa-sign-out fa-2x m-l-10" />
                                <FontAwesomeIcon
                                    icon="sign-out-alt" size="2x"
                                />
                            }
                        </a>
                        <ul className="dropdown-menu animated fadeInLeft">
                            <li className="arrow"/>
                            <li><a href="javascript:;">Edit Profile</a></li>
                            <li><a href="javascript:;"><span
                                className="badge badge-danger pull-right">2</span> Inbox</a>
                            </li>
                            <li><a href="javascript:;">Calendar</a></li>
                            <li><a href="javascript:;">Setting</a></li>
                            <li className="divider"/>
                            <li>
                                <a href="javascript:;" onClick={this.props.actions.logoutUser}>
                                  Log Out
                                </a>
                                {
                                   
                                }
                            </li>
                        </ul>
                    </li>
                </ul>

                <div className="search-form">
                    <button className="search-btn" type="submit"><i className="material-icons">search</i></button>
                    <input type="text" className="form-control" placeholder="Search Something..."/>
                    <a href="#" className="close" data-dismiss="navbar-search"><i
                        className="material-icons">close</i></a>
                </div>
            </div>
        </div>;
    }

}

const mapStateToProps = state => ({user: state.userAuthentication.user});
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators(actionCreators, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(Header);