import axios from "axios";

const USERTYPES_FETCH = "USERTYPES_FETCH";
const USERTYPES_IS_LOADING = "USERTYPES_IS_LOADING";

export const actionCreators = {
    fetchUserTypes: state => async (dispatch, getState) => {
        if (getState().userTypes.isLoading) {
            return;
        }
        dispatch({type: USERTYPES_IS_LOADING, isLoading: true});
        var url = "/api/usertype";
        var response = await axios.get(url);
        dispatch({type: USERTYPES_FETCH, userTypes: response.data});
        dispatch({type: USERTYPES_IS_LOADING, isLoading: false});
    }
};

const initialState = {
    isLoading: false,
    list: []
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case USERTYPES_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case USERTYPES_FETCH:
            return {...state, list: action.userTypes};
        default:
            return state;
    }
};