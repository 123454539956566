import axios from "axios";

const CHARGE_TYPES_FETCHED = "CHARGE_TYPES_FETCHED";
const CHARGE_TYPES_IS_LOADING = "CHARGE_TYPES_IS_LOADING";

export const actionCreators = {
    //abdul method
    fetchChargeTypes: (isClient) => async (dispatch, getState) => {
        if (getState().chargeTypes.isLoading) {
            return;
        }
        const url = "/api/chargeType";
        let config = null;
        dispatch({type: CHARGE_TYPES_IS_LOADING, isLoading: true});
        if (isClient) {
            config = {
                params: {isClient: true}
            }
        }
        let response = await axios.get(url,config);
        dispatch({type: CHARGE_TYPES_FETCHED, chargeTypes: response.data});
        dispatch({type: CHARGE_TYPES_IS_LOADING, isLoading: false});
    },
    //dharmendra method
    getChargeTypeByCategory: (categoryId) => async (dispatch, getState) => {
        if (getState().chargeTypes.isLoading) {
            return;
        }
        const url = "/api/chargeType/" + categoryId;
        dispatch({ type: CHARGE_TYPES_IS_LOADING, isLoading: true });
        let response = await axios.get(url);       
        dispatch({ type: CHARGE_TYPES_FETCHED, chargeTypes: response.data });
        dispatch({ type: CHARGE_TYPES_IS_LOADING, isLoading: false });
    },
};

const initialState = {
    isLoading: false,
    list: []
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case CHARGE_TYPES_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        case CHARGE_TYPES_FETCHED:
            return {
                ...state,
                list: action.chargeTypes
            };
        default:
            return state;
    }
};