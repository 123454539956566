import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ClientSelectOption extends Component {
    handleMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if(!this.props.option.isFirst){
            this.props.onSelect(this.props.option, event);
        }
    };

    handleMouseEnter = (event) => {
        this.props.onFocus(this.props.option, event);
    };
    handleMouseMove = (event) => {
        if (this.props.isFocused) return;
        this.props.onFocus(this.props.option, event);
    };

    render() {       
        let address = this.props.option.addresses[0];
        return (
            <div className={this.props.className}
                 onMouseDown={this.handleMouseDown}
                 onMouseEnter={this.handleMouseEnter}
                 onMouseMove={this.handleMouseMove}>
                {this.props.option.isFirst === true && this.props.option.link}
                {!this.props.option.isFirst=== true && <h5>{this.props.option.label}</h5>}
                {!this.props.option.isFirst=== true && <p>{address && (address.address1 +", " + address.address2)}</p>}
                {!this.props.option.isFirst=== true && <p>{address && address.city}, {address && address.state}, {address && address.postalCode}</p>}
                {this.props.children}
            </div>
        );
    }
}

ClientSelectOption.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
    onFocus: PropTypes.func,
    onSelect: PropTypes.func,
    option: PropTypes.object.isRequired
};
ClientSelectOption.defaultProps = {};

export default ClientSelectOption;
