import React from 'react';
import {connect} from 'react-redux';

const Home = props => (
    <div>
        <h1>Welcome {props.user.unique_name}</h1>
    </div>
);
const mapStateToProps = state => ({user: state.userAuthentication.user});
export default connect(mapStateToProps, null)(Home);
