import axios from "axios";


const RESETPASS_IS_LOADING = "RESETPASS_IS_LOADING";
const RESETPASS_SUBMIT = "RESETPASS_SUBMIT";

export const actionCreators = {
    resetPassword: state => async (dispatch, getState) => {
        if (getState().resetPassword.isLoading) {
            return;
        }
        try {
            dispatch({ type: RESETPASS_IS_LOADING, isLoading: true});
            const url = `api/account/reset-password`;
            const response = await axios.post(url, state);
            //let Message = response.data;
            dispatch({ type: RESETPASS_SUBMIT, Message: response.data});
            dispatch({ type: RESETPASS_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({ type: RESETPASS_IS_LOADING, isLoading: false});
            throw e;
        }
    }
};

const initialState = {
    user: {},
    isLoading: false,
    passwordReset: false,
    Message: null
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case RESETPASS_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        case RESETPASS_SUBMIT:
            return {
                ...state,
                passwordReset: true,
                Message: action.Message
            };
        default:
            return state;
    }
};