import React from 'react';

function ProjectListSelectable(props) {
    return (
        <div >
            <div className="row m-b-0">
                {
                    <table className="table table-condensed table-hover  m-b-0">
                        {
                            <thead>
                                <tr>
                                    <th style={{ "width": "6%", 'paddingLeft': '20px' }}><input type="checkbox" onChange={props.selectAll} checked={props.projectAllSelected()} /></th>
                                    <th style={{ "width": "10%" }}>Project #</th>
                                    <th style={{ "width": "25%" }}>Company</th>
                                    <th style={{ "width": "25%" }}>Property</th>
                                    <th style={{ "width": "14%" }}>Status</th>
                                </tr>
                            </thead>
                        }
                    </table>
                }
            </div>
            <div className="projectAccessList">
                <table className="table table-hover tbl_shipment" style={{ 'maxHeight':'435px'}}>                
                <tbody>
                {props.data && props.data.map(row => {
                    return (
                        <tr key={row.id}>
                            <td style={{"width":"6%"}}><input type="checkbox" onChange={(e) => props.onChange(e, row.id)} checked={props.isChecked(row.id)} /></td>
                            <td style={{ "width": "10%" }}>{row.id}</td>
                            <td style={{ "width": "25%" }}>
                                {
                                    row.customerName && row.customerName
                                    //row.projectClients && row.projectClients.length > 0 &&
                                    //props.displayCompanyName(row.projectClients, 1)
                                }
                            </td>
                            <td style={{ "width": "25%" }}>{
                                row.jobSiteName && row.jobSiteName 
                                //row.projectClients && row.projectClients.length > 0 &&
                                //props.displayCompanyName(row.projectClients, 3)
                            }</td>
                            <td style={{ "width": "14%" }}>{row.status && row.status}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            </div>
        </div>
    );
}

ProjectListSelectable.propTypes = {};
ProjectListSelectable.defaultProps = {};

export default ProjectListSelectable;
