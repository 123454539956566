import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var classNames = require('classnames');

class SideBarNavMenu extends Component {
    constructor(props) {
        super(props);
        this.expand = this.expand.bind(this);
        this.hasChildren= this.hasChildren.bind(this);
        this.state = {
            expand: false,
        }
    }

    componentWillMount() {
        if(this.props.isActive()){
            this.setState({
                expand: true
            });
        }
    }

    expand() {
        this.setState({
            expand: !this.state.expand
        });
    }
    hasChildren(){
        if(this.props.children){
            return <a href="javascript:;" onClick={this.expand}>
                {this.props.children && <b className="caret pull-right"/>}
                <i className="material-icons">{this.props.icon}</i>
                <span>{this.props.name}</span>
            </a>;
        }
        return <Link to={this.props.to} onClick={this.expand}>
            {this.props.children && <b className="caret pull-right"/>}
            {
             // <i className={this.props.isClass ? this.props.icon : 'material-icons'}>{this.props.isClass ? '' : this.props.icon}</i>
            }
            {
                <FontAwesomeIcon icon={this.props.icon} size="lg" fixedWidth />
            }
            <span className="p-l-2"> {this.props.name}</span>
        </Link>
    }
    render() {

        return (
            //for solving grey bs white issue i hv made changes 
            //initially { 'expand': this.state.expand }
            //changed
            //{ 'expand': this.props.children ? this.state.expand : false }
            <li className={classNames('has-sub', { 'expand': this.props.children ? this.state.expand : false }, { "active": this.props.isActive() })}>
                {this.hasChildren()}
                {this.props.children &&
                <ul className="sub-menu" style={{display: this.state.expand ? 'block' : 'none'}}>
                    {this.props.children}
                </ul>}

            </li>
        );
    }
}

export default SideBarNavMenu;