import axios from "axios";


const USER_IS_LOADING = "USER_IS_LOADING";
const USER_FETCH = "USER_FETCH";
const USER_FETCH_BY_TYPE = "USER_FETCH_BY_TYPE";
const USER_FETCH_PAGED = "USER_FETCH_PAGED";
const USER_FETCH_SINGLE = "USER_FETCH_SINGLE";
const USER_SAVED = "USER_SAVED";

export const actionCreators = {
    createUser: state => async (dispatch, getState) => {
        try {
            if (getState().users.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            dispatch({type: USER_IS_LOADING, isLoading: true});
            const url = "/api/user";
            let response = await axios.post(url, state);
            dispatch({type: USER_SAVED, lastSaved: response.data});
            dispatch({type: USER_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: USER_IS_LOADING, isLoading: false});
            throw e;
        }


    },
    fetchUsers: () => async (dispatch, getState) => {
        if (getState().users.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        const url = "/api/user";
        dispatch({type: USER_IS_LOADING, isLoading: true});
        let response = await axios.get(url);
        dispatch({type: USER_FETCH, users: response.data});
        dispatch({type: USER_IS_LOADING, isLoading: false});
    },
    fetchUsersByTypes: typeId => async (dispatch, getState) => {
        try {
            if (getState().clients.isLoading) {
                return;
            }
            dispatch({type: USER_IS_LOADING, isLoading: true});
            const url = "api/user";
            let response = await axios.get(url, {params: {typeId}});
            dispatch({type: USER_FETCH_BY_TYPE, users: response.data});
            dispatch({type: USER_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: USER_IS_LOADING, isLoading: false});
            throw(e);
        }
    },
    fetchPaged: (pageIndex, pageSize) => async (dispatch, getState) => {
        if (getState().users.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        const url = "/api/user/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize;
        dispatch({type: USER_IS_LOADING, isLoading: true});
        let response = await axios.get(url);
        dispatch({type: USER_FETCH_PAGED, users: response.data});
        dispatch({type: USER_IS_LOADING, isLoading: false});
    },
    getSingle: id => async (dispatch, getState) => {
        if (getState().users.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        const url = "/api/user/" + id;
        dispatch({type: USER_IS_LOADING, isLoading: true});
        let response = await axios.get(url);
        dispatch({type: USER_FETCH_SINGLE, user: response.data});
        dispatch({type: USER_IS_LOADING, isLoading: false});
    },
    updateUser: state => async (dispatch, getState) => {
        if (getState().users.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        try {
            dispatch({type: USER_IS_LOADING, isLoading: true});
            const url = "/api/user";
            await axios.put(url, state);
            dispatch({type: USER_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: USER_IS_LOADING, isLoading: false});
            throw e;
        }

    },
    deleteUser: id => async (dispatch, getState) => {
        if (getState().users.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        if (getState().userAuthentication.user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] === id) {
            // eslint-disable-next-line
            throw "Can't delete signed in user!";
        }
        try {
            dispatch({type: USER_IS_LOADING, isLoading: true});
            const url = "/api/user/" + id;
            await axios.delete(url);
            dispatch({type: USER_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: USER_IS_LOADING, isLoading: false});
            throw e;
        }
    },
    searchUsers: (searchFields, paged, pageIndex, pageSize, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().users.isLoading) {
            return;
        }
        try {
            dispatch({type: USER_IS_LOADING, isLoading: true});
            const url = "/api/user/search";
            let response = await axios.post(url, {searchFields, paged, pageIndex, pageSize, sortColumn, reverse});
            if (paged) {
                dispatch({type: USER_FETCH_PAGED, users: response.data});
            }
            else {
                dispatch({type: USER_FETCH, users: response.data});
            }
            dispatch({type: USER_IS_LOADING, isLoading: false});

        }
        catch (e) {
            dispatch({type: USER_IS_LOADING, isLoading: false});
            throw e;
        }

    }
};

const initialState = {
    list: [],
    isLoading: false,
    selected: null,
    lastSaved: null,
    byTypeList: [],
    paged: {}
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case USER_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case USER_FETCH:
            return {...state, list: action.users};
        case USER_FETCH_SINGLE:
            return {...state, selected: action.user};
        case USER_FETCH_BY_TYPE:
            return {...state, byTypeList: action.users};
        case USER_FETCH_PAGED:
            return {...state, paged: action.users};
        case USER_SAVED:
            return {...state, lastSaved: action.lastSaved};
        default:
            return state;
    }
};