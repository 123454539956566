import axios from "axios";
const CLIENT_FETCH = "CLIENT_FETCH";
const CLIENT_FETCH_TYPE = "CLIENT_FETCH_TYPE";
const CLIENT_FETCH_PAGED = "CLIENT_FETCH_PAGED";
const CLIENT_FETCH_SINGLE = "CLIENT_FETCH_SINGLE";
const CLIENT_IS_LOADING = "CLIENT_IS_LOADING";
const CLIENT_SAVED = "CLIENT_SAVED";
const CLIENT_FETCH_TYPE_ClientList = "CLIENT_FETCH_TYPE_ClientList";
const CLIENT_FETCH_TYPE_PropertyList = "CLIENT_FETCH_TYPE_PropertyList";
const CLIENT_FETCH_TYPE_allClientList = "CLIENT_FETCH_TYPE_allClientList";
const CLIENT_FETCH_QBVENDORS = "CLIENT_FETCH_QBVENDORS";
const CLIENT_FETCH_ADDRESS = "CLIENT_FETCH_ADDRESS";
const CLIENT_FETCH_SINGLE_BILLING = "CLIENT_FETCH_SINGLE_BILLING";
const CLIENT_FETCH_THIRD_PARTY = "CLIENT_FETCH_THIRD_PARTY";
const ADDRESS_SAVED = "ADDRESS_SAVED";
const CONTACTS_SAVED = "CONTACTS_SAVED";
export const actionCreators = {
    fetchClients: (moduleName, moduleId) => async (dispatch, getState) => {
        try {
            //if (getState().clients.isLoading) {
            //    return;
            //}
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            //if we werent passed a module name assume all
            moduleName = (!!moduleName) ? moduleName : "all";
            //if we werent passed a module id assume 0
            moduleId = (!!moduleId) ? moduleId : 0;

            const url = "/api/client/getClients?moduleName=" + moduleName + "&moduleId=" + moduleId;
            await axios.get(url)
                .then(function (response) {
                    //success handled here
                    dispatch({ type: CLIENT_FETCH, clients: response.data });
                })
                .catch(function (error) {
                    //if error
                    console.clear();
                    console.log(error);
                    dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
                })
                .then(function () {
                    // always executed
                    dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
                });;

            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw (e);
        }
    },
    getSingleClientAddress: (moduleName, moduleId, clientId) => async (dispatch, getState) => {
        try {
            if (getState().clients.isLoading) {
                return;
            }
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            //const url = "api/client";
            //if we werent passed a module name assume all
            moduleName = (!!moduleName) ? moduleName : "all";
            //if we werent passed a module id assume 0
            moduleId = (!!moduleId) ? moduleId : 0;
            const url = "/api/client/getClients?moduleName=" + moduleName + "&moduleId=" + moduleId + "&clientId=" + clientId;
            let response = await axios.get(url);
            dispatch({ type: CLIENT_FETCH_ADDRESS, clientDetails: response.data });
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw (e);
        }
    },
    fetchClientsByTypes: typeId => async (dispatch, getState) => {
        try {
            if (getState().clients.isLoading) {
                //console.log(getState().clients.isLoading);
                return;
            }
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            const url = "api/client";
            let response = await axios.get(url, { params: { typeId } });
            dispatch({ type: CLIENT_FETCH_TYPE, clients: response.data });
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw (e);
        }
    },
    fetchPaged: (pageIndex, pageSize) => async (dispatch, getState) => {
        if (getState().clients.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        const url = "/api/client/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize;
        dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: CLIENT_FETCH_PAGED, paged: response.data });
        dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
    },
    createClient: state => async (dispatch, getState) => {
        try {
            if (getState().clients.isLoading) {
                return;
            }
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            const url = "api/client";
            let response = await axios.post(url, state);
            dispatch({ type: CLIENT_SAVED, lastSaved: response.data });
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getSingle: (id) => async (dispatch, getState) => {
        try {
            if (getState().clients.isLoading) {
                return;
            }
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            const url = "api/client/" + id;
            let response = await axios.get(url);
            dispatch({ type: CLIENT_FETCH_SINGLE, client: response.data });
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw (e);
        }
    },
    updateClient: state => async (dispatch, getState) => {
        try {
            if (getState().clients.isLoading) {
                return;
            }
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            const url = "api/client";
            await axios.put(url, state);
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    searchClients: (searchFields, paged, pageIndex, pageSize, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().clients.isLoading) {
            return;
        }
        try {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            const url = "/api/client/search";
            let response = await axios.post(url, { searchFields, paged, pageIndex, pageSize, sortColumn, reverse });
            if (paged === true) {
                dispatch({ type: CLIENT_FETCH_PAGED, paged: response.data });
            }
            else {
                dispatch({ type: CLIENT_FETCH, clients: response.data });
            }
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    deleteClient: id => async (dispatch, getState) => {
        if (getState().clients.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        try {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            const url = "/api/client/" + id;
            await axios.delete(url);
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    addContact: contact => async (dispatch, getState) => {
        try {
            if (getState().clients.isLoading) {
                return;
            }
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            const url = "api/client/addContact";
            await axios.post(url, contact).then(function (response) {
                //success handled here
                dispatch({ type: CONTACTS_SAVED, lastSavedContact: response.data });
                dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            })
                .catch(function (error) {
                    //if error
                    console.clear();
                    console.log(error);
                    dispatch({ type: CONTACTS_SAVED, lastSavedContact: null });
                    dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
                })
                .then(function () {
                    // always executed
                    dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
                });

        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getClients: typeId => async (dispatch, getState) => {
        try {
            if (getState().clients.isLoading) {
                //console.log(getState().clients.isLoading);
                return;
            }
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            const url = "api/client";
            let response = await axios.get(url, { params: { typeId } });
            if (typeId === 1) {
                dispatch({ type: CLIENT_FETCH_TYPE_ClientList, clientLists: response.data });
            }
            else if (typeId === 3) {
                dispatch({ type: CLIENT_FETCH_TYPE_PropertyList, propertyList: response.data });
            }
            else if (typeId === 100) {
                ////consigee should bing  company of types  Client, Vendor, Property, Carrier, Warehouse, Installation
                dispatch({ type: CLIENT_FETCH_TYPE_allClientList, allClientList: response.data });
            }
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw (e);
        }
    },
    getQBVendors: () => async (dispatch, getState) => {
        try {
            //if (getState().clients.isLoading) {
            //    //console.log(getState().clients.isLoading);
            //    return;
            //}
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            const url = "api/QBSync/GetVendors";
            let response = await axios.get(url);
            dispatch({ type: CLIENT_FETCH_QBVENDORS, qbVendors: response.data });
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw (e);
        }
    },
    //this is same as get single but since i am using get single for other purpose(like save after pull from tabs)
    //so must different state for this in billing tab of shipment
    getDefaultBillingCient: (id) => async (dispatch, getState) => {
        try {
            if (getState().clients.isLoading) {
                return;
            }
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            const url = "api/client/" + id;
            let response = await axios.get(url);
            dispatch({ type: CLIENT_FETCH_SINGLE_BILLING, billingClient: response.data });
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw (e);
        }
    },
    getThirdPartyCient: (id) => async (dispatch, getState) => {
        try {
            if (getState().clients.isLoading) {
                return;
            }
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            const url = "api/client/" + id;
            await axios.get(url)
                .then(function (response) {
                    //success handled here
                    dispatch({ type: CLIENT_FETCH_THIRD_PARTY, thirdPartyClient: response.data });
                })
                .catch(function (error) {
                    //if error
                    console.clear();
                    console.log(error);
                    dispatch({ type: CLIENT_FETCH_THIRD_PARTY, thirdPartyClient: null });
                    dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
                })
                .then(function () {
                    // always executed
                    dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
                });


        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw (e);
        }
    },
    addAddress: contact => async (dispatch, getState) => {
        try {
            if (getState().clients.isLoading) {
                return;
            }
            dispatch({ type: CLIENT_IS_LOADING, isLoading: true });
            const url = "api/client/addAddress";
            await axios.post(url, contact)
                .then(function (response) {
                    //success handled here
                    dispatch({ type: ADDRESS_SAVED, lastSavedAddress: response.data });
                    dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
                })
                .catch(function (error) {
                    dispatch({ type: ADDRESS_SAVED, lastSavedAddress: null });
                    dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
                })
                .then(function () {
                    // always executed
                    dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
                });

        }
        catch (e) {
            dispatch({ type: CLIENT_IS_LOADING, isLoading: false });
            throw e;
        }
    },
};

const initialState = {
    isLoading: false,
    list: [],
    byTypeList: [],
    propertyList: [],
    clientLists: [],
    allClientList: [],
    paged: {},
    selected: null,
    lastSaved: null,
    qbVendors: [],
    clientDetails: [],
    billingClient: null,
    thirdPartyClient: null,
    lastSavedAddress: null,
    lastSavedContact: null,
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case CLIENT_FETCH:
            return { ...state, list: action.clients };
        case CLIENT_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case CLIENT_FETCH_TYPE:
            return { ...state, byTypeList: action.clients };
        case CLIENT_FETCH_SINGLE:
            return { ...state, selected: action.client };
        case CLIENT_SAVED:
            return { ...state, lastSaved: action.lastSaved };
        case CLIENT_FETCH_PAGED:
            return { ...state, paged: action.paged };
        case CLIENT_FETCH_TYPE_PropertyList:
            return { ...state, propertyList: action.propertyList };
        case CLIENT_FETCH_TYPE_ClientList:
            return { ...state, clientLists: action.clientLists }
        case CLIENT_FETCH_TYPE_allClientList:
            return { ...state, allClientList: action.allClientList }
        case CLIENT_FETCH_QBVENDORS:
            return { ...state, qbVendors: action.qbVendors }
        case CLIENT_FETCH_ADDRESS:
            return { ...state, clientDetails: action.clientDetails };
        case CLIENT_FETCH_SINGLE_BILLING:
            return { ...state, billingClient: action.billingClient };
        case CLIENT_FETCH_THIRD_PARTY:
            return { ...state, thirdPartyClient: action.thirdPartyClient };
        case ADDRESS_SAVED:
            return { ...state, lastSavedAddress: action.lastSavedAddress };
        case CONTACTS_SAVED:
            return { ...state, lastSavedContact: action.lastSavedContact };
        default:
            return state;
    }
};