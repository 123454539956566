import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {actionCreators} from "../../store/UserAuthentication";
import toastr from "toastr";


class Login extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = {
            userName: "",
            password: ""
        }
    }

    componentWillUnmount() {
        document.body.style.backgroundColor = "#D8D8D8";
        //document.body.classList.add("bg-white");
    }

    async submit(e) {
        e.preventDefault();
        try {
            await this.props.actions.loginUser(this.state);
            this.props.history.push("/");
        }
        catch (error) {
            if(error.response){
                const data = error.response.data[""];
                for (let i = 0; i < data.length; i++) {
                    toastr.error(data[i]);
                }
            }
            else{
                toastr.error(error);
            }
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        document.body.style.backgroundColor = "#212121";
        //document.body.classList.remove("bg-white");
        return (
            <div id="page-container">
                <div className="login login-with-news-feed">
                    <div className="news-feed">
                        <div className="news-image">
                            <img src={require("../../assets/img/login page_hero.jpg")} alt=""/>
                        </div>
                        <div className="news-caption">
                            <h4 className="caption-title">AXIS WorldWide</h4>
                            <p>
                                When it comes to logistics, we deliver the best of both worlds.
                            </p>
                        </div>
                    </div>
                    <div className="right-content bg-grey-900">
                        <div className="login-header">
                            <div className="brand">
                                <img className="login-logo" src={require("../../assets/img/golden_logo.png.jpg")} alt="logo" style={{ "width":"455px" }}/>
                            </div>
                            <div className="icon">
                                <i className="fa fa-sign-in"/>
                            </div>
                        </div>
                        <div className="login-content">
                            <form onSubmit={this.submit} className="margin-bottom-0">
                                <div className="form-group m-b-15">
                                    <input name="userName" value={this.state.userName} type="text" onChange={this.onChange}
                                           className="form-control input-lg" placeholder="Username" required/>
                                </div>
                                <div className="form-group m-b-15">
                                    <input name="password" type="password" className="form-control input-lg"
                                           onChange={this.onChange}
                                           placeholder="Password" required/>
                                </div>
                                <div className="checkbox m-b-30">
                                    <label>
                                        <input type="checkbox"/> Remember Me
                                    </label>
                                </div>
                                <div className="login-buttons">
                                    <button type="submit" className="btn login-button btn-block btn-lg">Sign me in
                                    </button>
                                </div>
                                <div className="m-t-20 m-b-40 p-b-40">
                                    Forgot password? Click <a href="/account/forgot-password"
                                                               className="text-axis-blue">here</a>.
                                </div>
                                <hr/>
                                <p className="text-center">
                                    &copy; AXIS Worldwide. All Rights Reserved {(new Date()).getFullYear()}
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actionCreators, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(Login)