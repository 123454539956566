import axios from "axios";

const CLIENT_TYPES_FETCHED = "CLIENT_TYPES_FETCHED";
const CLIENT_TYPES_LOADING = "CLIENT_TYPES_LOADING";

export const actionCreators = {
    fetchClientTypes: () => async (dispatch, getState) => {
        if (getState().clientTypes.isLoading) {
            //Do not issue duplicate request
            return;
        }
        dispatch({type: CLIENT_TYPES_LOADING, isLoading: true});
        const url = "/api/clientType";
        let response = await axios.get(url);
        dispatch({type: CLIENT_TYPES_FETCHED, clientTypes: response.data});
        dispatch({type: CLIENT_TYPES_LOADING, isLoading: false});
    }
};

const initialState = {
    list: [],
    isLoading: false
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case CLIENT_TYPES_LOADING:
            return {...state, isLoading: action.isLoading};
        case CLIENT_TYPES_FETCHED:
            return {...state, list: action.clientTypes};
        default:
            return state;
    }
};