import axios from "axios"
const PURCHASE_ORDER_FETCH = "PURCHASE_ORDER_FETCH";
const PURCHASE_ORDER_SINGLE_FETCH = "PURCHASE_ORDER_SINGLE_FETCH";
const PURCHASE_ORDER_IS_LOADING = "PURCHASE_ORDER_IS_LOADING";
// eslint-disable-next-line 
const PURCHASE_ORDER_ITEM_IS_LOADING = "PURCHASE_ORDER_IS_LOADING";
const PURCHASE_ORDER_SAVED = "PURCHASE_ORDER_SAVED";
const PURCHASE_ORDER_FETCH_PAGED = "PURCHASE_ORDER_FETCH_PAGED";
const PURCHASE_ORDER_FETCH_PAGED_ByPROJECTID = "PURCHASE_ORDER_FETCH_PAGED_ByPROJECTID";
const PURCHASE_ORDER_FETCH_PAGED_PROJECTID = "PURCHASE_ORDER_FETCH_PAGED_PROJECTID";
const PURCHASE_ORDER_COUNT_FETCHED = "PURCHASE_ORDER_COUNT_FETCHED";
const PURCHASE_ORDER_FETCH_PAGED_MODAL = "PURCHASE_ORDER_FETCH_PAGED_MODAL";

export const actionCreators = {
    fetchPurchaseOrder: () => async (dispatch, getState) => {
        if (getState().purchaseOrders.isLoading) {
            return;
        }
        dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
        const url = "/api/purchaseOrder";
        let response = await axios.get(url);

        dispatch({ type: PURCHASE_ORDER_FETCH, purchaseOrders: response.data });
        dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });

    },
    fetchPaged: (pageIndex, pageSize,requiredData) => async (dispatch, getState) => {
        if (getState().purchaseOrders.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        const url = "/api/purchaseOrder/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&requiredData=" + requiredData;
        dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: PURCHASE_ORDER_FETCH_PAGED, paged: response.data });
        dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
    },
    //because if pagination search sort will work on avode methos cause issue for change followup dates
    fetchPagedModal: (pageIndex, pageSize, requiredData) => async (dispatch, getState) => {
        if (getState().purchaseOrders.isLoading) {           
            return;
        }
        const url = "/api/purchaseOrder/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&requiredData=" + requiredData;
        dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: PURCHASE_ORDER_FETCH_PAGED_MODAL, pagedData: response.data });
        dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
    },
    getSinglePurchaseOrder: id => async (dispatch, getState) => {
        if (getState().purchaseOrders.isLoading) {
            return;
        }
        try {
            dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
            const url = "/api/purchaseOrder/" + id;
            let response = await axios.get(url);
            dispatch({ type: PURCHASE_ORDER_SINGLE_FETCH, selected: response.data });
            dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
        }
    },
     createPurchaseOrder: state => async (dispatch, getState) => {
         if (getState().purchaseOrders.isLoading) {
            return;
        }
        try {
            dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
            const url = "/api/purchaseOrder";
           // console.log(state);
            let response = await axios.post(url, state);
            dispatch({ type: PURCHASE_ORDER_SAVED, lastSaved: response.data });
            dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
            
        }
        catch (e) {
            dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
        }
    },
     updatePurchaseOrder: state => async (dispatch, getState) => {
         try {
             if (getState().purchaseOrders.isLoading) {
                 return;
             }
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
             const url = "/api/purchaseOrder";
             await axios.put(url, state);
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
         }
         catch (e) {
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
             throw e;
         }
    },
    updateFollowUpdate: state => async (dispatch, getState) => {
        try {
            if (getState().purchaseOrders.isLoading) {
                return;
            }
            dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
            const url = "/api/purchaseOrder/updatefolloupdate";
            await axios.put(url, state);
            dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
            throw e;
        }
    },
     deletePurchaseOrder: id => async (dispatch, getState) => {
         try {
             if (getState().purchaseOrders.isLoading) {
                 return;
             }
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
             const url = "/api/purchaseOrder/" + id
             
             await axios.delete(url);
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
         }
         catch (e) {
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
             throw e;
         }
     },
     searchPurchaseOrder: (searchFields, paged, pageIndex, pageSize) => async (dispatch, getState) => {
         if (getState().purchaseOrders.isLoading) {
             return;
         }
         try {
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
             const url = "/api/purchaseOrder/search";
             let response = await axios.post(url, { searchFields, paged, pageIndex, pageSize });
             if (paged === true) {
                 dispatch({ type: PURCHASE_ORDER_FETCH_PAGED, paged: response.data });
             }
             else {
                 dispatch({ type: PURCHASE_ORDER_FETCH, purchaseOrders: response.data });
             }
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
         }
         catch (e) {
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
             throw e;
         }

     },
     searchSortPO: (searchFields, paged, pageIndex, pageSize, sortColumn, reverse) => async (dispatch, getState) => {
         if (getState().purchaseOrders.isLoading) {
             return;
         }
         try {
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
             const url = "/api/purchaseOrder/search";
             let response = await axios.post(url, { searchFields, paged, pageIndex, pageSize, sortColumn, reverse });
             if (paged === true) {
                 dispatch({ type: PURCHASE_ORDER_FETCH_PAGED, paged: response.data });
             }
             else {
                 dispatch({ type: PURCHASE_ORDER_FETCH, purchaseOrders: response.data });
             }
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
         }
         catch (e) {
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
             throw e;
         }

    },
    searchSortPOModal: (searchFields, paged, pageIndex, pageSize, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().purchaseOrders.isLoading) {
            return;
        }
        try {
            dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
            const url = "/api/purchaseOrder/search";
            let response = await axios.post(url, { searchFields, paged, pageIndex, pageSize, sortColumn, reverse });
            if (paged === true) {
                dispatch({ type: PURCHASE_ORDER_FETCH_PAGED_MODAL, pagedData: response.data });
            }
            dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
            throw e;
        }

    },
     //api for listing purhcase order in project module
     //get purchaseOrder list by project id
     getPoByProjectIdPaged: (pageIndex, pageSize,projectId) => async (dispatch, getState) => {
         //if (getState().purchaseOrders.isLoading) {
         //    // Don't issue a duplicate request (we already have or are loading the requested data)
         //    return;
         //}
         const url = "/api/purchaseOrder/pagedByProjectId?pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&projectId=" + projectId;
         dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
         let response = await axios.get(url);
         dispatch({ type: PURCHASE_ORDER_FETCH_PAGED_ByPROJECTID, pagedByProjectId: response.data });
         dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
     },
     //this api used in project module Po Bottom listing to column wise search 
    searchPurchaseOrderByPoId: (searchFields, paged, pageIndex, pageSize, projectId, sortColumn, reverse) => async (dispatch, getState) => {
         if (getState().purchaseOrders.isLoading) {
             return;
         }
         try {
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
             const url = "/api/purchaseOrder/searchIn";
             let response = await axios.post(url, { searchFields, paged, pageIndex, pageSize, projectId, sortColumn, reverse });
             if (paged === true) {
                 dispatch({ type: PURCHASE_ORDER_FETCH_PAGED_PROJECTID, paged: response.data });
             }
             else {
                 dispatch({ type: PURCHASE_ORDER_FETCH, purchaseOrders: response.data });
             }
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
         }
         catch (e) {
             dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
             throw e;
         }

     },
     fechCounts: (purchaseOrderId) => async (dispatch, getState) => {
         if (getState().purchaseOrders.isLoading) {
             return;
         }
         dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: true });
         const url = "/api/purchaseOrder/dataCounts";
         let response = await axios.post(url, { purchaseOrderId });
         dispatch({ type: PURCHASE_ORDER_COUNT_FETCHED, counts: response.data });
         dispatch({ type: PURCHASE_ORDER_IS_LOADING, isLoading: false });
     },
    
};
const initialState = {
    isLoading: false,
    purchaseOrderList: [],   
    lastSaved: null,
    selectedPo: null,   
    paged: {},
    pagedData : {},
    pagedByProjectId: {},
    uoms: [],
    poItemLastSaved: null,
    dataCounts: {}
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case PURCHASE_ORDER_IS_LOADING:
            return { ...state, isLoading: action.isLoading };        
        case PURCHASE_ORDER_SAVED:
            return { ...state, lastSaved: action.lastSaved };       
        case PURCHASE_ORDER_FETCH:
            return { ...state, purchaseOrderList: action.purchaseOrders };
        case PURCHASE_ORDER_SINGLE_FETCH:
            return { ...state, selectedPo: action.selected }
        case PURCHASE_ORDER_FETCH_PAGED:
            return { ...state, paged: action.paged };  
            //seach by project id
        case PURCHASE_ORDER_FETCH_PAGED_PROJECTID:
            return { ...state, pagedByProjectId: action.paged }
            //get all po againest Project id
        case PURCHASE_ORDER_FETCH_PAGED_ByPROJECTID:
            return { ...state, pagedByProjectId: action.pagedByProjectId }
        case PURCHASE_ORDER_COUNT_FETCHED:
            return { ...state, dataCounts: action.counts }
        case PURCHASE_ORDER_FETCH_PAGED_MODAL:
            return { ...state, pagedData: action.pagedData}
        default: return state;
    }
}