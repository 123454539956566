import axios from "axios";
const SHIPMENT_RECEIVING_ALERT_LIST_BY_SHIPMENTID_FETCH = "SHIPMENT_RECEIVING_ALERT_LIST_BY_SHIPMENTID_FETCH";
const SHIPMENT_RECEIVING_ALERT_IS_LOADING = "SHIPMENT_RECEIVING_ALERT_IS_LOADING";
// eslint-disable-next-line
const SHIPMENT_RECEIVING_ALERT_SEARCH = "SHIPMENT_RECEIVING_ALERT_SEARCH";
export const actionCreators = {
    getRAListByShipmentId: (shipmentId) => async (dispatch, getState) => {
        if (getState().shipmentReceivingAlerts.isLoading) {
            return;
        }
        const url = "/api/shipmentRaItems/receivingAlertList?shipmentId=" + shipmentId;
        dispatch({ type: SHIPMENT_RECEIVING_ALERT_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: SHIPMENT_RECEIVING_ALERT_LIST_BY_SHIPMENTID_FETCH, receivingAlertList: response.data });
        dispatch({ type: SHIPMENT_RECEIVING_ALERT_IS_LOADING, isLoading: false });
    },
    searchInRAList: (searchFields, shipmentId, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().shipmentReceivingAlerts.isLoading) {
            return;
        }
        try {
            dispatch({ type: SHIPMENT_RECEIVING_ALERT_IS_LOADING, isLoading: true });
            const url = "/api/shipmentRaItems/search";
            let response = await axios.post(url, { searchFields, shipmentId, sortColumn, reverse});
            dispatch({ type: SHIPMENT_RECEIVING_ALERT_LIST_BY_SHIPMENTID_FETCH, receivingAlertList: response.data });
            dispatch({ type: SHIPMENT_RECEIVING_ALERT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: SHIPMENT_RECEIVING_ALERT_IS_LOADING, isLoading: false });
            throw e;
        }

    },
}
const initialState = {
    isLoading: false,
    poItemShipmentSaved: null,
    existingRAList: [],
    receivingAlertList: [],
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {        
        case SHIPMENT_RECEIVING_ALERT_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case SHIPMENT_RECEIVING_ALERT_LIST_BY_SHIPMENTID_FETCH:
            return { ...state, receivingAlertList: action.receivingAlertList }
        //case SHIPMENT_LIST_SEARCH:
        //    return { ...state, poShipmentList: action.poShipmentList }
        default: return state;
    }
}