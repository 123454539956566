import React from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import AxisLazyLoad from './components/common/AxisLazyLoad';
import Layout from './components/Layout';
import Home from './components/Home';
import Login from "./components/Login";
import UserDetail from "./components/User/UserEdit";
const  UserList=AxisLazyLoad({loader: () => import('./components/User/UserList'),});
const  UserCreate=AxisLazyLoad({loader: () => import('./components/User/UserCreate'),});
const  ClientCreate=AxisLazyLoad({loader: () => import('./components/Client/ClientCreate'),});
const  ClientList=AxisLazyLoad({loader: () => import('./components/Client/ClientList'),});
const  ClientEdit=AxisLazyLoad({loader: () => import('./components/Client/ClientEdit'),});
const  ProposalCreate=AxisLazyLoad({loader: () => import('./components/Proposal/ProposalCreate'),});
const  ProposalList=AxisLazyLoad({loader: () => import('./components/Proposal/ProposalList'),});
const  ProposalEdit=AxisLazyLoad({loader: () => import('./components/Proposal/ProposalEdit'),});
const  NotificationList=AxisLazyLoad({loader: () => import('./components/Notification/NotificationList'),});
const  ProjectCreate=AxisLazyLoad({loader: () => import('./components/Project/ProjectCreate'),});
const  ProjectList=AxisLazyLoad({loader: () => import('./components/Project/ProjectList'),});
const  POList=AxisLazyLoad({loader: () => import('./components/PurchaseOrder/PurchaseOrderList'),});
const  POCreate=AxisLazyLoad({loader: () => import('./components/PurchaseOrder/POCreate'),});
const  ShipmentForm=AxisLazyLoad({loader: () => import('./components/Shipment/ShipmentForm'),});
const  ShipmentList=AxisLazyLoad({loader: () => import('./components/Shipment/ShipmentList'),});
const  ReceivingAlertList=AxisLazyLoad({loader: () => import('./components/ReceivingAlerts/ReceivingAlertList'),});
const  ReceivingAlertsForm=AxisLazyLoad({loader: () => import('./components/ReceivingAlerts/ReceivingAlertsForm'),});
const  InvoiceList=AxisLazyLoad({loader: () => import('./components/Invoices/InvoicesList'),});
const  InvoiceForm=AxisLazyLoad({loader: () => import('./components/Invoices/InvoiceForm'),});
const  InventoryList=AxisLazyLoad({loader: () => import('./components/Inventory/InventoryList'),});
const  InventoryForm=AxisLazyLoad({loader: () => import('./components/Inventory/InventoryForm'),});
const  PickTicketList=AxisLazyLoad({loader: () => import('./components/PickTickets/PickTicketList'),});
const  PickTicketForm=AxisLazyLoad({loader: () => import('./components/PickTickets/PickTicketForm'),});
const  ResetPassword =AxisLazyLoad({loader: () => import('./components/Account/ResetPassword'),});
const  ForgotPassword =AxisLazyLoad({loader: () => import('./components/Account/ForgotPassword'),});
const  ReportList =AxisLazyLoad({loader: () => import('./components/Reports/ReportList'),});
function App(props) {
    return <Switch>
        <Route path='/login' component={Login} />
        <Route path='/account/forgot-password' component={ForgotPassword} />
        <Route path='/account/reset-password' component={ResetPassword} />
        <Layout>
            <div className="modal-backdrop fade in"
                 style={{display: props.backDropVisible === true ? "block" : "none"}}/>
            <Switch>
                <PrivateRoute exact path='/' component={Home}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/users' component={UserList}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"Admin"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/users/create' component={UserCreate}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"Admin"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/users/:id' component={UserDetail}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"AddressBook:View"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/address-books/create' component={ClientCreate}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"AddressBook:Create"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/address-books/:id' component={ClientEdit}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"AddressBook:View"}
                              claims={props.user.scope}
                />

                <PrivateRoute exact path='/address-books' component={ClientList}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"AddressBook:View"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/proposals' component={ProposalList}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"Proposal:View"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/proposals/create' component={ProposalCreate}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"Proposal:Create"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/proposals/:id' component={ProposalEdit}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"Proposal:View"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/projects/create' component={ProjectCreate}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"Project:Create"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/projects/:id' component={ProjectCreate}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"Project:View"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/projects' component={ProjectList}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"Project:View"}
                              claims={props.user.scope}
                />

                <PrivateRoute exact path='/purchase-orders' component={POList}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"PO:View"}
                              claims={props.user.scope}
                />

                <PrivateRoute exact path='/purchase-orders/create' component={POCreate}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"PO:Create"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/purchase-orders/:id' component={POCreate}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"PO:View"}
                              claims={props.user.scope}
                />

                <PrivateRoute exact path='/shipments/Create' component={ShipmentForm}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"Shipment:Create"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/shipments' component={ShipmentList}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"Shipment:View"}
                              claims={props.user.scope}
                />

                <PrivateRoute exact path='/shipments/:id' component={ShipmentForm}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"Shipment:View"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/receiving-alerts' component={ReceivingAlertList}
                    isAuthenticated={props.userAuthentication.isAuthenticated}
                    permission={"RA:View"}
                    claims={props.user.scope}
                />
                <PrivateRoute exact path='/receiving-alerts/Create' component={ReceivingAlertsForm}
                    isAuthenticated={props.userAuthentication.isAuthenticated}
                    permission={"RA:Create"}
                    claims={props.user.scope}
                />
                <PrivateRoute exact path='/receiving-alerts/:id' component={ReceivingAlertsForm}
                    isAuthenticated={props.userAuthentication.isAuthenticated}
                    permission={"RA:View"}
                    claims={props.user.scope}
                />
               
                <PrivateRoute exact path='/notifications' component={NotificationList}
                              isAuthenticated={props.userAuthentication.isAuthenticated}
                              permission={"PO:View"}
                              claims={props.user.scope}
                />
                <PrivateRoute exact path='/invoices' component={InvoiceList}
                    isAuthenticated={props.userAuthentication.isAuthenticated}
                    permission={"Invoice:View"}
                    claims={props.user.scope}
                />
                <PrivateRoute exact path='/invoices/create' component={InvoiceForm}
                    isAuthenticated={props.userAuthentication.isAuthenticated}
                    permission={"Invoice:Create"}
                    claims={props.user.scope}
                />
                <PrivateRoute exact path='/invoices/:id' component={InvoiceForm}
                    isAuthenticated={props.userAuthentication.isAuthenticated}
                    permission={"Invoice:View"}
                    claims={props.user.scope}
                />
                <PrivateRoute exact path='/inventory' component={InventoryList}
                    isAuthenticated={props.userAuthentication.isAuthenticated}
                    permission={"Inventory:View"}
                    claims={props.user.scope}
                />
                <PrivateRoute exact path='/inventory/:id' component={InventoryForm}
                    isAuthenticated={props.userAuthentication.isAuthenticated}
                    permission={"Inventory:View"}
                    claims={props.user.scope}
                />
                <PrivateRoute exact path='/pick-tickets' component={PickTicketList}
                    isAuthenticated={props.userAuthentication.isAuthenticated}
                    permission={"PickTicket:View"}
                    claims={props.user.scope}
                />
                <PrivateRoute exact path='/pick-tickets/create' component={PickTicketForm}
                    isAuthenticated={props.userAuthentication.isAuthenticated}
                    permission={"PickTicket:Create"}
                    claims={props.user.scope}
                />
                <PrivateRoute exact path='/pick-tickets/:id' component={PickTicketForm}
                    isAuthenticated={props.userAuthentication.isAuthenticated}
                    permission={"PickTicket:View"}
                    claims={props.user.scope}
                />
                <PrivateRoute exact path='/reports' component={ReportList}
                    isAuthenticated={props.userAuthentication.isAuthenticated}
                    permission={"Reports:View"}
                    claims={props.user.scope}
                />
                <PrivateRoute path='*'/>
            </Switch>
        </Layout>
    </Switch>
}

const PrivateRoute = ({component: Component, ...rest}) => {
    return <Route {...rest} render={(props) => (
        hasPermission(rest.isAuthenticated, rest.claims, rest.permission)
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/login',
                state: {from: props.location}
            }}/>
    )}/>;
};
const hasPermission = (isAuthenticated, claims, permission) => {
    if (isAuthenticated && !permission) {
        return true;
    }
    if (claims === "Admin") {
        return isAuthenticated === true;
    }
    if (claims && permission) {
        return isAuthenticated === true && claims.indexOf(permission) !== -1
    }
    return false;
};

let mapStateToProps = function (state) {
    return {
        userAuthentication: state.userAuthentication,
        user: state.userAuthentication.user,
        backDropVisible: state.modal.backDropVisible
    }
};
export default withRouter(connect(mapStateToProps, null)(App));