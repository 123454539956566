import axios from "axios";
const INVOICE_IS_LOADING = "INVOICE_IS_LOADING";
const INVOICE_STATUS_LIST = "INVOICE_STATUS_LIST";
const INVOICE_SAVED = "INVOICE_SAVED";
const INVOICE_SINGLE_FETCH = "INVOICE_SINGLE_FETCH"
const INVOICE_CHARGE_COUNT = "INVOICE_CHARGE_COUNT"
const SHIPMENT_INVOICE_LIST = "SHIPMENT_INVOICE_LIST"
const SHIPMENT_INVOICE_LIST_EXPORT = "SHIPMENT_INVOICE_LIST_EXPORT"
const INVOICE_LIST_PAGINATED = "INVOICE_LIST_PAGINATED"
const INVOICE_LIST_FILTERED_COLUMNS = "INVOICE_LIST_FILTERED_COLUMNS"
const VENDORS_BILLS_LIST = "VENDORS_BILLS_LIST";
const VENDOR_BILLS_PAGED = "VENDOR_BILLS_PAGED";
const INVOICE_EXPORTED_PAGED = "INVOICE_EXPORTED_PAGED";
export const actionCreators = {
    fetchInvoiceStatus: () => async (dispatch, getState) => {
        if (getState().invoices.isLoading) {
            return;
        }
        dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
        const url = "/api/invoices/GetInvoiceStatuses";
        let response = await axios.get(url);
        dispatch({ type: INVOICE_STATUS_LIST, invoiceStatusList: response.data });
        dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
    },
    fetchInvoices: (id,module, pageNumber, pageSize) => async (dispatch, getState) => {
        if (getState().invoices.isLoading) {
            return;
        }
        dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
        const url = "/api/invoices/GetInvoices?Id=" + id + "&containerModule=" + module + "&pageIndex=" + pageNumber + "&pageSize=" + pageSize ;
        let response = await axios.get(url);
        dispatch({ type: SHIPMENT_INVOICE_LIST, shipmentInvoices: response.data });
        dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
    },
    fetchInvoicesforExport: (id, module) => async (dispatch, getState) => {
        if (getState().invoices.isLoading) {
            return;
        }
        dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
        const url = "/api/invoices/GetInvoices?Id=" + id + "&containerModule=" + module;
        let response = await axios.get(url);
        dispatch({ type: SHIPMENT_INVOICE_LIST_EXPORT, invoiceExport: response.data });
        dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
    },
    updateInvoice: state => async (dispatch, getState) => {
        try {
            if (getState().invoices.isLoading) {
                return;
            }
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "api/invoices";
            await axios.put(url, state);
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    updatechargesToInvoice: state => async (dispatch, getState) => {
        try {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "api/invoices/UpdateChargeToInvoice";
            await axios.put(url, state);
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    removechargesFromInvoice: state => async (dispatch, getState) => {
        try {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "api/invoices/RemoveChargesFromInvoice";
            await axios.put(url, state);
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    deleteInvoice: id => async (dispatch, getState) => {
        try {
            if (getState().projects.isLoading) {
                return;
            }
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "api/invoices/" + id;
            await axios.delete(url);
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    createInvoice: state => async (dispatch, getState) => {
        if (getState().invoices.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "/api/invoices";
            let response = await axios.post(url, state);
            dispatch({ type: INVOICE_SAVED, lastSaved: response.data });
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
    },
    getSingle: id => async (dispatch, getState) => {
        if (getState().invoices.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "/api/invoices/" + id;
            let response = await axios.get(url);
            dispatch({ type: INVOICE_SINGLE_FETCH, invoiceDetail: response.data });
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
    },
    getChargesCount: (id,isMisc) => async (dispatch, getState) => {
        if (getState().invoices.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "/api/invoices/GetInvoiceChargeCount?id=" + id + "&isMisc="+ isMisc;
            let response = await axios.get(url);           
            dispatch({ type: INVOICE_CHARGE_COUNT, chargesCount: response.data });
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
    }
   ,
    searchInvoiceList: (searchFields, id, sortColumn, reverse, containerModule, pageIndex, pageSize) => async (dispatch, getState) => {
        if (getState().invoices.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "/api/invoices/searchInvoices";
            let response = await axios.post(url, { searchFields, id, sortColumn, reverse, containerModule, pageIndex, pageSize });
            dispatch({ type: SHIPMENT_INVOICE_LIST, shipmentInvoices: response.data });
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    searchInvoiceListExport: (searchFields, id, sortColumn, reverse, containerModule, pageIndex, pageSize) => async (dispatch, getState) => {
        //if (getState().invoices.isLoading) {
        //    return;
        //}
        try {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "/api/invoices/searchInvoices";
            let response = await axios.post(url, { searchFields, id, sortColumn, reverse, containerModule, pageIndex, pageSize});
            dispatch({ type: SHIPMENT_INVOICE_LIST_EXPORT, invoiceExport: response.data });
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    searchvendorBills: (searchFields, id, sortColumn, reverse) => async (dispatch, getState) => {
        //if (getState().invoices.isLoading) {
        //    return;
        //}
        try {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "/api/qbSync/searchVendorBills";
            let response = await axios.post(url, { searchFields, id, sortColumn, reverse });
            dispatch({ type: VENDORS_BILLS_LIST, vendorBills: response.data });
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    updateExportInvoiceStatus: (data) => async (dispatch, getState) => {
        //if (getState().invoices.isLoading) {
        //    return;
        //}
        try {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "/api/invoices/updateExportStatus";
            await axios.post(url,  data );
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    updateBillQueueStatus: (data) => async (dispatch, getState) => {
        //if (getState().invoices.isLoading) {
        //    return;
        //}
        try {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "/api/qbSync/updateVendorStatus";
            await axios.post(url, data);
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    getInvoicespaginated: (pageIndex, pageSize) => async (dispatch, getState) => {
        if (getState().invoices.isLoading) {
            return;
        }
        dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
        const url = "/api/invoices/getPaginatedInvoices?pageIndex=" + pageIndex + "&pageSize=" + pageSize;
        let response = await axios.get(url);
        dispatch({ type: INVOICE_LIST_PAGINATED, invoicePaginated : response.data });
        dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
    },
    searchInvoicePaginated: (searchFields, sortColumn, reverse, pageIndex, pageSize) => async (dispatch, getState) => {
        if (getState().invoices.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "/api/invoices/searchPaginatedInvoices";
            await axios.post(url, { searchFields, sortColumn, reverse, pageIndex, pageSize })
                .then(function (response) {
                    //success handled here
                    dispatch({ type: INVOICE_LIST_PAGINATED, invoicePaginated: response.data });
                    dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
                })
                .catch(function (error) {
                    //if error
                    console.clear();
                    console.log(error);
                    dispatch({ type: INVOICE_LIST_PAGINATED, invoicePaginated: {} });
                    dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
                })
                .then(function () {
                    // always executed
                    dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
                });
            
           
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    getfilteredColumns : () => async (dispatch, getState) => {
        if (getState().invoices.isLoading) {
            return;
        }
        dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
        const url = "/api/invoices/getFilterColumns";
        let response = await axios.get(url);
        dispatch({ type: INVOICE_LIST_FILTERED_COLUMNS, filtered: response.data });
        dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
    },
     getAllBills: () => async (dispatch, getState) => {
        if (getState().invoices.isLoading) {
            return;
        }
        dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
        const url = "/api/qbSync/getVendorBills";
        let response = await axios.get(url);
        dispatch({ type: VENDORS_BILLS_LIST, vendorBills: response.data });
        dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
    },
     getPagedVendorBills: (includeExported,pageIndex, pageSize) => async (dispatch, getState) => {
         if (getState().invoices.isLoading) {
             return;
         }
         const url = "/api/qbSync/pagedVendorBills";
        dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
        await axios.post(url, { includeExported,pageIndex, pageSize})
             .then(function (response) {
                 //success handled here
                 dispatch({ type: VENDOR_BILLS_PAGED, pagedVedorBills: response.data });
             })
             .catch(function (error) {
                 //if error
                 console.clear();
                 console.log(error);
                 dispatch({ type: VENDOR_BILLS_PAGED, pagedVedorBills: {} });
                 dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
             })
             .then(function () {
                 // always executed
                 dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
             });
     },
     searchInPaged: (includeExported,searchFields, pageIndex, pageSize, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().invoices.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
            const url = "/api/qbSync/searchPaged";
            await axios.post(url, { includeExported,searchFields, pageIndex, pageSize, sortColumn, reverse })
                .then(function (response) {
                    //success handled here
                    dispatch({ type: VENDOR_BILLS_PAGED,pagedVedorBills: response.data });
                })
                .catch(function (error) {
                    //if error
                    console.clear();
                    console.log(error);
                    dispatch({ type: VENDOR_BILLS_PAGED, pagedVedorBills: {}});
                    dispatch({ type: VENDOR_BILLS_PAGED, isLoading: false });
                })
                .then(function () {
                    // always executed
                    dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
                }); 
        }
        catch (e) {
            dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
            throw e;
        }
     },
     getPagedInvoiceExport: (includeExported, pageIndex, pageSize, containerModule) => async (dispatch, getState) => {
         if (getState().invoices.isLoading) {
             return;
         }
         const url = "/api/invoices/pagedInvoices";
         dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
         await axios.post(url, { includeExported, pageIndex, pageSize, containerModule })
             .then(function (response) {
                 //success handled here
                 dispatch({ type: INVOICE_EXPORTED_PAGED, invoiceExportList: response.data });
             })
             .catch(function (error) {
                 //if error
                 console.clear();
                 console.log(error);
                 dispatch({ type: INVOICE_EXPORTED_PAGED, invoiceExportList: {} });
                 dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
             })
             .then(function () {
                 // always executed
                 dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
             });
     },
     searchInvoiceExport: (includeExported, searchFields, pageIndex, pageSize, sortColumn, reverse, containerModule) => async (dispatch, getState) => {
         if (getState().invoices.isLoading) {
             return;
         }
         try {
             dispatch({ type: INVOICE_IS_LOADING, isLoading: true });
             const url = "/api/invoices/searchPagedInvoices";
             await axios.post(url, { includeExported, searchFields, pageIndex, pageSize, sortColumn, reverse, containerModule })
                 .then(function (response) {
                     //success handled here
                     dispatch({ type: INVOICE_EXPORTED_PAGED, invoiceExportList: response.data });
                 })
                 .catch(function (error) {
                     //if error
                     console.clear();
                     console.log(error);
                     dispatch({ type: INVOICE_EXPORTED_PAGED, invoiceExportList: {} });
                     dispatch({ type: INVOICE_EXPORTED_PAGED, isLoading: false });
                 })
                 .then(function () {
                     // always executed
                     dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
                 });
         }
         catch (e) {
             dispatch({ type: INVOICE_IS_LOADING, isLoading: false });
             throw e;
         }
     },
};

const initialState = {
    isLoading: false,
    invoiceStatusList: [],
    lastSaved: null,
    invoiceDetail: [],
    chargesCount: 0,
    shipmentInvoices: [],
    invoiceExport: [],
    invoicePaginated: [],
    filteredColumn: [],
    vendorBills: [],
    pagedVedorBills: {},
    invoiceExportList: {},
};
export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case INVOICE_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case INVOICE_STATUS_LIST:
            return { ...state, invoiceStatusList: action.invoiceStatusList };
        case INVOICE_SAVED:
            return { ...state, lastSaved: action.lastSaved };
        case INVOICE_SINGLE_FETCH:
            return { ...state, invoiceDetail: action.invoiceDetail };
        case INVOICE_CHARGE_COUNT:
            return { ...state, chargesCount: action.chargesCount };
        case SHIPMENT_INVOICE_LIST:
            return { ...state, shipmentInvoices: action.shipmentInvoices };
        case SHIPMENT_INVOICE_LIST_EXPORT:
            return { ...state, invoiceExport: action.invoiceExport };
        case INVOICE_LIST_PAGINATED:
            return { ...state, invoicePaginated: action.invoicePaginated };
        case   INVOICE_LIST_FILTERED_COLUMNS:
            return { ...state, filteredColumn: action.filtered };
        case VENDORS_BILLS_LIST:
            return { ...state, vendorBills: action.vendorBills };
        case VENDOR_BILLS_PAGED:
            return { ...state, pagedVedorBills: action.pagedVedorBills };
        case INVOICE_EXPORTED_PAGED:
            return { ...state, invoiceExportList: action.invoiceExportList };
        default:
            return state;
    }
};
