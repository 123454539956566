import axios from "axios";

const SHIPMENT_METHOD_FETCHED = "SHIPMENT_METHOD_FETCHED";
const SHIPMENT_METHOD_LOADING = "SHIPMENT_METHOD_LOADING";

export const actionCreators = {
    fetchShipmentMethods: () => async (dispatch, getState) => {
        if(getState().shipmentMethods.isLoading){
            return;
        }
        try {
            const url = "/api/shipmentMethod";
            dispatch({type: SHIPMENT_METHOD_LOADING, isLoading: true});
            const response = await axios.get(url);
            dispatch({type: SHIPMENT_METHOD_LOADING, isLoading: false});
            dispatch({type: SHIPMENT_METHOD_FETCHED, shipmentMethods: response.data});
        } catch (e) {
            dispatch({type: SHIPMENT_METHOD_LOADING, isLoading: false});
        }

    }
};

const initialState = {
    isLoading:false,
    list:[]
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case SHIPMENT_METHOD_LOADING:
            return {...state,isLoading:action.isLoading};
        case SHIPMENT_METHOD_FETCHED:
            return {...state,list:action.shipmentMethods};
        default:
            return state;
    }
};