import axios from "axios";
// eslint-disable-next-line
const INVENTORY_FETCH = "INVENTORY_FETCH";
const INVENTORY_FETCH_PAGED = "INVENTORY_FETCH_PAGED";
const INVENTORY_IS_LOADING = "INVENTORY_IS_LOADING";
// eslint-disable-next-line
const INVENTORY_COUNT = "INVENTORY_COUNT";
const INVENTORY_ALERTS_FILTER_COLUMNS = "INVENTORY_ALERTS_FILTER_COLUMNS";
const INVENTORY_SINGLE_FETCH = "INVENTORY_SINGLE_FETCH";
const INVENTORY_COUNT_FETCHED = "INVENTORY_COUNT_FETCHED";
export const actionCreators = {
    fetchPaged: (pageIndex, pageSize) => async (dispatch, getState) => {
        if (getState().inventories.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        const url = "/api/inventory/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize;
        dispatch({ type: INVENTORY_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: INVENTORY_FETCH_PAGED, paged: response.data });
        dispatch({ type: INVENTORY_IS_LOADING, isLoading: false });
    },
    getFilteredColumns: () => async (dispatch, getState) => {
        if (getState().inventories.isLoading) {
            return;
        }
        dispatch({ type: INVENTORY_IS_LOADING, isLoading: true });
        const url = "/api/inventory/filtered";
        let response = await axios.get(url);
        dispatch({ type: INVENTORY_ALERTS_FILTER_COLUMNS, filter: response.data });
        dispatch({ type: INVENTORY_IS_LOADING, isLoading: false });
    },
    searchInventries: (searchFields, paged, pageIndex, pageSize, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().inventories.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVENTORY_IS_LOADING, isLoading: true });
            const url = "/api/inventory/search";
            let response = await axios.post(url, { searchFields, paged, pageIndex, pageSize, sortColumn, reverse });
            if (paged === true) {
                dispatch({ type: INVENTORY_FETCH_PAGED, paged: response.data });
            }
            //else {
            //    dispatch({ type: RECEIVING_ALERTS_FETCH_DATA, data: response.data });
            //}
            dispatch({ type: INVENTORY_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVENTORY_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getSingleInventory: id => async (dispatch, getState) => {
        if (getState().inventories.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVENTORY_IS_LOADING, isLoading: true });
            const url = "/api/inventory/" + id;
            let response = await axios.get(url);
            dispatch({ type: INVENTORY_SINGLE_FETCH, selected: response.data });
            dispatch({ type: INVENTORY_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVENTORY_IS_LOADING, isLoading: false });
        }
    },
    fechCounts: (inventoryId) => async (dispatch, getState) => {
        if (getState().inventories.isLoading) {
            return;
        }
        dispatch({ type: INVENTORY_IS_LOADING, isLoading: true });
        const url = "/api/inventory/dataCounts";
        let response = await axios.post(url, { inventoryId });
        dispatch({ type: INVENTORY_COUNT_FETCHED, counts: response.data });
        dispatch({ type: INVENTORY_IS_LOADING, isLoading: false });
    },
};

const initialState = {
    isLoading: false,
    filtered: [],
    paged: {},
    selected: null,   
    dataCounts: {}
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case INVENTORY_IS_LOADING:
            return { ...state, isLoading: action.isLoading };        
        case INVENTORY_FETCH_PAGED:
            return { ...state, paged: action.paged };      
        case INVENTORY_ALERTS_FILTER_COLUMNS:
            return { ...state, filtered: action.filter }
        case INVENTORY_SINGLE_FETCH:
            return { ...state, selected: action.selected };
        case INVENTORY_COUNT_FETCHED:
            return { ...state, dataCounts: action.counts }
        default:
            return state;
    }
};