import axios from "axios";
const CHARGE_CATEGORIES_FETCHED = "CHARGE_CATEGORIES_FETCHED";
const CHARGE_CATEGORIES_IS_LOADING = "CHARGE_CATEGORIES_IS_LOADING";

export const actionCreators = {
    fetchChargeTypes: (isClient) => async (dispatch, getState) => {
        if (getState().chargeTypes.isLoading) {
            return;
        }
        const url = "/api/chargeCategory";
        let config = null;
        dispatch({type: CHARGE_CATEGORIES_FETCHED, isLoading: true});
        if (isClient) {
            config = {
                params: {isClient: true}
            }
        }
        let response = await axios.get(url,config);
        dispatch({type: CHARGE_CATEGORIES_FETCHED, chargeTypes: response.data});
        dispatch({type: CHARGE_CATEGORIES_IS_LOADING, isLoading: false});
    }
};

const initialState = {
    isLoading: false,
    list: []
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case CHARGE_CATEGORIES_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        case CHARGE_CATEGORIES_FETCHED:
            return {
                ...state,
                list: action.chargeTypes
            };
        default:
            return state;
    }
};