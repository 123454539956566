import axios from "axios";
const PURCHASE_ORDER_ITEM_IS_LOADING = "PURCHASE_ORDER_ITEM_IS_LOADING";
const PURCHASE_ORDER_ITEM_FETCH = "PURCHASE_ORDER_ITEM_FETCH";
const PURCHASE_ORDER_UOM_FETCH ="PURCHASE_ORDER_UOM_FETCH"
const PURCHASE_ORDER_ITEM_SAVED = "PURCHASE_ORDER_ITEM_SAVED";
const PURCHASE_ORDER_ITEM_SINGLE_FETCH = "PURCHASE_ORDER_ITEM_SINGLE_FETCH";
//const PURCHASE_ORDER_UOM_ISLOADING = "PURCHASE_ORDER_UOM_ISLOADING";
const PURCHASE_ORDER_ITEM_COL_FETCHED = "PURCHASE_ORDER_ITEM_COL_FETCHED";
const PURCHASE_ORDER_ITEM_FETCHED_PAGED = "PURCHASE_ORDER_ITEM_FETCHED_PAGED";
const PURCHASE_ORDER_ITEMCAT_FETCH = "PURCHASE_ORDER_ITEMCAT_FETCH";
export const actionCreators = {
    fetchPurchaseOrderItems: (poId) => async (dispatch, getState) => {
        if (getState().purchaseOrderItems.isLoading) {
            return;
        }
        dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: true });      
        const url = "/api/poItemsByPoId/" + poId;        
        let response = await axios.get(url);
        dispatch({ type: PURCHASE_ORDER_ITEM_FETCH, purchaseOrderItems: response.data });
        dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
    },
    //po item api start here
    fetchUoms: () => async (dispatch, getState) => {
        if (getState().purchaseOrderItems.isLoading) {
            return;
        }        
        dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: true });
        const url = "/api/poItems/uom";
        let response = await axios.get(url);
        dispatch({ type: PURCHASE_ORDER_UOM_FETCH, uoms: response.data });
        dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });

    },
    createPoItems: state => async (dispatch, getState) => {
        if (getState().purchaseOrderItems.isLoading) {
            //debugger;
            return;
        }
        try {
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: true });          
            const url = "/api/poItems";     
            let response = await axios.post(url, state);
            dispatch({ type: PURCHASE_ORDER_ITEM_SAVED, poItemLastSaved: response.data });
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
        }
    },
    getSinglePOItem: (id,purchaseOrderId) => async (dispatch, getState) => {
        if (getState().purchaseOrderItems.isLoading) {
            return;
        }
        try {
            //console.log('single po callse'+id)
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/poItems/getSingle?id=" + id + "&PurchaseOrderId=" + purchaseOrderId;
            let response = await axios.get(url);
            dispatch({ type: PURCHASE_ORDER_ITEM_SINGLE_FETCH, selected: response.data });
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
        }
    },
    updatePoItem: state => async (dispatch, getState) => {
        try {
            if (getState().purchaseOrderItems.isLoading) {
                return;
            }
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/poItems";
            await axios.put(url, state);
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    deletePOItem: poItemId => async (dispatch, getState) => {
        try {
            if (getState().purchaseOrderItems.isLoading) {
                return;
            }
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/poItems/" + poItemId

            await axios.delete(url);
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getFilteredPoItemColums: (purchaseOrderId) => async (dispatch, getState) => {
        if (getState().purchaseOrderItems.isLoading) {
            return;
        
        }
        dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: true });
        const url = "/api/poItemsByPoId/filteredColums?PurcahseOrderId=" + purchaseOrderId;
       // const url = "/api/poItemsByPoId/" + purchaseOrderId;
        let response = await axios.get(url);
        dispatch({ type: PURCHASE_ORDER_ITEM_COL_FETCHED, filter: response.data });
        dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
    },
    getPagedPoItemList: (pageIndex, pageSize,purchaseOrderId) => async (dispatch, getState) => {
        if (getState().purchaseOrderItems.isLoading) {            
            return;
        }    
        dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: true });
        if (purchaseOrderId === 0) {
            dispatch({ type: PURCHASE_ORDER_ITEM_FETCHED_PAGED, paged: {}});          
        }
        else {
            const url = "/api/poItems/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&purchaseOrderId=" + purchaseOrderId;
            let response = await axios.get(url);
            dispatch({ type: PURCHASE_ORDER_ITEM_FETCHED_PAGED, paged: response.data });
        }
        dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
    },
    searchInPoItemList: (searchFields, paged, pageIndex, pageSize, sortColumn, reverse,purchaseOrderId) => async (dispatch, getState) => {
        if (getState().purchaseOrderItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/poItems/search";
            let response = await axios.post(url, { searchFields, paged, pageIndex, pageSize, sortColumn, reverse, purchaseOrderId });
            if (paged === true) {
                dispatch({ type: PURCHASE_ORDER_ITEM_FETCHED_PAGED, paged: response.data });
            }
            else {
                dispatch({ type: PURCHASE_ORDER_ITEM_FETCH, data: response.data });
            }
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    fetchItemCategories: () => async (dispatch, getState) => {
        if (getState().purchaseOrderItems.isLoading) {
            return;
        }
        dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: true });
        const url = "/api/poItems/itemCategories";
        let response = await axios.get(url);
        dispatch({ type: PURCHASE_ORDER_ITEMCAT_FETCH, itemCategories: response.data });
        dispatch({ type: PURCHASE_ORDER_ITEM_IS_LOADING, isLoading: false });
    },
};
const initialState = {
    isLoading: false,
    purchaseOrderItemList: [],
    poItemLastSaved: null,  
    poItemShipmentSaved:null,
    selectedPoItem: null,
    paged: {},
    uoms: [],
    filteredColumns: [],
    itemCategories:[],
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case PURCHASE_ORDER_ITEM_IS_LOADING:
            return { ...state, isLoading: action.isLoading };   
        case PURCHASE_ORDER_ITEM_FETCH:
            return { ...state, purchaseOrderItemList: action.purchaseOrderItems };
        case PURCHASE_ORDER_UOM_FETCH:
            return { ...state, uoms: action.uoms };
        case PURCHASE_ORDER_ITEM_SAVED:
            return { ...state, poItemLastSaved: action.poItemLastSaved };
        case PURCHASE_ORDER_ITEM_SINGLE_FETCH:
            return { ...state, selectedPoItem: action.selected }
        case PURCHASE_ORDER_ITEM_COL_FETCHED:
            return { ...state, filteredColumns: action.filter };
        case PURCHASE_ORDER_ITEM_FETCHED_PAGED:
            return { ...state, paged: action.paged };
        case PURCHASE_ORDER_ITEMCAT_FETCH:
            return { ...state, itemCategories: action.itemCategories}
        default: return state;
    }
}