import axios from "axios";
const PO_ITEM_SHIPMENT_IS_LOADING = "PO_ITEM_SHIPMENT_IS_LOADING";
const PO_ITEM_NEW_SHIPMENT_SAVED = "PO_ITEM_NEW_SHIPMENT_SAVED";
const FETCH_SHIPMENT_BY_PROJECTID = "FETCH_SHIPMENT_BY_PROJECTID";
const SHIPMENT_List_BY_POID_FETCH = "SHIPMENT_List_BY_POID_FETCH";
const SHIPMENT_LIST_SEARCH = "SHIPMENT_LIST_SEARCH";
const SHIPMENT_FILTER_COL_FETCHED = "SHIPMENT_FILTER_COL_FETCHED";
export const actionCreators = {
    addToShipment: state => async (dispatch, getState) => {
        if (getState().purchaseOrderShipments.isLoading) {
            return;
        }
        try {
            dispatch({ type: PO_ITEM_SHIPMENT_IS_LOADING, isLoading: true });
            //internally ie in PurchaseOrderItemController.cs 
            //i call create shipment api and get id then use i
            const url = "/api/poItemsShipment";
            let response = await axios.post(url, state);
            dispatch({ type: PO_ITEM_NEW_SHIPMENT_SAVED, poItemShipmentSaved: response.data });
            dispatch({ type: PO_ITEM_SHIPMENT_IS_LOADING, isLoading: false });

        }
        catch (e) {
            dispatch({ type: PO_ITEM_SHIPMENT_IS_LOADING, isLoading: false });
        }
    },
   
    //this ap is used in purchase order exsing shipment lists
    fetchPagedShipmentItemsByPoId: (pageIndex, pageSize, projectId) => async (dispatch, getState) => {
        if (getState().purchaseOrderShipments.isLoading) {
            return;
        }
        const url = "/api/poItemsShipment/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&projectId=" + projectId;        
        dispatch({ type: PO_ITEM_SHIPMENT_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: FETCH_SHIPMENT_BY_PROJECTID, existingPagedShipments: response.data });
        dispatch({ type: PO_ITEM_SHIPMENT_IS_LOADING, isLoading: false });
    },
    //this api will be used to bind shipments in Purchase order shipment tab
    getShipmentListByPoId: (purchaseOrderId) => async (dispatch, getState) => {
        if (getState().purchaseOrderShipments.isLoading) {
            return;
        }
        const url = "/api/poItemsShipment/shipmentList?purchaseOrderId=" + purchaseOrderId ;
        dispatch({ type: PO_ITEM_SHIPMENT_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: SHIPMENT_List_BY_POID_FETCH, poShipmentList: response.data });
        dispatch({ type: PO_ITEM_SHIPMENT_IS_LOADING, isLoading: false });
    },
    //used to search with in shipment 
    //defined in PurchaseOrderShipment Controller
    searchShipmentList: (searchFields, purchaseOrderId, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().purchaseOrderShipments.isLoading) {
            return;
        }
        try {
            dispatch({ type: PO_ITEM_SHIPMENT_IS_LOADING, isLoading: true });
            const url = "/api/poItemsShipment/search";
            let response = await axios.post(url, { searchFields, purchaseOrderId, sortColumn, reverse});
            dispatch({ type: SHIPMENT_LIST_SEARCH, poShipmentList: response.data });
            dispatch({ type: PO_ITEM_SHIPMENT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PO_ITEM_SHIPMENT_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    getFilteredColums: (purchaseOrderId) => async (dispatch, getState) => {
        if (getState().purchaseOrderShipments.isLoading) {
            return;

        }
        dispatch({ type: PO_ITEM_SHIPMENT_IS_LOADING, isLoading: true });
        const url = "/api/poItemsShipment/filteredColums?PurchaseOrderId=" + purchaseOrderId;
        let response = await axios.get(url);
        dispatch({ type: SHIPMENT_FILTER_COL_FETCHED, filter: response.data });
        dispatch({ type: PO_ITEM_SHIPMENT_IS_LOADING, isLoading: false });
    },
};
const initialState = {
    isLoading: false,   
    poItemShipmentSaved: null,  
    existingShipmentList: [],
    poShipmentList: [],   
    filteredColumns: [],
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {  
        case PO_ITEM_SHIPMENT_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case PO_ITEM_NEW_SHIPMENT_SAVED:
            return { ...state, poItemShipmentSaved: action.poItemShipmentSaved }
        case FETCH_SHIPMENT_BY_PROJECTID:
            return { ...state, existingShipmentList: action.existingPagedShipments }
        case SHIPMENT_List_BY_POID_FETCH:
            return { ...state, poShipmentList: action.poShipmentList }
        case SHIPMENT_LIST_SEARCH:
            return { ...state, poShipmentList: action.poShipmentList }
        case SHIPMENT_FILTER_COL_FETCHED:
            return { ...state, filteredColumns: action.filter }
        default: return state;
    }
}