import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TextFieldGroup = ({ field, value, label, error, type, onChange, onBlur, isRequired, readOnly, inputSize, labelWidth}) => {
    return (
        <div className={classnames('form-group', { 'has-error': isRequired && error})}>
            {
                //<label className="control-label col-md-2 text-left p-r-0 ">

            }
            <label className={"control-label text-left p-r-0  col-md-" + (labelWidth ? labelWidth : '2')} >
            {label + " "}{isRequired && <span className="text-danger">*</span>}</label>
            <div className={"col-md-" + (inputSize ? inputSize : '9')}>
                <input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    type={type}
                    name={field}
                    className="form-control"
                    readOnly={readOnly}
                />
                {isRequired && error && <span className="help-block">{error}</span>}
            </div>
        </div>);
};

TextFieldGroup.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
    inputSize: PropTypes.string,
    labelWidth: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
};

TextFieldGroup.defaultProps = {
    type: 'text'
};

export default TextFieldGroup;