import axios from "axios";
const PROJECT_FETCH = "PROJECT_FETCH";
const PROJECT_FETCH_PAGED = "PROJECT_FETCH_PAGED";
const PROJECT_SINGLE_FETCH = "PROJECT_SINGLE_FETCH";
const PROJECT_SAVED = "PROJECT_SAVED";
const PROJECT_IS_LOADING = "PROJECT_IS_LOADING";
const CLIENT_PFETCH_TYPE = "CLIENT_PFETCH_TYPE";
const PROJECT_COUNT_FETCHED = "PROJECT_COUNT_FETCHED";
const PURCHASE_ORDER_List_BY_PROJECT_ID_FETCH = "PURCHASE_ORDER_List_BY_PROJECT_ID_FETCH";
const SHIPMENT_List_BY_PROJECT_ID_FETCH = "SHIPMENT_List_BY_PROJECT_ID_FETCH";
const WAREHOUSE_CHARGES_RESPONSE = "WAREHOUSE_CHARGES_RESPONSE";
const BILLINGPERIODS_LIST = "BILLINGPERIODS_LIST";
const PROJECT_DETAILS = "PROJECT_DETAILS";
const PROJECT_FILTER_COLUMNS = "PROJECT_FILTER_COLUMNS";
const PROJECT_FETCH_PAGED_FILTERED = "PROJECT_FETCH_PAGED_FILTERED";
export const actionCreators = {
    fetchProjects: () => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        dispatch({type: PROJECT_IS_LOADING, isLoading: true});
        const url = "/api/project";
        let response = await axios.get(url);

        dispatch({type: PROJECT_FETCH, projects: response.data});
        dispatch({type: PROJECT_IS_LOADING, isLoading: false});

    },
    fetchByClientId: id => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        dispatch({type: PROJECT_IS_LOADING, isLoading: true});
        const url = "/api/project/client/" + id;
        let response = await axios.get(url);
        dispatch({type: PROJECT_FETCH, projects: response.data});
        dispatch({type: PROJECT_IS_LOADING, isLoading: false});
    },
    searchByClientId: (id, searchTerm) => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        dispatch({type: PROJECT_IS_LOADING, isLoading: true});
        const url = "/api/project/searchByClient";
        let response = await axios.post(url, {clientId: id, searchTerm});
        dispatch({type: PROJECT_FETCH, projects: response.data});
        dispatch({type: PROJECT_IS_LOADING, isLoading: false});
    },
    createProject: state => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        try {
            dispatch({type: PROJECT_IS_LOADING, isLoading: true});
            const url = "/api/project";
            let response = await axios.post(url, state);
            dispatch({type: PROJECT_SAVED, lastSaved: response.data});
            dispatch({type: PROJECT_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: PROJECT_IS_LOADING, isLoading: false});
        }
    },
    getSingle: id => async (dispatch, getState) => {
        //if (getState().projects.isLoading) {
        //    return;
        //}
        try {
            dispatch({type: PROJECT_IS_LOADING, isLoading: true});
            const url = "/api/project/" + id;
            let response = await axios.get(url);
            dispatch({type: PROJECT_SINGLE_FETCH, selected: response.data});
            dispatch({type: PROJECT_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: PROJECT_IS_LOADING, isLoading: false});
        }
    },
    fetchPaged: (pageIndex, pageSize) => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        const url = "/api/project/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize;
        dispatch({type: PROJECT_IS_LOADING, isLoading: true});
        let response = await axios.get(url);
        dispatch({type: PROJECT_FETCH_PAGED, paged: response.data});
        dispatch({type: PROJECT_IS_LOADING, isLoading: false});
    },
    //SINCE project client not mapped propery in getfiltercolums need to manage this way
    fetchPagedFiltered: (pageIndex, pageSize) => async (dispatch, getState) => {
        if (getState().projects.isLoading) {            
            return;
        }
        const url = "/api/project/pagedFiltered?pageIndex=" + 1 + "&pageSize=" + 500;
        dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: PROJECT_FETCH_PAGED_FILTERED, pagedFiltered: response.data });
        dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
    },
    getFilteredColumns: () => async (dispatch, getState) => {
        if (getState().pickTickets.isLoading) {
            return;
        }
        dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
        const url = "/api/Project/filtered";
        let response = await axios.get(url);
        dispatch({ type: PROJECT_FILTER_COLUMNS, filter: response.data });
        dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
    },
    searchProjects: (searchFields, paged, pageIndex, pageSize, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        try {
            dispatch({type: PROJECT_IS_LOADING, isLoading: true});
            const url = "/api/project/search";
            let response = await axios.post(url, {searchFields, paged, pageIndex, pageSize, sortColumn, reverse});
            if (paged === true) {
                dispatch({type: PROJECT_FETCH_PAGED, paged: response.data});
            }
            else {
                dispatch({type: PROJECT_FETCH, proposals: response.data});
            }
            dispatch({type: PROJECT_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: PROJECT_IS_LOADING, isLoading: false});
            throw e;
        }

    },
    updateProject: state => async (dispatch, getState) => {
        try {
            if (getState().projects.isLoading) {
                return;
            }
            dispatch({type: PROJECT_IS_LOADING, isLoading: true});
            const url = "api/project";
            await axios.put(url, state);
            dispatch({type: PROJECT_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: PROJECT_IS_LOADING, isLoading: false});
            throw e;
        }
    },
    deleteProject: id => async (dispatch, getState) => {
        try {
            if (getState().projects.isLoading) {
                return;
            }
            dispatch({type: PROJECT_IS_LOADING, isLoading: true});
            const url = "api/project/"+id;
            await axios.delete(url);
            dispatch({type: PROJECT_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: PROJECT_IS_LOADING, isLoading: false});
            throw e;
        }
    },
    getPurchaseOrderListByProjectId: (projectId) => async (dispatch, getState) => {
        //if (getState().projects.isLoading) {
        //    return;
        //}  
        dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
        if (projectId === 0) {
            dispatch({ type: PURCHASE_ORDER_List_BY_PROJECT_ID_FETCH, purchaseOrderList:[] });
        }
        else {
            const url = "/api/Project/purchaseOrders?projectId=" + projectId;
            let response = await axios.get(url);
            dispatch({ type: PURCHASE_ORDER_List_BY_PROJECT_ID_FETCH, purchaseOrderList: response.data });
        }
        dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
    },
    searchInPos: (projectId, searchFields, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        const url = "/api/Project/searchInPos";
        dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
        let response = await axios.post(url, { projectId, searchFields, sortColumn, reverse });
        dispatch({ type: PURCHASE_ORDER_List_BY_PROJECT_ID_FETCH, purchaseOrderList: response.data });
        dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
    },
    searchShipmentListByProjectId: (projectId, searchFields, sortColumn, reverse) => async (dispatch, getState) => {
        //if (getState().projects.isLoading) {
        //    return;
        //}
        const url = "/api/Project/Searchshipments";
        dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
        let response = await axios.post(url, { projectId, searchFields, sortColumn, reverse });
        dispatch({ type: SHIPMENT_List_BY_PROJECT_ID_FETCH, shipmentList: response.data });
        dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
    },

    getShipmentListByProjectId: (projectId) => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }

        const url = "/api/Project/shipments?projectId=" + projectId;
        dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
        await axios.get(url)
            .then(function (response) {
                //success handled here
                dispatch({ type: SHIPMENT_List_BY_PROJECT_ID_FETCH, shipmentList: response.data });
            })
            .catch(function (error) {
                //if error
                console.clear();
                console.log(error);
                dispatch({ type: SHIPMENT_List_BY_PROJECT_ID_FETCH, shipmentList: [] });
                dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
            })
            .then(function () {
                // always executed
                dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
             });
       
    },
    fetchClientsByTypes: typeId => async (dispatch, getState) => {
        try {
            if (getState().projects.isLoading) {
                //return;
            }
            dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
            const url = "api/client";
            let response = await axios.get(url, { params: { typeId } });
            dispatch({ type: CLIENT_PFETCH_TYPE, clients: response.data });
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
            throw (e);
        }
    },
    fechCounts: (projectId) => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
        const url = "api/Project/dataCounts";
        let response = await axios.post(url, { projectId });
        dispatch({ type: PROJECT_COUNT_FETCHED, counts: response.data });
        dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
    },
    createWarehouseCharges: state => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        try {
            dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
            const url = "/api/project/createWarehouseCharge";
            let response = await axios.post(url, state);
            dispatch({ type: WAREHOUSE_CHARGES_RESPONSE, warehouseresponse: response.data });
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
        }
    },
    updateItemsWeight: state => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        try {
            dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
            const url = "/api/project/updateitemsweight";
            await axios.post(url, state);
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
        }
    },
    updateItemsPieces: state => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        try {
            dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
            const url = "/api/project/updateitemspieces";
            await axios.post(url, state);
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
        }
    },
    updateBillingHours: state => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        try {
            dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
            const url = "/api/project/updatebillingHours";
            await axios.post(url, state);
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
        }
    },
    fetchzeroItemsWeight: state => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        try {
            dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
            const url = "/api/project/fetchzeroweightitems";
            let response = await axios.post(url, state);
            dispatch({ type: WAREHOUSE_CHARGES_RESPONSE, warehouseresponse: response.data });
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
        }
    },
     getBillingPeriods: state => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        try {
            dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
            const url = "/api/project/getBillingPeriods";
            let response = await axios.post(url, state);
            dispatch({ type: BILLINGPERIODS_LIST, billingPeriods: response.data });
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PROJECT_IS_LOADING, isLoading: false });
        }
    },
     getProjectDetails: (ProjectId) => async (dispatch, getState) => {
         if (getState().commonApis.isLoading) {
             return;
         }
         dispatch({ type: PROJECT_IS_LOADING, isLoading: true });
       
         const url = "/api/project/getProjectDetails?ProjectId=" + ProjectId;
         let response = await axios.get(url);         
         dispatch({ type: PROJECT_DETAILS, projectDetails: response.data });
         
         dispatch({ type: PROJECT_IS_LOADING, isLoading: false });

     },     
};

const initialState = {
    isLoading: false,
    list: [],
    paged: {},
    purchaseOrderList:[],
    shipmentList: [],
    lastSaved: null,
    selected: null,
    projectDetails:null,
    clients: [],
    dataCounts: {},
    warehouseresponse: {},
    billingPeriods: [],
    filtered: [],
    pagedFiltered: {},   
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case PROJECT_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case PROJECT_FETCH:
            return {...state, list: action.projects};
        case PROJECT_SAVED:
            return {...state, lastSaved: action.lastSaved};
        case PROJECT_FETCH_PAGED:
            return {...state, paged: action.paged};
        case PROJECT_SINGLE_FETCH:
            return { ...state, selected: action.selected };
        case PURCHASE_ORDER_List_BY_PROJECT_ID_FETCH:
            return { ...state, purchaseOrderList: action.purchaseOrderList }
        case SHIPMENT_List_BY_PROJECT_ID_FETCH:
            return { ...state, shipmentList: action.shipmentList }
        case CLIENT_PFETCH_TYPE:
            return { ...state, clients: action.clients }
        case PROJECT_COUNT_FETCHED:
            return { ...state, dataCounts: action.counts }
        case WAREHOUSE_CHARGES_RESPONSE:
            return { ...state, warehouseresponse: action.warehouseresponse }
        case BILLINGPERIODS_LIST:
            return { ...state, billingPeriods: action.billingPeriods }
        case PROJECT_DETAILS:
            return { ...state, projectDetails: action.projectDetails }
        case PROJECT_FILTER_COLUMNS:
            return { ...state, filtered: action.filter }
        case PROJECT_FETCH_PAGED_FILTERED:
            return { ...state, pagedFiltered: action.pagedFiltered };        
        default:
            return state;
    }
};