import axios from "axios";

const STATES_LOADING = "STATES_LOADING";
const STATES_FETCHED = "STATES_FETCHED";

export const actionCreators = {
    fetchStates: (id) => async (dispatch, getState) => {
        try{
            if(getState().states.isLoading){
                return;
            }
            dispatch({type:STATES_LOADING,isLoading:true});
            let url = "/api/state/country/"+id;
            let response = await axios.get(url);
            dispatch({type:STATES_FETCHED,states:response.data});
            dispatch({type:STATES_LOADING,isLoading:false});
        }
        catch (e){
            dispatch({type:STATES_LOADING,isLoading:false});
            throw e;
        }
    }
};

const initialState = {
    isLoading:false,
    list:[],
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case STATES_LOADING:
            return {...state,isLoading:action.isLoading};
        case STATES_FETCHED:
            return {...state,list:action.states};
        default:
            return state;
    }
};