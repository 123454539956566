import axios from "axios";
const PICK_TICKET_IS_LOADING = "PICK_TICKET_IS_LOADING";
const PICK_TICKET_SAVED = "PICK_TICKET_SAVED";
const PICK_TICKET_SINGLE_FETCH = "PICK_TICKET_SINGLE_FETCH";
const PICK_TICKET_FETCH_PAGED = "PICK_TICKET_FETCH_PAGED";
const PICK_TICKET_FILTER_COLUMNS = "PICK_TICKET_FILTER_COLUMNS";
const PICK_TICKET_EXTLIST = "PICK_TICKET_EXTLIST";
const PICK_TICKET_COUNT_FETCHED = "PICK_TICKET_COUNT_FETCHED";
export const actionCreators = {
    createPickTicket: data => async (dispatch, getState) => {
        if (getState().pickTickets.isLoading) {
            return;
        }
        try {
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: true });
            if (data === 0) {
                dispatch({ type: PICK_TICKET_SAVED, lastSaved: null });
            }
            else{
                const url = "/api/pickTicket";         
                let response = await axios.post(url, data);
                dispatch({ type: PICK_TICKET_SAVED, lastSaved: response.data });
                }
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });

        }
        catch (e) {
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
        }
    },
 getSinglePickTkt: id => async (dispatch, getState) => {
     if (getState().pickTickets.isLoading) {
            return;
        }
        try {
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: true });
            if (id === 0) {
                dispatch({ type: PICK_TICKET_SINGLE_FETCH, selected: null });
            }
            else {
                const url = "/api/pickTicket/" + id;
                let response = await axios.get(url);
                dispatch({ type: PICK_TICKET_SINGLE_FETCH, selected: response.data });
            }
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
        }
    },
    updatePickTicket: state => async (dispatch, getState) => {
        try {
            if (getState().pickTickets.isLoading) {
                return;
            }
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: true });
            const url = "/api/pickTicket";
            await axios.put(url, state);
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
            throw e;
        }
 },
    getPagedPickTks: (pageIndex, pageSize) => async (dispatch, getState) => {
        if (getState().pickTickets.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        const url = "/api/pickTicket/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize;
        dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: PICK_TICKET_FETCH_PAGED, paged: response.data });
        dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
    },
    getFilteredColumns: () => async (dispatch, getState) => {
        if (getState().pickTickets.isLoading) {
            return;
        }
        dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: true });
        const url = "/api/pickTicket/filtered";
        let response = await axios.get(url);
        dispatch({ type: PICK_TICKET_FILTER_COLUMNS, filter: response.data });
        dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
    },
    searchPictkts: (searchFields, paged, pageIndex, pageSize, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().pickTickets.isLoading) {
            return;
        }
        try {
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: true });
            const url = "/api/pickTicket/search";
            let response = await axios.post(url, { searchFields, paged, pageIndex, pageSize, sortColumn, reverse });
            if (paged === true) {
                dispatch({ type: PICK_TICKET_FETCH_PAGED, paged: response.data });
            }
            //else {
            //    dispatch({ type: RECEIVING_ALERTS_FETCH_DATA, data: response.data });
            //}
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    deletePickTkt: id => async (dispatch, getState) => {
        try {
            if (getState().pickTickets.isLoading) {
                return;
            }
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: true });
            const url = "/api/pickTicket/" + id

            await axios.delete(url);
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getExistingPickTktListByProjectId: (projectId) => async (dispatch, getState) => {
        if (getState().pickTickets.isLoading) {
            return;
        }
        try {
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: true });
            const url = "/api/pickTicket/getExtPickTkts?ProjectId=" + projectId;
            //debugger;
            let response = await axios.get(url);
            //itemTransactionLogList
            dispatch({ type: PICK_TICKET_EXTLIST, extPickList: response.data });
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    searchExtPickTicket: (searchFields, sortColumn, reverse, projectId) => async (dispatch, getState) => {
        if (getState().pickTickets.isLoading) {
            return;
        }
        try {
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: true });
            const url = "/api/pickTicket/searchInList";
            let response = await axios.post(url, { searchFields, sortColumn, reverse, projectId });

            dispatch({ type: PICK_TICKET_EXTLIST, extPickList: response.data });

            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    fechCounts: (pickTicketId) => async (dispatch, getState) => {
        if (getState().pickTickets.isLoading) {
            return;
        }
        dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: true });
        const url = "api/pickTicket/dataCounts";
        let response = await axios.post(url, { pickTicketId });
        dispatch({ type: PICK_TICKET_COUNT_FETCHED, counts: response.data });
        dispatch({ type: PICK_TICKET_IS_LOADING, isLoading: false });
    },
};
const initialState = {
    isLoading: false,   
    lastSaved: null,
    selectedTkt: null,   
    paged: {},
    filtered: [],
    extPickList: {},
    dataCounts: {},
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case PICK_TICKET_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case PICK_TICKET_SAVED:
            return { ...state, lastSaved: action.lastSaved };     
        case PICK_TICKET_SINGLE_FETCH:
            return { ...state, selectedTkt: action.selected }
        case PICK_TICKET_FETCH_PAGED:
            return { ...state, paged: action.paged }; 
        case PICK_TICKET_FILTER_COLUMNS:
            return { ...state, filtered: action.filter }
        case PICK_TICKET_EXTLIST:
            return { ...state, extPickList: action.extPickList }
        case PICK_TICKET_COUNT_FETCHED:
            return { ...state, dataCounts: action.counts }
        default: return state;
    }
}