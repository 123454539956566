import axios from "axios";

const CLAIM_FETCHED = "CLAIM_FETCHED";
const CLAIM_IS_LOADING = "CLAIM_IS_LOADING";

export const actionCreators = {
    fetchClaims: (isClient) => async (dispatch, getState) => {
        if (getState().claims.isLoading) {
            return;
        }
        const url = "/api/claim";
        let config = null;
        dispatch({type: CLAIM_IS_LOADING, isLoading: true});
        if (isClient) {
            config = {
                params: {isClient: true}
            }
        }
        let response = await axios.get(url, config);
        dispatch({type: CLAIM_FETCHED, claims: response.data});
        dispatch({type: CLAIM_IS_LOADING, isLoading: false});
    }
};

const initialState = {
    isLoading: false,
    list: []
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case CLAIM_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        case CLAIM_FETCHED:
            return {
                ...state,
                list: action.claims
            };
        default:
            return state;
    }
};