import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {actionCreators} from "../../store/Notifications";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import moment from "moment";
import classnames from "classnames";

class NotificationsDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownOpen: false,
        }
    }

    timer = null;

    componentWillMount() {
        this.props.actions.fetchForDropdownNotifications(true);
        //this.timer = setInterval(()=>this.props.actions.fetchForDropdownNotifications(true), 2000);
    }

    dismissNotification = async (e, id) => {
        e.preventDefault();
        await this.props.actions.dismissNotification(id);
        await this.props.actions.fetchForDropdownNotifications();
        //toastr.success("Notification dismissed");
    };
    changeUserDropDownVisibility = () => {
        this.setState(prevState => ({dropDownOpen: !prevState.dropDownOpen}));
    };

    render() {
        return (
            <li className={classnames("dropdown", {"open": this.state.dropDownOpen})}>

                <a className="icon notification waves-effect waves-light icon-hand"
                   onClick={this.changeUserDropDownVisibility}>
                    <i className="material-icons">inbox</i>
                    {this.props.notifications.length > 0 &&
                    <span className="label label-notification">{this.props.notifications.length}</span>}
                </a>
                <ul className="dropdown-menu media-list pull-right animated fadeInDown">
                    <li className="dropdown-header text-center">Notifications {this.props.notifications.length > 0 && "(" + this.props.notifications.length + ")"}</li>
                    {this.props.notifications.map(notification => {
                        return (
                            <li className="media" key={notification.id}>
                                <Link to={notification.link}>
                                    <div className="media-body">
                                        <button
                                            className="pull-right"
                                            onClick={(e) => this.dismissNotification(e, notification.id)}
                                            style={{border: "0"}}
                                        >
                                            x
                                        </button>
                                        <h6 className="media-heading">{notification.title}</h6>
                                        <p>{notification.text}</p>
                                        <div
                                            className="text-muted f-s-11">{moment.utc(notification.scheduledFor).local().format("MM-DD-YYYY")}</div>
                                    </div>
                                </Link>
                            </li>);
                    })}

                    <li className="dropdown-footer text-center">
                        <Link to="/notifications">View more</Link>
                    </li>
                </ul>
            </li>
        );
    }
}

NotificationsDropdown.propTypes = {};
NotificationsDropdown.defaultProps = {};

const mapStateToProps = state => ({notifications: state.notifications.dropdownList});
const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actionCreators, dispatch)});
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsDropdown);
