import axios from "axios";

const PROPOSAL_IS_LOADING = "PROPOSAL_IS_LOADING";
const PROPOSAL_FETCHED = "PROPOSAL_FETCHED";
const PROPOSAL_SINGLE_FETCHED = "PROPOSAL_SINGLE_FETCHED";
const PROPOSAL_FETCH_PAGED = "PROPOSAL_FETCH_PAGED";
const PROPOSAL_SAVED = "PROPOSAL_SAVED";

export const actionCreators = {
    fetchProposals: () => async (dispatch, getState) => {
        try {
            if (getState().proposals.isLoading) {
                return;
            }
            const url = "/api/proposal";
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: true});
            let response = await axios.get(url);
            dispatch({type: PROPOSAL_FETCHED, proposals: response.data});
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: false});
        }
    },
    getSingle: id => async (dispatch, getState) => {
        try {
            if (getState().proposals.isLoading) {
                // Don't issue a duplicate request (we already have or are loading the requested data)
                return;
            }
            const url = "/api/proposal/" + id;
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: true});
            let response = await axios.get(url);
            dispatch({type: PROPOSAL_SINGLE_FETCHED, selected: response.data});
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: false});
        }
        catch (e) {

        }
    },
    createProposal: state => async (dispatch, getState) => {
        try {
            if (getState().proposals.isLoading) {
                return;
            }
            const url = "/api/proposal";
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: true});
            let response = await axios.post(url, state);
            dispatch({type: PROPOSAL_SAVED, lastSaved: response.data});
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: false});
        }
    },
    updateProposal: state => async (dispatch, getState) => {
        try {
            if (getState().proposals.isLoading) {
                return;
            }
            const url = "/api/proposal";
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: true});
            let response = await axios.put(url, state);
            dispatch({type: PROPOSAL_SAVED, lastSaved: response.data.id});
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: false});
        }
    },
    fetchPaged: (pageIndex, pageSize) => async (dispatch, getState) => {
        if (getState().proposals.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        const url = "/api/proposal/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize;
        dispatch({type: PROPOSAL_IS_LOADING, isLoading: true});
        let response = await axios.get(url);
        dispatch({type: PROPOSAL_FETCH_PAGED, paged: response.data});
        dispatch({type: PROPOSAL_IS_LOADING, isLoading: false});
    },
    searchProposals: (searchFields, paged, pageIndex, pageSize, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().clients.isLoading) {
            return;
        }
        try {
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: true});
            const url = "/api/proposal/search";
            let response = await axios.post(url, {searchFields, paged, pageIndex, pageSize, sortColumn, reverse});
            if (paged === true) {
                dispatch({type: PROPOSAL_FETCH_PAGED, paged: response.data});
            }
            else {
                dispatch({type: PROPOSAL_FETCHED, proposals: response.data});
            }
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: false});
            throw e;
        }

    },
    deleteProposal: id => async (dispatch, getState) => {
        if (getState().proposals.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        try {
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: true});
            const url = "/api/proposal/" + id;
            await axios.delete(url);
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: PROPOSAL_IS_LOADING, isLoading: false});
            throw e;
        }
    },
};

const initialState = {
    isLoading: false,
    list: [],
    paged: {},
    selected: null,
    lastSaved:null
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case PROPOSAL_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case PROPOSAL_FETCHED:
            return {...state, list: action.proposals};
        case PROPOSAL_FETCH_PAGED:
            return {...state, paged: action.paged};
        case PROPOSAL_SINGLE_FETCHED:
            return {...state, selected: action.selected};
        case PROPOSAL_SAVED:
            return {...state, lastSaved: action.lastSaved};
        default:
            return state;
    }
};