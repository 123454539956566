import axios from "axios";

const PO_FETCHED = "PO_FETCHED";
const PO_STATUS_IS_LOADING = "PO_STATUS_IS_LOADING";

export const actionCreators = {
    fetchPoStatuses: state => async (dispatch, getState) => {
        try {
            if (getState().purchaseOrderStatuses.isLoading) {
                return;
            }
            const url = "/api/POStatus";
            dispatch({ type: PO_STATUS_IS_LOADING, isLoading: true });
            let response = await axios.get(url);
            dispatch({ type: PO_FETCHED, purchaseOrderStatuses: response.data });
            dispatch({ type: PO_STATUS_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PO_STATUS_IS_LOADING, isLoading: false });
        }
    }
};

const initialState = {
    isLoading: false,
    list: []
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case PO_FETCHED:
            return { ...state, list: action.purchaseOrderStatuses };
        case PO_STATUS_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        default:
            return state;
    }
};