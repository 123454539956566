import axios from "axios";

const NOTIFICATIONS_IS_LOADING = "NOTIFICATIONS_IS_LOADING";
const NOTIFICATIONS_FETCHED = "NOTIFICATIONS_FETCHED";
const NOTIFICATIONS_FETCHED_DROPDOWN = "NOTIFICATIONS_FETCHED_DROPDOWN";
export const actionCreators = {
    fetchNotifications: (dismissed) => async (dispatch, getState) => {
        if (getState().notifications.isLoading) {
            return;
        }
        try {
            const url = "/api/notification";
            dispatch({type: NOTIFICATIONS_IS_LOADING, isLoading: true});
            let response = await axios.get(url,{params:{dismissed}});
            dispatch({type: NOTIFICATIONS_IS_LOADING, isLoading: false});
            dispatch({type: NOTIFICATIONS_FETCHED, notifications: response.data});
        }
        catch (e) {
            dispatch({type: NOTIFICATIONS_IS_LOADING, isLoading: false});
        }
    },
    fetchForDropdownNotifications: () => async (dispatch, getState) => {
        if (getState().notifications.isLoading) {
            return;
        }
        try {
            const url = "/api/notification";
            dispatch({type: NOTIFICATIONS_IS_LOADING, isLoading: true});
            let response = await axios.get(url,{params:{dismissed : false}});
            dispatch({type: NOTIFICATIONS_IS_LOADING, isLoading: false});
            dispatch({type: NOTIFICATIONS_FETCHED_DROPDOWN, notifications: response.data});
        }
        catch (e) {
            dispatch({type: NOTIFICATIONS_IS_LOADING, isLoading: false});
        }
    },
    dismissNotification: id => async (dispatch, getState) => {
        if (getState().notifications.isLoading) {
            return;
        }
        try {
            const url = "/api/notification/dismiss/" +id;
            dispatch({type: NOTIFICATIONS_IS_LOADING, isLoading: true});
            await axios.post(url);
            dispatch({type: NOTIFICATIONS_IS_LOADING, isLoading: false});
        }
        catch (e){
            dispatch({type: NOTIFICATIONS_IS_LOADING, isLoading: false});
        }
    }
};

const initialState = {
    list: [],
    isLoading: false,
    dropdownList:[],
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case NOTIFICATIONS_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case NOTIFICATIONS_FETCHED_DROPDOWN:
            return {...state, dropdownList: action.notifications};
        case NOTIFICATIONS_FETCHED:
            return {...state, list: action.notifications};
        default:
            return state;
    }
};