import axios from "axios";
const INVENTORY_ITEM_IS_LOADING = "INVENTORY_ITEM_IS_LOADING";
const INVENTORY_ITEM_SAVED = "INVENTORY_ITEM_SAVED";
const INVENTORY_ITEM_FETCHED = "INVENTORY_ITEM_FETCHED";
const INVENTORY_ITEM_COL_FETCHED = "INVENTORY_ITEM_COL_FETCHED";
const INVENTORY_ITEM_GET = "INVENTORY_ITEM_GET";
const INVENTORY_ITEM_UNGROUPED = "INVENTORY_ITEM_UNGROUPED";
const INVENTORY_ITEM_NewGroup = "INVENTORY_ITEM_NewGroup";
const INVENTORY_ITEM_EXTGroup = "INVENTORY_ITEM_EXTGroup";
const INVENTORY_ITEM_GET_GROUPS = "INVENTORY_ITEM_GET_GROUPS";
const ITEM_LOGS = "ITEM_LOGS";
const INVENTORY_ITEM_LOGSCOL_FETCHED = "INVENTORY_ITEM_LOGSCOL_FETCHED";
const INVENTORY_ITEM_FETCH_PAGED = "INVENTORY_ITEM_FETCH_PAGED";
const INVENTORY_GET_GROUPEDITEMS = "INVENTORY_GET_GROUPEDITEMS";
export const actionCreators = {
    createInventoryItem: state => async (dispatch, getState) => {
        if (getState().inventoryItems.isLoading) {
            return;
        }
        try {
            const url = "/api/inventoryItems";
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });           
            const config = { headers: { 'Content-Type': 'application/json' } };
            let response = await axios.post(url, state, config);
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            dispatch({ type: INVENTORY_ITEM_SAVED, lastSaved: response.data });
        } catch (e) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    updateInventoryItem: state => async (dispatch, getState) => {
        try {
            //console.clear();
            //console.log(state);
            //debugger;
            if (getState().inventoryItems.isLoading) {
               // return;
            }
            
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/inventoryItems/updateItem";
            await axios.put(url, state);
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getInventoryItems: (inventoryId, projectId, requiredData) => async (dispatch, getState) => {
        if (getState().inventoryItems.isLoading) {
            return;
        }

        if (inventoryId === 0) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            dispatch({ type: INVENTORY_ITEM_FETCHED, inventoryItems: {} });
        }
        else {
            const url = "/api/inventoryItems/getAllInventoryItem?InventoryId=" + inventoryId + '&ProjectId=' + projectId + '&requiredData=' + requiredData;
            ///api/inventoryItems/getAllInventoryItem?InventoryId=4&ProjectId=4
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            let response = await axios.get(url);
            dispatch({ type: INVENTORY_ITEM_FETCHED, inventoryItems: response.data });
        }
        dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
    },
    getFilteredColums: (inventoryId, projectId) => async (dispatch, getState) => {
        if (getState().inventoryItems.isLoading) {
            return;
        }
        dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
        const url = "/api/inventoryItems/filteredColums?InventoryId=" + inventoryId + '&ProjectId=' + projectId;
        let response = await axios.get(url);
        dispatch({ type: INVENTORY_ITEM_COL_FETCHED, filter: response !== undefined ? response.data : []});
        dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
    },
    searchInList: (searchFields, inventoryId,sortColumn, reverse, requiredData) => async (dispatch, getState) => {
        if (getState().inventoryItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/inventoryItems/search";
            let response = await axios.post(url, { searchFields, inventoryId, sortColumn, reverse, requiredData });
            dispatch({ type: INVENTORY_ITEM_FETCHED, inventoryItems: response.data });
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getInventories: (inventoryId, projectId, requiredData) => async (dispatch, getState) => {
        if (getState().inventoryItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/inventoryItems/allInventories";
            let response = await axios.post(url, { inventoryId, projectId, requiredData});
            dispatch({ type: INVENTORY_ITEM_GET, inventoryItems: response.data });
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    getExistingGroups: (inventoryId, projectId) => async (dispatch, getState) => {
        if (getState().inventoryItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/inventoryItems/getGroups?InventoryId=" + inventoryId + '&ProjectId=' + projectId;
            let response = await axios.get(url);
            dispatch({ type: INVENTORY_ITEM_GET_GROUPS, groups: response.data });
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    ungroupItems: (projectId, selectedInventoryItems) => async (dispatch, getState) => {        
        try {
            if (getState().inventoryItems.isLoading) {
                return;
            }
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/inventoryItems/ungroup";
            await axios.post(url, { projectId, selectedInventoryItems});           
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            //dispatch({ type: INVENTORY_ITEM_UNGROUPED, ungroup: response.data });
        }
        catch (e) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    addToGroup: (projectId, selectedInventoryItems, selectedGroupId) => async (dispatch, getState) => {
        try {
            if (getState().inventoryItems.isLoading) {
                return;
            }
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/inventoryItems/grouping";
            let response = null;
            if (selectedGroupId != null) {
                response = await axios.post(url, { projectId, selectedInventoryItems, selectedGroupId });               
                dispatch({ type: INVENTORY_ITEM_EXTGroup, extGroupId: response.data });
            }
                else{
                response = await axios.post(url, { projectId, selectedInventoryItems });               
                dispatch({ type: INVENTORY_ITEM_NewGroup, groupId: response.data });
            }
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    addToExtistingGroup: (projectId, selectedInventoryItems,groupId) => async (dispatch, getState) => {
        try {
            if (getState().inventoryItems.isLoading) {
                return;
            }
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/inventoryItems/grouping";
            let response = await axios.post(url, { projectId, selectedInventoryItems });
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            dispatch({ type: INVENTORY_ITEM_EXTGroup, extGroupId: response.data });
        }
        catch (e) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getItemLogs: (inventoryId, projectId, itemDetailMasterId) => async (dispatch, getState) => {
        if (getState().inventoryItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/inventoryItems/getItemLogs?InventoryId=" + inventoryId + '&ProjectId=' + projectId + '&ItemDetailMasterId=' + itemDetailMasterId;
            let response = await axios.get(url);
            //itemTransactionLogList
            dispatch({ type: ITEM_LOGS, itemLogs: response.data });
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    getLogsFilteredColums: (inventoryId,itemDetailMasterId,projectId) => async (dispatch, getState) => {
        if (getState().inventoryItems.isLoading) {
            return;
        }
        dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
        const url = "/api/inventoryItems/getLogsColums?InventoryId=" + inventoryId + '&ProjectId=' + projectId + '&ItemDetailMasterId=' + itemDetailMasterId;
        let response = await axios.get(url);
        dispatch({ type: INVENTORY_ITEM_LOGSCOL_FETCHED, filterLogs: response.data });
        dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
    },
    searchInItemLogs: (searchFields, inventoryId, projectId, itemDetailMasterId, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().inventoryItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/inventoryItems/searchInLogs";
            let response = await axios.post(url, { searchFields, inventoryId, projectId, itemDetailMasterId, sortColumn, reverse });
            dispatch({ type: ITEM_LOGS, itemLogs: response.data });
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    addToNewGroup: (projectId, selectedInventoryItems) => async (dispatch, getState) => {
        try {
            if (getState().inventoryItems.isLoading) {
                return;
            }
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/inventoryItems/newGroup";
            let response = null;           
                response = await axios.post(url, { projectId, selectedInventoryItems });
                dispatch({ type: INVENTORY_ITEM_NewGroup, newGroupId: response.data });
           
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getPagedInventroies: (inventoryId, projectId, requiredData,pageIndex, pageSize) => async (dispatch, getState) => {
        if (getState().inventoryItems.isLoading) {
            return;
        }
        //const url = "/api/inventoryItems/paged?inventoryId=" + inventoryId + "&projectId=" + projectId + "&pageIndex=" + pageIndex + "&pageSize=" + pageSize + '&requiredData=' + requiredData;
        const url = "/api/inventoryItems/paged";
        dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
        await axios.post(url, { inventoryId, projectId, requiredData, pageIndex, pageSize})
            .then(function (response) {
                //success handled here
                dispatch({ type: INVENTORY_ITEM_FETCH_PAGED, paged: response.data });
            })
            .catch(function (error) {
                //if error
                console.clear();
                console.log(error);
                dispatch({ type: INVENTORY_ITEM_FETCH_PAGED, paged: {} });
                dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            })
            .then(function () {
                // always executed
                dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            });

    },
    searchInPaged: (searchFields, paged, pageIndex, pageSize, sortColumn, reverse, inventoryId,projectId,requiredData) => async (dispatch, getState) => {
        if (getState().inventoryItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/inventoryItems/searchPaged";
            await axios.post(url, { searchFields, paged, pageIndex, pageSize, sortColumn, reverse, inventoryId, projectId, requiredData })
                .then(function (response) {
                    //success handled here
                    dispatch({ type: INVENTORY_ITEM_FETCH_PAGED, paged: response.data });
                })
                .catch(function (error) {
                    //if error
                    console.clear();
                    console.log(error);
                    dispatch({ type: INVENTORY_ITEM_FETCH_PAGED, paged: {} });
                    dispatch({ type: INVENTORY_ITEM_FETCH_PAGED, isLoading: false });
                })
                .then(function () {
                    // always executed
                    dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
                }); 
        }
        catch (e) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getGroupedItems: (groupedItem) => async (dispatch, getState) =>
    {
        if (getState().inventoryItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/inventoryItems/getgroupeditems";
            let response = await axios.post(url, { groupedItem });
            dispatch({ type: INVENTORY_GET_GROUPEDITEMS, inventoryItems: response.data });
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: INVENTORY_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }

    },
}
const initialState = {
    isLoading: false,
    lastSaved: null,
    selectedInvventoryItem: null,
    data: {},
    filtered: [],
    ungroup: null,
    newGroupId: null,
    extGroupId: null,
    groups: {},
    itemLogs: {},
    filterLogsCol: [],  
    paged: {},
    groupedItems: []
};
export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case INVENTORY_ITEM_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case INVENTORY_ITEM_SAVED:
            return { ...state, lastSaved: action.lastSaved };
        case INVENTORY_ITEM_FETCHED:
            return { ...state, data: action.inventoryItems };
        case INVENTORY_ITEM_COL_FETCHED:
            return { ...state, filtered: action.filter };
        case INVENTORY_ITEM_GET:
            return { ...state, data: action.inventoryItems }
        case INVENTORY_ITEM_UNGROUPED:
            return { ...state, ungroup: action.ungroup }
        case INVENTORY_ITEM_NewGroup:
            return { ...state, newGroupId: action.newGroupId }
        case INVENTORY_ITEM_EXTGroup:
            return { ...state, extGroupId: action.extGroupId }
        case INVENTORY_ITEM_GET_GROUPS:
            return { ...state, groups: action.groups }
        case ITEM_LOGS:
            return { ...state, itemLogs: action.itemLogs }
        case INVENTORY_ITEM_LOGSCOL_FETCHED:
            return { ...state, filterLogsCol: action.filterLogs };
        case INVENTORY_ITEM_FETCH_PAGED:
            return { ...state, paged: action.paged };
        case INVENTORY_GET_GROUPEDITEMS:
            return { ...state, groupedItems: action.inventoryItems.itemsWithoutWeightList };
        default: return state;
    }
}