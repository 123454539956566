import React, {Component} from 'react';
import SideBarNav from './sideBarNav/SideBarNav';
import Header from './Header';
var classNames = require("classnames");

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSideBarMinified: false
        }
        this.minifySideBar = this.minifySideBar.bind(this);
    }

    minifySideBar() {
        this.setState({
            isSideBarMinified: !this.state.isSideBarMinified
        });
    }

    render() {
        return (
            <div id="page-container" className={classNames("fade page-sidebar-fixed page-header-fixed in",
                { "page-sidebar-minified": this.state.isSideBarMinified })}>
                <Header minifySideBar={this.minifySideBar}/>
                <SideBarNav minifySideBar={this.minifySideBar}/>
                <div className="sidebar-bg"/>
                <div id="content" className="content">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Layout;