// import the library
import { library } from '@fortawesome/fontawesome-svg-core';
// import your required icons
import {
    faHome,
    faHandHoldingUsd,
    faTasks,
    faFileAlt,
    faShippingFast,
    faTruckLoading,
    faWarehouse,
    faTicketAlt,
    faFileInvoiceDollar,
    faChartBar,
    faLock,
    faUsers,
    faAddressBook,
    faAngleDoubleLeft,
    faSignOutAlt,
    faSignInAlt,
    faGripLines,
    faPlus,
    faMinus,
    faQuestionCircle,
    faPlusCircle,
    faMinusCircle,
    faSortAmountUp,
    faSortAmountDown,
    faExclamationTriangle, faWindowClose, faSyncAlt, faTimes, faTimesCircle, faEye, faPen, faBars, faSpinner,
    faTrashAlt, faTrash, faForward, faBackward, faArrowRight, faArrowLeft

} from '@fortawesome/free-solid-svg-icons';
//this icon installed but i am not using in app so not include as it loads app
//import { faCode, faHighlighter } from '@fortawesome/free-regular-svg-icons';
//import { faCode, faHighlighter } from '@fortawesome/free-light-svg-icons';

library.add(   
    faHome,
    faHandHoldingUsd,
    faTasks,
    faFileAlt,
    faShippingFast,
    faTruckLoading,
    faWarehouse,
    faTicketAlt,
    faFileInvoiceDollar,
    faChartBar,
    faLock,
    faUsers,
    faAddressBook,
    faAngleDoubleLeft,
    faSignOutAlt,
    faSignInAlt,
    faGripLines,
    faPlus,
    faMinus,
    faQuestionCircle,
    faPlusCircle,
    faMinusCircle,
    faSortAmountUp,
    faSortAmountDown,
    faExclamationTriangle, faWindowClose, faSyncAlt, faTimes, faTimesCircle, faEye, faPen, faBars,
    faSpinner, faTrashAlt, faTrash, faForward, faBackward, faArrowLeft, faArrowRight   
);