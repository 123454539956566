import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import SideBarNavMenu from './SideBarNavMenu';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class SideBarNav extends Component {
    constructor(props) {
        super(props);
        this.isActive = this.isActive.bind(this);
        this.state = {
            isActive: false
        }
    }

    isActive(path) {
        return path.indexOf(this.props.location.pathname) > -1;
    }

    render() {
        return (
            <div id="sidebar" className="sidebar">
                <div data-scrollbar="true" data-height="100%" className="sideBarScroll">
                    <ul className="nav">
                        <li className="nav-profile">
                            <a href="#" data-toggle="nav-profile">
                                <div className="image">
                                    <img src={require("../../../assets/img/user-default.png")} alt="" />
                                </div>
                                <div className="info">
                                    {this.props.user.unique_name}
                                </div>
                            </a>
                        </li>
                    </ul>
                    <ul className="nav">
                        <SideBarNavMenu to="/" isActive={() => this.isActive(["/"])}
                            name="Home"
                            icon="home"
                        />
                        {hasPermission(this.props.user.scope, ["Admin"]) &&
                            <SideBarNavMenu to="/users" isActive={() => this.isActive(["/user", "/users/create"])} name="Users"
                                icon="users">
                            </SideBarNavMenu>
                        }
                        {hasPermission(this.props.user.scope, ["AddressBook:View", "AddressBook:Create", "AddressBook:Update", "AddressBook:Delete"]) &&
                            <SideBarNavMenu to="/address-books"
                                isActive={() => this.isActive(["/address-book", "/address-book/create"])}
                                name="Address Book"
                                icon="address-book">
                            </SideBarNavMenu>
                        }
                        {hasPermission(this.props.user.scope, ["Proposal:View", "Proposal:Create", "Proposal:Update", "Proposal:Delete"]) &&
                            <SideBarNavMenu to="/proposals" isActive={() => this.isActive(["/proposals", "/proposals/create"])}
                                name="Proposals"
                                icon="hand-holding-usd">
                            </SideBarNavMenu>
                        }
                        {hasPermission(this.props.user.scope, ["Project:View", "Project:Create", "Project:Update", "Project:Delete"]) &&
                            <SideBarNavMenu to="/projects" isActive={() => this.isActive(["/projects", "/project/create"])}
                                name="Projects"
                                icon="tasks">
                            </SideBarNavMenu>
                        }

                        {hasPermission(this.props.user.scope, ["PO:View", "PO:Create", "PO:Update", "PO:Delete"]) &&
                            <SideBarNavMenu to="/purchase-orders"
                                isActive={() => this.isActive(["/purchase-orders", "/purchase-orders/create"])}
                                name="Purchase Orders"
                                icon="file-alt">
                            </SideBarNavMenu>
                        }
                        {hasPermission(this.props.user.scope, ["Shipment:View", "Shipment:Create", "Shipment:Update", "Shipment:Delete"]) &&
                            <SideBarNavMenu to="/shipments" isActive={() => this.isActive(["/shipments", "/shipments/create"])}
                                name="Shipments"
                            icon="fa-shipping-fast">
                            </SideBarNavMenu>
                        }
                        {
                            hasPermission(this.props.user.scope, ["RA:View", "RA:Create", "RA:Update", "RA:Delete"]) &&
                            <SideBarNavMenu to="/receiving-alerts"
                                isActive={() => this.isActive(["/receiving-alerts", "/receiving-alerts/create"])}
                                name="Receiving Alerts"
                                icon="truck-loading" isClass="true">
                            </SideBarNavMenu>
                        }
                        {
                            hasPermission(this.props.user.scope, ["Inventory:View", "Inventory:Create", "Inventory:Update", "Inventory:Delete"]) &&
                            <SideBarNavMenu to="/inventory"
                                isActive={() => this.isActive(["/inventory", "/inventory/create"])}
                                name="Inventory"
                                icon="warehouse">
                            </SideBarNavMenu>
                        }
                        {
                            hasPermission(this.props.user.scope, ["PickTicket:View", "PickTicket:Create", "PickTicket:Update", "PickTicket:Delete"]) &&
                            <SideBarNavMenu to="/pick-tickets"
                                isActive={() => this.isActive(["/pick-tickets", "/pick-tickets/create"])}
                                name="Pick Tickets"
                                icon="ticket-alt" isClass="true">
                            </SideBarNavMenu>
                        }
                        {
                            hasPermission(this.props.user.scope, ["Invoice:View", "Invoice:Create", "Invoice:Update", "Invoice:Delete"]) &&
                            <SideBarNavMenu to="/invoices"
                                isActive={() => this.isActive(["/invoices", "/invoices/create"])}
                                name="Invoices"
                                icon="file-invoice-dollar">
                            </SideBarNavMenu>
                        }

                        {
                            hasPermission(this.props.user.scope, ["Reports:View", "Reports:Create", "Reports:Update", "Reports:Delete"]) &&
                            <SideBarNavMenu to="/reports"
                                isActive={() => this.isActive(["/reports", "/reports/create"])}
                                name="Reports"
                                icon="chart-bar">
                            </SideBarNavMenu>
                        }
                        <li>
                            <a href="javascript:;" className="sidebar-minify-btn" onClick={this.props.minifySideBar}>
                                <FontAwesomeIcon icon={"angle-double-left"} size="lg" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

const hasPermission = (claims, permission) => {
    if (claims && permission) {
        if (claims === "Admin") {
            return true;
        }
        if (permission instanceof Array) {
            claims = claims.split(' ');
            // debugger;
            return claims.some(item => {
                return permission.indexOf(item) >= 0
            });
        }
        if (claims && permission) {
            return claims.indexOf(permission) !== -1
        }
    }
    return false;
};


const mapStateToProps = state => ({ user: state.userAuthentication.user });
export default withRouter(connect(mapStateToProps, null)(SideBarNav));