import axios from "axios";
const PO_RECEIVING_ALERT_FETCH = "PO_RECEIVING_ALERT_FETCH";
const PO_RECEIVING_ALERT_IS_LOADING = "PO_RECEIVING_ALERT_IS_LOADING";
 // eslint-disable-next-line
const SHIPMENT_RECEIVING_ALERT_SEARCH = "SHIPMENT_RECEIVING_ALERT_SEARCH";
const PO_RECEIVING_ALERT_ITEM_SAVED = "PO_RECEIVING_ALERT_ITEM_SAVED";
const PO_RECEIVING_ALERT_FETCH_BYPO = "PO_RECEIVING_ALERT_FETCH_BYPO";
const RA_FILTER_COL_FETCHED = "RA_FILTER_COL_FETCHED";
export const actionCreators = {
    getRAListByPurchaseOrderId: (purchaseOrderId) => async (dispatch, getState) => {
        if (getState().purchaseOrderReceivingAlerts.isLoading) {
            return;
        }
        const url = "/api/poReceivingAlerts/receivingAlertList?purchaseOrderId=" + purchaseOrderId;
        dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: PO_RECEIVING_ALERT_FETCH, receivingAlertList: response.data });
        dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: false });
    },
    getExistingRAListByPOId: (purchaseOrderId) => async (dispatch, getState) => {
        if (getState().purchaseOrderReceivingAlerts.isLoading) {
            return;
        }
        const url = "/api/poReceivingAlerts/exRAList?purchaseOrderId=" + purchaseOrderId;
        dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: PO_RECEIVING_ALERT_FETCH_BYPO, existingRAList: response.data });
        dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: false });
    },
    searchInRAList: (searchFields, purchaseOrderId, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().purchaseOrderReceivingAlerts.isLoading) {
            return;
        }
        try {
            dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: true });
            const url = "/api/poReceivingAlerts/search";
            let response = await axios.post(url, { searchFields, purchaseOrderId, sortColumn, reverse });
            dispatch({ type: PO_RECEIVING_ALERT_FETCH, receivingAlertList: response.data });
            dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    createRAItemFromPo: data => async (dispatch, getState) => {
        if (getState().purchaseOrderReceivingAlerts.isLoading) {
            //return;
        }
        try {
            const url = "/api/poReceivingAlerts/createRAItemFromPo";
            dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: true });
            let response = await axios.post(url, data);
            dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: false });
            dispatch({ type: PO_RECEIVING_ALERT_ITEM_SAVED, lastSaved: response.data });
        } catch (e) {
            dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    createRAItem: data => async (dispatch, getState) => {
        if (getState().purchaseOrderReceivingAlerts.isLoading) {
            return;
        }
        try {
            const url = "/api/poReceivingAlerts/addToRAItem";
            dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: true });
            const config = { headers: { 'Content-Type': 'application/json' } };
             let response = await axios.post(url, data, config);
            console.clear();
            console.log(data);
            //debugger;
            //let response = await axios.post(url, data);
            dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: false });
            dispatch({ type: PO_RECEIVING_ALERT_ITEM_SAVED, lastSaved: response.data });
        } catch (e) {
            dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getFilteredColums: (purchaseOrderId) => async (dispatch, getState) => {
        if (getState().purchaseOrderReceivingAlerts.isLoading) {
            return;

        }
        dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: true });
        const url = "/api/poReceivingAlerts/filteredColums?PurchaseOrderId=" + purchaseOrderId;     
        let response = await axios.get(url);
        dispatch({ type:RA_FILTER_COL_FETCHED, filter: response.data });
        dispatch({ type: PO_RECEIVING_ALERT_IS_LOADING, isLoading: false });
    },
}
const initialState = {
    isLoading: false,
    poRAItemSaved: null,
    existingRAList: [],
    receivingAlertList: [],
    filteredColumns: [],
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case PO_RECEIVING_ALERT_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case PO_RECEIVING_ALERT_FETCH:
            return { ...state, receivingAlertList: action.receivingAlertList }
        case PO_RECEIVING_ALERT_ITEM_SAVED:
            return { ...state, poRAItemSaved: action.lastSaved }
        case PO_RECEIVING_ALERT_FETCH_BYPO:
            return { ...state, existingRAList: action.existingRAList }
        case RA_FILTER_COL_FETCHED:
            return { ...state, filteredColumns: action.filter }
        default: return state;
    }
}