import React from 'react';
import TextFieldGroup from "../common/TextFieldGroup";
import ProjectListSelectable from "../Project/ProjectListSelectable";
import UserModuleSelect from "./UserModuleSelect";
import 'react-select/dist/react-select.css';
import classnames from "classnames";
import MetadataModal from "../common/MetadataInfo";
import ClientSelect from "../common/ClientSelect";
function UserForm(props) {
    const { errors, userName, email, password, confirmPassword, firstName, lastName, title, clientId, status, userTypeId, isAdmin, isLoading, isEditMode, createdAt, modifiedAt, modifiedBy, createdBy, clientTypesIds} = props;
    return (
        <form onSubmit={props.onSubmit} className="form-horizontal">
            {errors.form && <div className="alert alert-danger">{errors.form}</div>}
            {  <div className="row">
                <div className="col-md-12">
                    <div className="col-md-6">
                        <h4>{props.isEditMode && firstName + " " + lastName}</h4>
                    </div>                    
                </div>
            </div>
            }
            {
                <div className="row loading">
                    <div className="col-md-12">
                        {isLoading === true &&   <div className="spinner" /> }
                        <div className="panel panel-axis">
                            <div className="panel">
                                <ul className="nav nav-tabs">
                                    <li className={classnames({ "active": true })}>
                                        <a className="icon-hand" onClick={() => this.tabClick("detailTab")}>
                                            <span>Details</span>
                                        </a>
                                    </li>
                                    {
                                        <div className="panel-heading-btn" style={{ 'margin': '4px' }}>
                                            <a href={isLoading ? "javascript:;" : "javascript:"}
                                                className={isLoading ? 'btn btn-success disabled  btn-xs' : 'btn btn-success btn-xs'}
                                                onClick={props.onSubmit}
                                                style={{ 'background': '#009900' }}
                                            >
                                                {(isEditMode === true) ? "Update" : "Create"}
                                            </a>

                                            {
                                                isEditMode === true &&
                                                <a onClick={props.displayWarning}
                                                    className={isLoading ? 'btn btn-danger btn-xs disabled' : "btn btn-danger btn-xs"}>Delete</a>
                                            }
                                            {<a href={props.cancelClicked ? "javascript:;" : "/users"}
                                                onClick={props.cancelClicked ? props.cancelClicked : "javascript:;"}
                                                className={isLoading ? 'btn btn-default btn-xs disabled' : "btn btn-default btn-xs"}>Close</a>}

                                            {props.isEditMode && <MetadataModal
                                                createdBy={createdBy}
                                                createdAt={createdAt}
                                                modifiedBy={modifiedBy}
                                                modifiedAt={modifiedAt}
                                            />}
                                            
                                        </div>}
                                </ul>
                                <div className="tab-content " >
                                    <div className={classnames("tab-pane fade in", { "active": true })} style={{ 'marginTop': '-4px' }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextFieldGroup
                                                    field="firstName"
                                                    label="First Name"
                                                    value={firstName}
                                                    error={errors.firstName}
                                                    onChange={props.onChange}
                                                    isRequired={true}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextFieldGroup
                                                    field="lastName"
                                                    label="Last Name"
                                                    value={lastName}
                                                    error={errors.lastName}
                                                    onChange={props.onChange}
                                                    isRequired={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={classnames('form-group', { 'has-error': errors.clientId })}>
                                                    <label className="control-label text-left col-md-2">Company <span
                                                        className="text-danger">*</span></label>
                                                    <div className="col-md-9">
                                                        {
                                                            <ClientSelect
                                                                clientTypeIds={clientTypesIds}
                                                                clientId={clientId}
                                                                requiredData={25}
                                                                setValue={props.selectCompany}
                                                                clientSaved={props.clientSaved}
                                                            />
                                                        }
                                                        {errors.clientId && <span className="help-block">{errors.clientId}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <TextFieldGroup
                                                    field="title"
                                                    label="Title"
                                                    value={title}
                                                    error={errors.title}
                                                    onChange={props.onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextFieldGroup
                                                    type="email"
                                                    field="email"
                                                    label="Email"
                                                    value={email}
                                                    error={errors.email}
                                                    onChange={props.onChange}
                                                    isRequired={true}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextFieldGroup
                                                    type="text"
                                                    field="userName"
                                                    label="Username"
                                                    value={userName}
                                                    error={errors.userName}
                                                    onChange={props.onChange}
                                                    readonly={props.isEditMode && "readonly"}
                                                    isRequired={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextFieldGroup
                                                    type="password"
                                                    field="password"
                                                    label="Password"
                                                    value={password}
                                                    error={errors.password}
                                                    onChange={props.onChange}
                                                    isRequired={true}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <TextFieldGroup
                                                    type="password"
                                                    field="confirmPassword"
                                                    label="Confirm Password"
                                                    value={confirmPassword}
                                                    error={errors.confirmPassword}
                                                    onChange={props.onChange}
                                                    isRequired={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={classnames('form-group', { 'has-error': errors.userTypeId })}>
                                                    <label className="control-label text-left col-md-2">Type <span
                                                        className="text-danger">*</span></label>
                                                    <div className="col-md-10">
                                                        {props.userTypeList && props.userTypeList.map(item => {
                                                            return (
                                                                <label key={item.id} className="radio-inline">
                                                                    <input onChange={props.onChange} type="radio"
                                                                        name="userTypeId" value={item.id}
                                                                        checked={userTypeId == item.id} />
                                                                    {item.name}
                                                                </label>
                                                            );
                                                        })}
                                                        {errors.userTypeId && <span className="help-block">{errors.userTypeId}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="control-label text-left col-md-2">Is Admin?</label>
                                                    <div className="col-md-10 p-t-5">
                                                        <input type="checkbox"
                                                            name="isAdmin"
                                                            value={true}
                                                            onChange={props.onChange}
                                                            disabled={props.disableAdmin}
                                                            checked={isAdmin.toString() === "true"} />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="control-label text-left col-md-2">User Status <span
                                                        className="text-danger">*</span></label>
                                                    <div className="col-md-10">
                                                        <label className="radio-inline">
                                                            <input onChange={props.onChange} type="radio" name="status" value={true}
                                                                checked={status.toString() === "true"} />
                                                            Active
                                                            </label>
                                                        <label className="radio-inline">
                                                            <input onChange={props.onChange} type="radio" name="status" value={false}
                                                                checked={status.toString() === "false"} />
                                                            Inactive
                                                         </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {props.isAdmin === false && <div className="row">
                <div className="col-md-5">
                    <div className="panel panel-axis">
                        <div className="panel-heading">
                            <h4 className="panel-title">Module Access</h4>
                        </div>
                        <div className="panel-body">
                            <UserModuleSelect
                                moduleSelected={props.moduleSelected}
                                selectedClaims={props.claims}
                                data={props.claimsList}
                                allSelected={props.allSelected}
                                disableAdmin={props.disableAdmin}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="panel panel-axis">
                        <div className="panel-heading">
                            <h4 className="panel-title">Project Access</h4>
                        </div>
                        <div className="panel-body">
                            <div className="col-md-3" style={{ 'paddingLeft': '25px' }}>
                                <input
                                    className={"form-control"}
                                type="text" placeholder={"Search.."}
                                onChange={props.search}/></div>
                            <div className="col-md-12">
                                <ProjectListSelectable
                                    onChange={props.projectSelected}
                                    isChecked={props.projectChecked}
                                    data={props.projectList}
                                    selectAll={props.projectSelectAll}
                                    projectAllSelected={props.projectAllSelected}
                                    displayCompanyName={props.displayCompanyName}
                                    displayPropertyName={props.displayPropertyName}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {
            //    {(props.isEditMode === false && props.hasCreatePermission()) &&
            //<button disabled={isLoading} type="submit" className="btn btn-sm btn-primary m-r-5 m-t-10">
            //    Create
            //</button>}
            //{(props.isEditMode === true && props.hasEditPermission()) &&
            //<button disabled={isLoading} type="submit" className="btn btn-sm btn-primary m-r-5 m-t-10">
            //    Update
            //</button>}

            //<a onClick={props.cancelClicked} className="btn btn-sm btn-default m-t-10">Cancel</a>
            //{props.hasDeletePermission() && (props.isEditMode === true &&
            //    <a onClick={props.displayWarning} className="btn btn-sm btn-danger m-t-10 pull-right">Delete</a>)}
                //
            }
        </form>
    );
}

UserForm.propTypes = {};
UserForm.defaultProps = {};

export default UserForm;
