import isEmpty from 'lodash/isEmpty';
const validator = require('validator');

export function validateInput(data) {
    let errors = {};
    if (validator.isEmpty(data.email)) {
        errors.email = 'This field is required';
    }

    if(!validator.isEmail(data.email)){
        errors.email = 'Email not in correct format!';
    }
    if (validator.isEmpty(data.firstName)) {
        errors.firstName = 'This field is required';
    }
    if (validator.isEmpty(data.lastName)) {
        errors.lastName = 'This field is required';
    }
    if (validator.isEmpty(data.userName)) {
        errors.userName = 'This field is required';
    }
    if(!validator.isEmpty(data.password) || !validator.isEmpty(data.confirmPassword)){
        if (validator.isEmpty(data.password)) {
            
            errors.password = 'This field is required';
        }
        if(data.password !== data.confirmPassword){
            errors.confirmPassword = 'Passwords do not match';
            errors.password = 'Passwords do not match';
        }
        if (!validator.matches(data.password, "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{4,}$")) {
            errors.password = 'Password must be at least 6 characters and must include at least one upper case, one lower case letter, one special character and one numeric digit.';
        }
    }
    
    if(validator.isEmpty(data.clientId.toString())){
        errors.clientId = "This field is required!";
    }
    if(validator.isEmpty(data.userTypeId.toString())){
        errors.userTypeId = "This field is required!";
    }

    return {
        errors,
        isValid: isEmpty(errors)
    };
}
