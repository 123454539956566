import axios from "axios"
const SHIPMENT_ITEM_IS_LOADING = "SHIPMENT_ITEM_IS_LOADING";
const SHIPMENT_ITEM_SAVED = "SHIPMENT_ITEM_SAVED";
const SHIPMENT_ITEM_SINGLE_FETCH = "SHIPMENT_ITEM_SINGLE_FETCH";
const SHIPMENT_ITEM_FETCHED = "SHIPMENT_ITEM_FETCHED";
const SHIPMENT_ITEM_COL_FETCHED = "SHIPMENT_ITEM_COL_FETCHED";
export const actionCreators = {
    getShipmentItemList: (shipmentId) => async (dispatch, getState) => {
        if (getState().shipmentItems.isLoading) {
            return;
        }
        const url = "/api/shipmentItem/getShipmentItem?shipmentId=" + shipmentId;
        dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: SHIPMENT_ITEM_FETCHED, shipmentItems: response.data });
        dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: false });
    },
    createShipmentItem: state => async (dispatch, getState) => {
        if (getState().shipmentItems.isLoading) {
            return;
        }
        try {
            const url = "/api/shipmentItem/create";
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: true });
            // console.log('api data' + JSON.stringify(state)); 
            //const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            const config = { headers: { 'Content-Type': 'application/json' } };
            let response = await axios.post(url, state, config);
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: false });
            dispatch({ type: SHIPMENT_ITEM_SAVED, lastSaved: response.data });
        } catch (e) {
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
   
    getSingleShipmentItem: (shipmentItemId) => async (dispatch, getState) => {
        if (getState().shipmentItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: true });
            // const url = "/api/shipmentItem/" + receivingAlertItemId;
            const url = "/api/shipmentItem/getSingle?ShipmentItemId=" + shipmentItemId;
            let response = await axios.get(url);
            dispatch({ type: SHIPMENT_ITEM_SINGLE_FETCH, selectedShipmentItem: response.data });
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: false });
        }
    },
    updateShipmentItem: state => async (dispatch, getState) => {
        try {
            if (getState().shipmentItems.isLoading) {
                return;
            }
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/shipmentItem";
            await axios.put(url, state);
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    deleteShipmentItem: shipmentItemId => async (dispatch, getState) => {
        try {
            if (getState().shipmentItems.isLoading) {
                return;
            }
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/shipmentItem/" + shipmentItemId
            await axios.delete(url);
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },

    getFilteredColums: (shipmentId) => async (dispatch, getState) => {
        if (getState().shipmentItems.isLoading) {
            return;

        }
        dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: true });
        const url = "/api/shipmentItem/filteredColums?shipmentId=" + shipmentId;
        let response = await axios.get(url);
        dispatch({ type: SHIPMENT_ITEM_COL_FETCHED, filter: response.data });
        dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: false });
    },
    searchShipmentItems: (searchFields, sortColumn, reverse, shipmentId) => async (dispatch, getState) => {
        if (getState().shipmentItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/shipmentItem/search";
            let response = await axios.post(url, { searchFields, sortColumn, reverse, shipmentId });

            dispatch({ type: SHIPMENT_ITEM_FETCHED, shipmentItems: response.data });

            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: SHIPMENT_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
}
const initialState = {
    isLoading: false,
    lastSaved: null,
    selectedShipmentItem: null,
    data: {},
    filtered: []
};
export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case SHIPMENT_ITEM_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case SHIPMENT_ITEM_SAVED:
            return { ...state, lastSaved: action.lastSaved };
        case SHIPMENT_ITEM_FETCHED:
            return { ...state, data: action.shipmentItems };
        case SHIPMENT_ITEM_COL_FETCHED:
            return { ...state, filtered: action.filter };
        case SHIPMENT_ITEM_SINGLE_FETCH:
            return { ...state, selectedShipmentItem: action.selectedShipmentItem }

        default: return state;
    }
}