import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Loading(props) { 
    if (props.error) {
    return <div>Component not Loaded!Please try to reload page. <button onClick={ props.retry }>Retry to Load this Module</button></div>;
  }
  // else if (props.timedOut) {
  //  return <div>Taking a long time... <button onClick={ props.retry }>Retry</button></div>;
  //} else if (props.pastDelay) {
  //  return <div>Loading...</div>;
  //} 
    else {
   // return <div className="spinner" />;
         return <FontAwesomeIcon
                    icon={"spinner"}
                    size="1x"                               
                    className={"text-axis-blue m-l-2"}
                    title="Component is Loading."
                    />;
        //return <div>Component is Loading..</div>;
      // return null
  }
   
};