import axios from "axios";

const PROJECT_TYPE_IS_LOADING = "PROJECT_TYPE_IS_LOADING";
const PROJECT_TYPE_FETCHED = "PROJECT_TYPE_FETCHED";

export const actionCreators = {
    fetchProjectTypes: state => async (dispatch, getState) => {
        try {
            if (getState().projectTypes.isLoading) {
                return;
            }
            const url = "/api/projectType";
            let response = await axios.get(url);
            dispatch({type: PROJECT_TYPE_IS_LOADING, isLoading: true});
            dispatch({type: PROJECT_TYPE_FETCHED, projectTypes: response.data});
            dispatch({type: PROJECT_TYPE_IS_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: PROJECT_TYPE_IS_LOADING, isLoading: false});
        }
    }
};

const initialState = {
    isLoading: false,
    list: []
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case PROJECT_TYPE_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case PROJECT_TYPE_FETCHED:
            return {...state, list: action.projectTypes};
        default:
            return state;
    }
};