import axios from "axios";
const STANDARD_RATE_LIST = "STANDARD_RATE_LIST";
const WAREHOUSE_RATE_LIST = "WAREHOUSE_RATE_LIST";
const MASTER_DATA_LIST = "MASTER_DATA_LIST";
const RATE_IS_LOADING = "RATE_IS_LOADING";
const PROJECT_RATE_MANAGER = "PROJECT_RATE_MANAGER";
export const actionCreators = {

    getProjectRateManageData: (projectId) => async (dispatch, getState) => {
        const url = "/api/Project/getProjectRateManager?id=" + projectId;
        dispatch({ type: RATE_IS_LOADING, isLoading: true });
        const response = await axios.get(url);
        dispatch({ type: PROJECT_RATE_MANAGER, projectrate: response.data });
        dispatch({ type: RATE_IS_LOADING, isLoading: false });
    },
    getAllStandardRate : () => async (dispatch, getState) => {
        //if (getState().projects.isLoading) {
        //    return;
        //}

        const url = "/api/Project/getAllStandardRate";
        dispatch({ type: RATE_IS_LOADING, isLoading: true });
        const response = await axios.get(url);
        dispatch({ type: STANDARD_RATE_LIST, standardList: response.data });
        dispatch({ type: RATE_IS_LOADING, isLoading: false });
    },
    getAllWarehouseRate: (projectId) => async (dispatch, getState) => {
        //if (getState().projects.isLoading) {
        //    return;
        //}

        const url = "/api/Project/getAllWarehouseRate?projectId=" + projectId;
        dispatch({ type: RATE_IS_LOADING, isLoading: true });
        const response = await axios.get(url);
        dispatch({ type: WAREHOUSE_RATE_LIST, warehouseList: response.data });
        dispatch({ type: RATE_IS_LOADING, isLoading: false });
    },
    getMasterData: () => async (dispatch, getState) => {
        const url = "/api/Project/getWarehouseRateMasterData";
        dispatch({ type: RATE_IS_LOADING, isLoading: true });
        const response = await axios.get(url);
        dispatch({ type: MASTER_DATA_LIST, ratesMasterData: response.data });
        dispatch({ type: RATE_IS_LOADING, isLoading: false });
    },
    createSingleWarehouserate: state => async (dispatch, getState) => {
        if (getState().projects.isLoading) {
            return;
        }
        try {
            dispatch({ type: RATE_IS_LOADING, isLoading: true });
            const url = "/api/project/addSingleWareHouserate";
            await axios.post(url, state);
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
    },
    updateSingleWareHouserate: state => async (dispatch, getState) => {
        //if (getState().projects.isLoading) {
        //    return;
        //}
        try {
            dispatch({ type: RATE_IS_LOADING, isLoading: true });
            const url = "/api/project/updateSingleWareHouserate";
            await axios.put(url, state);
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
    },
    updateProjectManager : state => async (dispatch, getState) => {
        try {
            dispatch({ type: RATE_IS_LOADING, isLoading: true });
            const url = "/api/project/updateProjectManager";
            await axios.put(url, state);
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
    },
    updateStandardRates: state => async (dispatch, getState) => {
        try {
            dispatch({ type: RATE_IS_LOADING, isLoading: true });
            const url = "/api/project/updateStandardRates";
            await axios.put(url, state);
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
    },
    updateWareHouserate: state => async (dispatch, getState) => {
        //if (getState().projects.isLoading) {
        //    return;
        //}
        try {
            dispatch({ type: RATE_IS_LOADING, isLoading: true });
            const url = "/api/project/updateWareHouserate";
            await axios.put(url, state);
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
    },
    createSingleStandardrate: state => async (dispatch, getState) => {
        //if (getState().projects.isLoading) {
        //    return;
        //}
        try {
            dispatch({ type: RATE_IS_LOADING, isLoading: true });
            const url = "/api/project/addSingleStandardrate";
            await axios.post(url, state);
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
    },
    setDefaultRate: id => async (dispatch, getState) => {
        try {
            dispatch({ type: RATE_IS_LOADING, isLoading: true });
            const url = "/api/project/setDefaultData";
            await axios.post(url,{ id});
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
    },
    updateSingleStandardrate: state => async (dispatch, getState) => {
        //if (getState().projects.isLoading) {
        //    return;
        //}
        try {
            dispatch({ type: RATE_IS_LOADING, isLoading: true });
            const url = "/api/project/updateSingleStandardrate";
            await axios.put(url, state);
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
    },
    removeStandardrates: state => async (dispatch, getState) => {
        //if (getState().projects.isLoading) {
        //    return;
        //}
        try {
            dispatch({ type: RATE_IS_LOADING, isLoading: true });
            const url = "/api/project/RemoveStandardRates";
            await axios.put(url, state);
            
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
    },
    removeWarehouseRates: state => async (dispatch, getState) => {
        //if (getState().projects.isLoading) {
        //    return;
        //}
        try {
            dispatch({ type: RATE_IS_LOADING, isLoading: true });
            const url = "/api/project/removeWarehouseRates";
            await axios.put(url, state);
             dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
    },
    searchWarehouseRateList: (searchFields,  sortColumn, reverse,projectId) => async (dispatch, getState) => {
        
        try {
            dispatch({ type: RATE_IS_LOADING, isLoading: true });
            const url = "/api/project/searchWarehouseRate";
            let response = await axios.post(url, { searchFields, sortColumn, reverse, projectId });
            dispatch({ type: WAREHOUSE_RATE_LIST, warehouseList: response.data });
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    searchStandardRateList: (searchFields, sortColumn, reverse) => async (dispatch, getState) => {

        try {
            dispatch({ type: RATE_IS_LOADING, isLoading: true });
            const url = "/api/project/searchStandardRate";
            let response = await axios.post(url, { searchFields, sortColumn, reverse });
            dispatch({ type: STANDARD_RATE_LIST, standardList: response.data });
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RATE_IS_LOADING, isLoading: false });
            throw e;
        }

    }

};

const initialState = {
    isLoading: false,
    standardList: [],
    warehouseList: [],
    lastSaved: "",
    ratesMasterData: [],
    projectrate: {}
};
export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case STANDARD_RATE_LIST:
            return { ...state, standardList: action.standardList }
        case WAREHOUSE_RATE_LIST:
            return { ...state, warehouseList: action.warehouseList }
        case MASTER_DATA_LIST:
            return { ...state, ratesMasterData: action.ratesMasterData }
        case RATE_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case PROJECT_RATE_MANAGER:
            return { ...state, projectrate: action.projectrate };
        default:
            return state;
    }
};