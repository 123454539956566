import axios from "axios";
const PICK_TICKET_ITEM_IS_LOADING = "PICK_TICKET_ITEM_IS_LOADING";
const PICK_TICKET_ITEM_SAVED = "PICK_TICKET_ITEM_SAVED";
const PICK_TICKET_ITEM_FETCHED = "PICK_TICKET_ITEM_FETCHED";
const PICK_TICKET_ITEM_FETCH_PAGED = "PICK_TICKET_ITEM_FETCH_PAGED";
const PICK_TICKET_ITEM_FILTER_COLUMNS = "PICK_TICKET_ITEM_FILTER_COLUMNS";
const NON_INVENTORY_ITEM = "NON_INVENTORY_ITEM";
export const actionCreators = {

    createPickTicketItem: data => async (dispatch, getState) => {
        if (getState().pickTicketItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/pickTicketItem";
            // console.log(state);
            let response = await axios.post(url, data);
          // debugger;
            dispatch({ type: PICK_TICKET_ITEM_SAVED, lastSaved: response.data });
            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: false });

        }
        catch (e) {
            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: false });
        }
    },
   
   getPickTicketItemList: (pickTicketId) => async (dispatch, getState) => {
        if (getState().pickTicketItems.isLoading) {
            return;
        }
        const url = "/api/pickTicketItem/getPickTktItem?pickTicketId=" + pickTicketId;
        dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: PICK_TICKET_ITEM_FETCHED, tktItems: response.data });
        dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: false });
    },
    updatePickTicketItem: state => async (dispatch, getState) => {
        try {
            if (getState().pickTicketItems.isLoading) {
                return;
            }
            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/pickTicketItem";
            await axios.put(url, state);
            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
 },
  
    getFilteredColumns: (pickTicketId) => async (dispatch, getState) => {
        if (getState().pickTicketItems.isLoading) {
            return;
        }
        dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: true });
        const url = "/api/pickTicketItem/filtered?pickTicketId=" + pickTicketId;
        let response = await axios.get(url);
        dispatch({ type: PICK_TICKET_ITEM_FILTER_COLUMNS, filter: response.data });
        dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: false });
    },
   
    searchPickTicketItems: (searchFields, sortColumn, reverse, pickTicketId) => async (dispatch, getState) => {
        if (getState().pickTicketItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/pickTicketItem/search";
            let response = await axios.post(url, { searchFields, sortColumn, reverse, pickTicketId });

            dispatch({ type: PICK_TICKET_ITEM_FETCHED, tktItems: response.data });

            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    deleteItem: Id => async (dispatch, getState) => {
        try {
            if (getState().pickTicketItems.isLoading) {
                return;
            }
            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/pickTicketItem/" + Id
            await axios.delete(url);
            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    createNonInventoryItem: (data) => async (dispatch, getState) => {
        if (getState().pickTicketItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/pickTicketItem/createNonInventoryItem";
            let response = await axios.post(url,data );

            dispatch({ type: NON_INVENTORY_ITEM, lastSavedNonInItem: response.data });

            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PICK_TICKET_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
};
const initialState = {
    isLoading: false,   
    lastSaved: null,
    data: {},  
    paged: {},
    filtered: [],
    lastSavedNonInItem:null,
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case PICK_TICKET_ITEM_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case PICK_TICKET_ITEM_SAVED:
            return { ...state, lastSaved: action.lastSaved };     
        case PICK_TICKET_ITEM_FETCHED:
            return { ...state, data: action.tktItems }
        case PICK_TICKET_ITEM_FETCH_PAGED:
            return { ...state, paged: action.paged }; 
        case PICK_TICKET_ITEM_FILTER_COLUMNS:
            return { ...state, filtered: action.filter }
        case NON_INVENTORY_ITEM:
            return { ...state, lastSavedNonInItem: action.lastSavedNonInItem }
        default: return state;
    }
}