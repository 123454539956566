import axios from "axios"

const RECEIVING_ALERT_ITEM_IS_LOADING = "RECEIVING_ALERT_ITEM_IS_LOADING";
const RECEIVING_ALERT_ITEM_SAVED = "RECEIVING_ALERT_ITEM_SAVED";
const RECEIVING_ALERT_ITEM_SINGLE_FETCH = "RECEIVING_ALERT_ITEM_SINGLE_FETCH";
const RECEIVING_ALERT_ITEM_FETCHED = "RECEIVING_ALERT_ITEM_FETCHED";
const RECEIVING_ALERT_ITEM_COL_FETCHED = "RECEIVING_ALERT_ITEM_COL_FETCHED";
export const actionCreators = {
    getRAItemList: (receivingAlertId) => async (dispatch, getState) => {
        if (getState().rAItems.isLoading) {
            return;
        }
       
        if (receivingAlertId === 0) {
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: true });
            dispatch({ type: RECEIVING_ALERT_ITEM_FETCHED, receivingAlertItems: {} });
        }
        else {
            const url = "/api/raItem/getAllRAItem?receivingAlertId="+ receivingAlertId;
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: true });
            let response = await axios.get(url);
            dispatch({ type: RECEIVING_ALERT_ITEM_FETCHED, receivingAlertItems: response.data });
        }
        dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
    },
    createRAItem: state => async (dispatch, getState) => {
        if (getState().rAItems.isLoading) {
            return;
        }
        try {
            const url = "/api/raItem/create";
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: true });
           // console.log('api data' + JSON.stringify(state)); 
            //const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            const config = { headers: { 'Content-Type': 'application/json' } };
            let response = await axios.post(url, state, config);
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
            dispatch({ type: RECEIVING_ALERT_ITEM_SAVED, lastSaved: response.data });
        } catch (e) {
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    createRAItemFromShipment: data => async (dispatch, getState) => {
        if (getState().rAItems.isLoading) {
            return;
        }
        try {
            const url = "/api/raItem/createFromShipment";
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: true });           
            const config = { headers: { 'Content-Type': 'application/json' } };
            let response = await axios.post(url, data, config);
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
            dispatch({ type: RECEIVING_ALERT_ITEM_SAVED, lastSaved: response.data });
        } catch (e) {
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getSingleRAItem: (receivingAlertItemId, receivingAlertId) => async (dispatch, getState) => {
        if (getState().rAItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: true });
           // const url = "/api/raItem/" + receivingAlertItemId;
            const url = "/api/raItem/getSingle?receivingAlertItemId=" + receivingAlertItemId + "&receivingAlertId=" + receivingAlertId;
            let response = await axios.get(url);            
            dispatch({ type: RECEIVING_ALERT_ITEM_SINGLE_FETCH, selectedRaItem: response.data });
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
        }
    },
    updateRAItem: state => async (dispatch, getState) => {
        try {
            if (getState().rAItems.isLoading) {
                return;
            }
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/raItem";
            await axios.put(url, state);
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    deleteRAItem: receivingAlertId => async (dispatch, getState) => {
        try {
            if (getState().rAItems.isLoading) {
                return;
            }
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/raItem/" + receivingAlertId          
            await axios.delete(url);
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }
    },
  
    getFilteredRAItemColums: (receivingAlertId) => async (dispatch, getState) => {
        if (getState().rAItems.isLoading) {
            return;            
        }
        if (receivingAlertId === 0) {
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: true });
            
            dispatch({ type: RECEIVING_ALERT_ITEM_COL_FETCHED, filter: {} });
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
        }
        else {
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/raItem/filteredColums?ReceivingAlertId=" + receivingAlertId;
            let response = await axios.get(url);
            dispatch({ type: RECEIVING_ALERT_ITEM_COL_FETCHED, filter: response.data });
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
        }
    },
    searchReceivingAlertsItems: (searchFields, sortColumn, reverse, receivingAlertId) => async (dispatch, getState) => {
        if (getState().rAItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: true });
            const url = "/api/raItem/search";
            let response = await axios.post(url, { searchFields, sortColumn, reverse, receivingAlertId });
            
            dispatch({ type: RECEIVING_ALERT_ITEM_FETCHED, receivingAlertItems: response.data });
            
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RECEIVING_ALERT_ITEM_IS_LOADING, isLoading: false });
            throw e;
        }

    },
}
const initialState = {
    isLoading: false, 
    lastSaved: null,
    selectedRAItem: null,
    data: {},
    filtered: []
};
export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case RECEIVING_ALERT_ITEM_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case RECEIVING_ALERT_ITEM_SAVED:
            return { ...state, lastSaved: action.lastSaved }; 
        case RECEIVING_ALERT_ITEM_FETCHED:
            return { ...state, data: action.receivingAlertItems }; 
        case RECEIVING_ALERT_ITEM_COL_FETCHED:
            return { ...state, filtered: action.filter };
        case RECEIVING_ALERT_ITEM_SINGLE_FETCH:
            return { ...state, selectedRAItem: action.selectedRaItem }
   
        default: return state;
    }
}