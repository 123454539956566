import axios from "axios";
const PROJECT_RA_FETCH = "PROJECT_RA_FETCH";
const PROJECT_RA_IS_LOADING = "PROJECT_RA_IS_LOADING ";
// eslint-disable-next-line
const PROJECT_RA_SEARCH = "PROJECT_RA_SEARCH";
const PROJECT_RA_IS_FILTER_FETCHED ="PROJECT_RA_IS_FILTER_FETCHED"

export const actionCreators = {
    getRAListByProjectId: (projectId) => async (dispatch, getState) => {
        if (getState().projectRAs.isLoading) {
            return;
        }
        const url = "/api/projectRAs/getAllRAs?ProjectId=" + projectId;
        dispatch({ type: PROJECT_RA_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: PROJECT_RA_FETCH, raList: response.data });
        dispatch({ type: PROJECT_RA_IS_LOADING, isLoading: false });
    },
    getFilteredColumns: (projectId) => async (dispatch, getState) => {
        if (getState().projectRAs.isLoading) {
            return;
        }
        dispatch({ type: PROJECT_RA_IS_LOADING, isLoading: true });
        const url = "/api/projectRAs/filtered?ProjectId=" + projectId;
        let response = await axios.get(url);
        dispatch({ type: PROJECT_RA_IS_FILTER_FETCHED, filtered: response.data });
        dispatch({ type: PROJECT_RA_IS_LOADING, isLoading: false });
    },
    searchInRA: (searchFields, sortColumn, reverse, projectId) => async (dispatch, getState) => {
        if (getState().rAItems.isLoading) {
            return;
        }
        try {
            dispatch({ type: PROJECT_RA_IS_LOADING, isLoading: true });
            const url = "/api/projectRAs/search";
            let response = await axios.post(url, { searchFields, sortColumn, reverse, projectId });

            dispatch({ type: PROJECT_RA_FETCH, raList: response.data });

            dispatch({ type: PROJECT_RA_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: PROJECT_RA_IS_LOADING, isLoading: false });
            throw e;
        }

    },
}
const initialState = {
    isLoading: false,
    poRAItemSaved: null,
    existingRAList: [],
    raList: [],
    filtered:[],
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case PROJECT_RA_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case PROJECT_RA_FETCH:
            return { ...state, raList: action.raList }
        case PROJECT_RA_IS_FILTER_FETCHED:
            return { ...state, filtered: action.filtered }
        default: return state;
    }
}