
const BACKDROP_VISIBILITY_CHANGED = "BACKDROP_VISIBILITY_CHANGED";

export const actionCreators = {
    changeVisibilty: isVisible => async (dispatch, getState) => {
        dispatch({type: BACKDROP_VISIBILITY_CHANGED, isVisible: isVisible});
    }
};

const initialState = {
    backDropVisible: false
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case BACKDROP_VISIBILITY_CHANGED:
            return {...state, backDropVisible: action.isVisible};
        default:
            return state;
    }
};