import React from 'react';
import moment from 'moment';

function MetadataModal(props) {
    return (
        // eslint-disable-next-line
        <a className="btn btn-xs btn-icon btn-circle btn-warning axis_tooltip">
            <span style={{color: 'black', fontSize: '12px', fontWeight: 'bold'}}>&#63;</span>
            <span className="axis_tooltip_text">
                Created By : {props.createdBy && props.createdBy.userName ? props.createdBy.userName : props.createdBy}{<br/>}
                Created On : {props.createdAt && moment.utc(props.createdAt).local().format("MM-DD-YYYY hh:mm a")}{ <br/>}
                Updated By : {props.modifiedBy && props.modifiedBy.userName ? props.modifiedBy.userName : props.modifiedBy}{<br/>}
                Updated On: {props.modifiedAt && moment.utc(props.modifiedAt).local().format("MM-DD-YYYY hh:mm a")}{ <br />}
                {
                  //props.modalType === "success" ? ""
                  // : <h4><i className={props.modalType === "success" ? "" : "fa fa-info-circle"} /> {props.modalHeading}</h4>
                    //<p> {props.moduleDescription? 'Description:' : ''}
                    //    {props.moduleDescription ? props.moduleDescription : ''}
                    //</p>
                }
                

        </span>
        </a>
    );
}

MetadataModal.propTypes = {};
MetadataModal.defaultProps = {};

export default MetadataModal;
