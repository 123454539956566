import axios from "axios"
const RECEIVING_ALERTS_FETCHED_LIST = "RECEIVING_ALERTS_FETCHED_LIST";
const RECEIVING_ALERTS_IS_LOADING = "RECEIVING_ALERTS_IS_LOADING";
// eslint-disable-next-line
const RECEIVING_ALERTS_FETCH = "RECEIVING_ALERTS_FETCH";
const RECEIVING_ALERTS_FETCH_DATA = "RECEIVING_ALERTS_FETCH_DATA";
const RECEIVING_ALERTS_SAVED = "RECEIVING_ALERTS_SAVED";
const RECEIVING_ALERTS_SINGLE_FETCH = "RECEIVING_ALERTS_SINGLE_FETCH";
const RECEIVING_ALERTS_FILTER_FETCHED = "RECEIVING_ALERTS_FILTER_FETCHED";
const RECEIVING_ALERTS_FETCHED_PAGED = "RECEIVING_ALERTS_FETCHED_PAGED";
const RA_FETCH_ALL_POS = "RA_FETCH_ALL_POS";
export const actionCreators = {
    fetchData: () => async (dispatch, getState) => {
        if (getState().receivingAlerts.isLoading) {
            //if i uncomment it click event of receiving alert modal of po module not fetch data of po# and project#
           // return;
        }
        dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: true });
         //THIS api will fetch all projects,Shipments,Purchase Orders
        const url = "/api/ra/getDetailsData";      
        let response = await axios.get(url);
        dispatch({ type: RECEIVING_ALERTS_FETCH_DATA, data: response.data });
        dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });

    },
    createReceivingAlert: state => async (dispatch, getState) => {
        if (getState().receivingAlerts.isLoading) {
            return;
        }
        try {
            dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: true });
            const url = "/api/ra";       
            const config = { headers: { 'Content-Type': 'application/json' } };
            let response = await axios.post(url, state, config);
            dispatch({ type: RECEIVING_ALERTS_SAVED, lastSaved: response.data });
            dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
        }
    },
    getSingleReceivingAlert: id => async (dispatch, getState) => {
        if (getState().receivingAlerts.isLoading) {
            return;
        }
        try {
            
            if (id === 0) {
                dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: true });               
                dispatch({ type: RECEIVING_ALERTS_SINGLE_FETCH, selectedRa: null});
                dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
            }
            else {
                dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: true });
                const url = "/api/ra/" + id;
                let response = await axios.get(url);
                dispatch({ type: RECEIVING_ALERTS_SINGLE_FETCH, selectedRa: response.data });
                dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });}
        }
        catch (e) {
            dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
        }
    },
    updateReceivingAlert: state => async (dispatch, getState) => {
        try {
            if (getState().receivingAlerts.isLoading) {
                return;
            }
            dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: true });
            const url = "/api/ra";
            await axios.put(url, state);
            dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    deleteReceivingAlert: id => async (dispatch, getState) => {
        try {
            if (getState().receivingAlerts.isLoading) {
                return;
            }
            dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: true });
            const url = "/api/ra/" + id

            await axios.delete(url);
            dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getFilteredRAList: () => async (dispatch, getState) => {
        if (getState().receivingAlerts.isLoading) {
            return;
        }
        dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: true });
        const url = "/api/ra/filtered";
        await axios.post(url)
            .then(function (response) {
                //success handled here
                dispatch({ type: RECEIVING_ALERTS_FILTER_FETCHED, filter: response.data });
            })
            .catch(function (error) {
                //if error
                console.clear();
                console.log(error);
                dispatch({ type: RECEIVING_ALERTS_FETCHED_PAGED, filter: {} });
                dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
            })
            .then(function () {
                dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
            });  
      
        
    },

    getPagedRAList: (pageIndex, pageSize) => async (dispatch, getState) => {
        if (getState().receivingAlerts.isLoading) {            
            return;
        }
        const url = "/api/ra/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize;
        dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: true });
        await axios.get(url)
            .then(function (response) {
                //success handled here
                dispatch({ type: RECEIVING_ALERTS_FETCHED_PAGED, paged: response.data });
            })
            .catch(function (error) {
                //if error
                console.clear();
                console.log(error);
                dispatch({ type: RECEIVING_ALERTS_FETCHED_PAGED, paged: {}});
                dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
            })
            .then(function () {
                // always executed
                dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
            });       
    },
    getRAListByProjectId: (projectId) => async (dispatch, getState) => {
        if (getState().receivingAlerts.isLoading) {           
            return;
        }
        const url = "/api/ra/getByProjectId?projectId=" + projectId;
        dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: RECEIVING_ALERTS_FETCHED_LIST, raList: response.data });
        dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
    },
    searchReceivingAlerts: (searchFields, paged, pageIndex, pageSize, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().receivingAlerts.isLoading) {
            return;
        }
        try {
            dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: true });
            const url = "/api/ra/search";
            let response = await axios.post(url, { searchFields, paged, pageIndex, pageSize, sortColumn, reverse });
            if (paged === true) {
                dispatch({ type: RECEIVING_ALERTS_FETCHED_PAGED, paged: response.data });
            }
            else {
                dispatch({ type: RECEIVING_ALERTS_FETCH_DATA, data: response.data });
            }
            dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
            throw e;
        }
    },
    getAllPos: () => async (dispatch, getState) => {
        if (getState().receivingAlerts.isLoading) {
            return;
        }
        dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: true });
        const url = "/api/common/allPos";
        let response = await axios.get(url);
        dispatch({ type: RA_FETCH_ALL_POS, pos: response.data });
        dispatch({ type: RECEIVING_ALERTS_IS_LOADING, isLoading: false });
    },

}
const initialState = {
    isLoading: false,
    data: {},
    lastSaved: null,
    selectedRA: null,
    paged: {},
    raList: {},
    filtered: [],
    allPos: {}
};
export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case RECEIVING_ALERTS_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case RECEIVING_ALERTS_SAVED:
            return { ...state, lastSaved: action.lastSaved }; 
        case RECEIVING_ALERTS_FETCH_DATA:
            return { ...state, data: action.data };
        case RECEIVING_ALERTS_SINGLE_FETCH:
            return { ...state, selectedRA: action.selectedRa }
        case RECEIVING_ALERTS_FETCHED_PAGED:
            return { ...state, paged: action.paged };
        case RECEIVING_ALERTS_FILTER_FETCHED:
            return { ...state, filtered: action.filter };
        case RECEIVING_ALERTS_FETCHED_LIST:
            return { ...state, raList: action.raList }
        case RA_FETCH_ALL_POS:
            return { ...state, allPos: action.pos }
        default: return state;
    }
}