import axios from "axios";
const DATA_IS_LOADING = "DATA_IS_LOADING";
const FETCH_OPEN_MODULES = "FETCH_OPEN_MODULES";
export const actionCreators = {  
 getOpenModules: (moduleId,moduleType )=> async (dispatch, getState) => {
     if (getState().dataFlows.isLoading) {
            return;
        }
     dispatch({ type: DATA_IS_LOADING, isLoading: true });      
     if (moduleType === "Project") {
         const url = "/api/dataFlow/getProjectOpenModules?projectId=" + moduleId;
         let response = await axios.get(url);
         dispatch({ type: FETCH_OPEN_MODULES, openModules: response.data });

     }
     else if (moduleType === "Po") {
         const url = "/api/dataFlow/getPosOpenModules?poId=" + moduleId;
         let response = await axios.get(url);
         dispatch({ type: FETCH_OPEN_MODULES, openModules: response.data });

     }
            
            
            dispatch({ type: DATA_IS_LOADING, isLoading: false });        
    },   
};
const initialState = {
    isLoading: false,   
    openModules: {},
    
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case DATA_IS_LOADING:
            return { ...state, isLoading: action.isLoading };          
        case FETCH_OPEN_MODULES:
            return { ...state, openModules: action.openModules }
        
        default: return state;
    }
}