import axios from "axios";
const SHIPMENT_FETCHED = "SHIPMENT_FETCHED";
const SHIPMENT_FILTER_FETCHED = "SHIPMENT_FILTER_FETCHED";
const SHIPMENT_FETCHED_PAGED = "SHIPMENT_FETCHED_PAGED";
const SHIPMENT_SELECTED = "SHIPMENT_SELECTED";
const SHIPMENT_LOADING = "SHIPMENT_LOADING";
const SHIPMENT_SAVED = "SHIPMENT_SAVED";
const SHIPMENT_COUNT_FETCHED = "SHIPMENT_COUNT_FETCHED";
export const actionCreators = {
    fetchShipments: () => async (dispatch, getState) => {
        if (getState().shipments.isLoading) {
            return;
        }
        dispatch({type: SHIPMENT_LOADING, isLoading: true});
        const url = "/api/shipment";
        let response = await axios.get(url);

        dispatch({type: SHIPMENT_FETCHED, shipments: response.data});
        dispatch({type: SHIPMENT_LOADING, isLoading: false});
    },
    fetchFilter: () => async (dispatch, getState) => {
        if (getState().shipments.isLoading) {
            return;
        }
        dispatch({type: SHIPMENT_LOADING, isLoading: true});
        const url = "/api/shipment/filter";
        let response = await axios.get(url);
        dispatch({type: SHIPMENT_FILTER_FETCHED, filter: response.data});
        dispatch({type: SHIPMENT_LOADING, isLoading: false});
    },

    fetchPaged: (pageIndex, pageSize) => async (dispatch, getState) => {
        if (getState().shipments.isLoading) {
            // Don't issue a duplicate request (we already have or are loading the requested data)
            return;
        }
        const url = "/api/shipment/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize;
        dispatch({type: SHIPMENT_LOADING, isLoading: true});
        let response = await axios.get(url);
        dispatch({type: SHIPMENT_FETCHED_PAGED, paged: response.data});
        dispatch({type: SHIPMENT_LOADING, isLoading: false});
    },

    createShipment: state => async (dispatch, getState) => {
        if (getState().shipments.isLoading) {
            return;
        }
        try {
            const url = "/api/shipment";
            dispatch({type: SHIPMENT_LOADING, isLoading: true});
            const response = await axios.post(url, state);
            dispatch({type: SHIPMENT_LOADING, isLoading: false});
            dispatch({type: SHIPMENT_SAVED, lastSaved: response.data});
        } catch (e) {
            dispatch({type: SHIPMENT_LOADING, isLoading: false});
            throw e;
        }
    },
    getSingle: id => async (dispatch, getState) => {
        if (getState().shipments.isLoading) {
            return;
        }
        try {
            const url = `/api/shipment/${id}`;
            dispatch({type: SHIPMENT_LOADING, isLoading: true});
            const response = await axios.get(url);
            dispatch({type: SHIPMENT_LOADING, isLoading: false});
            dispatch({type: SHIPMENT_SELECTED, single: response.data});
        } catch (e) {
            dispatch({type: SHIPMENT_LOADING, isLoading: false});
        }
    },
    updateShipment: state => async (dispatch, getState) => {
        if (getState().shipments.isLoading) {
            return;
        }
        try {
            const url = `/api/shipment`;
            //debugger;
            dispatch({type: SHIPMENT_LOADING, isLoading: true});
            await axios.put(url, state);
            dispatch({type: SHIPMENT_LOADING, isLoading: false});
        } catch (e) {
            dispatch({type: SHIPMENT_LOADING, isLoading: false});
            throw e;
        }
    },
    searchShipments: (searchFields, paged, pageIndex, pageSize, sortColumn, reverse) => async (dispatch, getState) => {
        if (getState().shipments.isLoading) {
            return;
        }
        try {
            dispatch({type: SHIPMENT_LOADING, isLoading: true});
            const url = "/api/shipment/search";
            let response = await axios.post(url, {searchFields, paged, pageIndex, pageSize, sortColumn, reverse});
            if (paged === true) {
                dispatch({type: SHIPMENT_FETCHED_PAGED, paged: response.data});
            }
            else {
                dispatch({type: SHIPMENT_FETCHED, shipments: response.data});
            }
            dispatch({type: SHIPMENT_LOADING, isLoading: false});
        }
        catch (e) {
            dispatch({type: SHIPMENT_LOADING, isLoading: false});
            throw e;
        }

    },
    fechCounts: (shipmentId) => async (dispatch, getState) => {
        if (getState().shipments.isLoading) {
            return;
        }
        dispatch({ type: SHIPMENT_LOADING, isLoading: true });
        const url = "/api/shipment/dataCounts";
        let response = await axios.post(url, {shipmentId});
        dispatch({ type: SHIPMENT_COUNT_FETCHED, counts: response.data });
        dispatch({ type: SHIPMENT_LOADING, isLoading: false });
    },
    deleteShipment: shipmentId => async (dispatch, getState) => {
        try {
            if (getState().rAItems.isLoading) {
                return;
            }
            dispatch({ type: SHIPMENT_LOADING, isLoading: true });
            const url = "/api/shipment/" + shipmentId
            await axios.delete(url);
            dispatch({ type: SHIPMENT_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: SHIPMENT_LOADING, isLoading: false });
            throw e;
        }
    },
};

const initialState = {
    isLoading: false,
    list: [],
    filter: [],
    paged: {},
    single: null,
    lastSaved: null,
    dataCounts: {}
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case SHIPMENT_LOADING:
            return {...state, isLoading: action.isLoading};
        case SHIPMENT_SELECTED:
            return {...state, single: action.single};
        case SHIPMENT_SAVED:
            return {...state, lastSaved: action.lastSaved};
        case SHIPMENT_FETCHED_PAGED:
            return {...state, paged: action.paged};
        case SHIPMENT_FILTER_FETCHED:
            return { ...state, filter: action.filter };
        case SHIPMENT_COUNT_FETCHED:
            return { ...state, dataCounts: action.counts };
        default:
            return state;
    }
};