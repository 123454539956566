import axios from "axios";
const CHARGE_SAVED = "CHARGE_SAVED";
const SHIPMENT_LIST = "SHIPMENT_LIST";
const CHARGE_LIST = "CHARGE_LIST";
const CHARGES_IS_LOADING = "CHARGES_IS_LOADING";
const INVOICE_LIST = "INVOICE_LIST";
const CHARGES_MASTER_DATA_LIST = "CHARGES_MASTER_DATA_LIST";
const DELETE_WH_CHARGES_RESULT = "DELETE_WH_CHARGES_RESULT";
const LAST_BILLING_PERIOD_STATUS = "LAST_BILLING_PERIOD_STATUS";
const CHARGES_FETCH_PAGED = "CHARGES_FETCH_PAGED";
export const actionCreators = {
    createOpenCharges: state => async (dispatch, getState) => {
        if (getState().charges.isLoading) {
            return;
        }
        try {
            dispatch({ type: CHARGES_IS_LOADING, isLoading: true });
            const url = "/api/charges";
            let response = await axios.post(url, state);
            dispatch({ type: CHARGE_SAVED, lastSaved: response.data });
            dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
        }
    },
    updteOpenCharges: state => async (dispatch, getState) => {
        if (getState().charges.isLoading) {
            return;
        }
        try {
            dispatch({ type: CHARGES_IS_LOADING, isLoading: true });
            const url = "/api/charges";
            await axios.put(url, state);
            dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
        }
    },
    deleteOpenCharges: id => async (dispatch, getState) => {
        if (getState().charges.isLoading) {
            return;
        }
        try {
            dispatch({ type: CHARGES_IS_LOADING, isLoading: true });
            const url = "/api/charges/" + id;
             await axios.delete(url);
            dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
        }
    },
    deleteWHCharges: id => async (dispatch, getState) => {
        if (getState().charges.isLoading) {
            return;
        }
        try {
            dispatch({ type: CHARGES_IS_LOADING, isLoading: true });
            const url = "/api/charges/deleteWarehouseCharges/" + id;
            let response =  await axios.delete(url);
            dispatch({ type: DELETE_WH_CHARGES_RESULT, whchargeResponse: response.data });
            dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
        }
    },
    fetchProjectwiseShipments: (projectId) => async (dispatch, getState) => {
        if (getState().charges.isLoading) {
            //return;
        }
        dispatch({ type: CHARGES_IS_LOADING, isLoading: true });
        const url = "/api/charges/?ProjectId=" + projectId;
        let response = await axios.get(url);
        dispatch({ type: SHIPMENT_LIST, ShipmentIds: response.data });
        dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
    }
    ,
    fetchOpenCharges: (Id,type) => async (dispatch, getState) => {
        //if (getState().charges.isLoading) {
        //    return;
        //}
        dispatch({ type: CHARGES_IS_LOADING, isLoading: true });
        const url = "/api/charges/GetCharges?type="+type+"&Id=" + Id ;
        let response = await axios.get(url);
        dispatch({ type: CHARGE_LIST, listCharges: response.data });
        dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
    },
    fetchInvoiceStatus: () => async (dispatch, getState) => {
        if (getState().charges.isLoading) {
            return;
        }
        dispatch({ type: CHARGES_IS_LOADING, isLoading: true });
        const url = "/api/invoiceStatus";
        let response = await axios.get(url);
        dispatch({ type: INVOICE_LIST, invoiceStatusList: response.data });
        dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
    },
    searchChargesList: (searchFields, shipmentId, sortColumn, reverse,containerModule,projectId,invoiceId) => async (dispatch, getState) => {
        if (getState().charges.isLoading) {
            return;
        }
        try {
            dispatch({ type: CHARGES_IS_LOADING, isLoading: true });
            const url = "/api/charges/Search";
            let response = await axios.post(url, { searchFields, shipmentId, sortColumn, reverse, containerModule, projectId, invoiceId });
            dispatch({ type: CHARGE_LIST, listCharges: response.data });
            dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
        }
        catch (e) {
            dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
            throw e;
        }

    },
    getMasterData: () => async (dispatch, getState) => {
        const url = "/api/Charges/getChargesMasterData";
        dispatch({ type: CHARGES_IS_LOADING, isLoading: true });
        let response = await axios.get(url);
        dispatch({ type: CHARGES_MASTER_DATA_LIST, chargesMasterData: response.data });
        dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
    },
   getBillingperiodInvoiceStatus : (id) => async (dispatch, getState) => {
       const url = "/api/Charges/GetLastBillingPeriodInvoiceStatus/" + id;
        dispatch({ type: CHARGES_IS_LOADING, isLoading: true });
       let response = await axios.get(url);
       dispatch({ type: LAST_BILLING_PERIOD_STATUS, lastBillingPeriodInvoiceResponse: response.data });
        dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
    },
   fetchPagedCharges: (id,type,pageIndex, pageSize) => async (dispatch, getState) => {
       if (getState().charges.isLoading) {
           // Don't issue a duplicate request (we already have or are loading the requested data)
           return;
       }
       //const url = "/api/Charges/paged?pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&id=" + id + "&type=" + type;

       const url = "/api/Charges/paged";
       dispatch({ type: CHARGES_IS_LOADING, isLoading: true });
       await axios.post(url, { id, type, pageIndex, pageSize})
           .then(function (response) {
               //success handled here
               dispatch({ type: CHARGES_FETCH_PAGED, paged: response.data });
           })
           .catch(function (error) {
               //if error
               console.clear();
               console.log(error);
               dispatch({ type: CHARGES_FETCH_PAGED, paged: {} });
               dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
           })
           .then(function () {
               // always executed
               dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
           });;
       
       dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
   },
   searchInPaged: (id, moduleName, searchFields, pageIndex, pageSize, sortColumn, reverse) => async (dispatch, getState) => {
       if (getState().charges.isLoading) {
           return;
       }
       try {
           dispatch({ type: CHARGES_IS_LOADING, isLoading: true });
           const url = "/api/Charges/searchPaged";
           await axios.post(url, { id, moduleName, searchFields, pageIndex, pageSize, sortColumn, reverse})
               .then(function (response) {
                   //success handled here
                   dispatch({ type: CHARGES_FETCH_PAGED, paged: response.data });
               })
               .catch(function (error) {
                   //if error
                   console.clear();
                   console.log(error);
                   dispatch({ type: CHARGES_FETCH_PAGED, paged: {} });
                   dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
               })
               .then(function () {
                   // always executed
                   dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
               });
       }
       catch (e) {
           dispatch({ type: CHARGES_IS_LOADING, isLoading: false });
           throw e;
       }
   },
};
const initialState = {
    isLoading: false,
    shipmentIds: [],
    lastSaved: 0,
    listCharges: [],
    chargesSearchList: [],
    invoiceStatusList: [],
    chargesMasterData: [],
    whchargeResponse: {},
    lastBillingPeriodInvoiceResponse: {},
    paged: {},
};
export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case CHARGES_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case CHARGE_SAVED:
            return { ...state, lastSaved: action.lastSaved };
        case SHIPMENT_LIST:
            return { ...state, shipmentIds: action.ShipmentIds };
        case CHARGE_LIST:
            return { ...state, listCharges: action.listCharges };
        case INVOICE_LIST:
            return { ...state, invoiceStatusList: action.invoiceStatusList };
        case CHARGES_MASTER_DATA_LIST:
            return { ...state, chargesMasterData: action.chargesMasterData };
        case DELETE_WH_CHARGES_RESULT:
            return { ...state, whchargeResponse: action.whchargeResponse };
        case LAST_BILLING_PERIOD_STATUS:
            return { ...state, lastBillingPeriodInvoiceResponse: action.lastBillingPeriodInvoiceResponse };
        case CHARGES_FETCH_PAGED:
            return { ...state, paged: action.paged };    
        default:
            return state;
    }
};
