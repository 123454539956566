import axios from "axios";

const CONTACT_TYPES_IS_LOADING = "CONTACT_TYPES_IS_LOADING";
const CONTACT_TYPES_FETCHED = "CONTACT_TYPES_FETCHED";

export const actionCreators = {
    fetchContactTypes: () => async (dispatch, getState) => {
        if (getState().userTypes.isLoading) {
            return;
        }
        try {
            dispatch({type: CONTACT_TYPES_IS_LOADING, isLoading: true});
            const url = "/api/contactType";
            let response = await axios.get(url);
            dispatch({type: CONTACT_TYPES_FETCHED, contactTypes: response.data});
            dispatch({type: CONTACT_TYPES_IS_LOADING, isLoading: false});

        }
        catch (e) {
            dispatch({type: CONTACT_TYPES_IS_LOADING, isLoading: false});
            throw e;
        }
    }
};

const initialState = {
    isLoading: false,
    list: []
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case CONTACT_TYPES_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case CONTACT_TYPES_FETCHED:
            return {...state, list: action.contactTypes};
        default:
            return state;
    }
};